import * as React from 'react';
import styled from '../helpers/styled';
import i18nProps from '../helpers/i18n';
import BronzeTestProps from 'learning-redux-store/dist/props/bronze_test';
import { AppState, BronzeTestState, requestBronzePayment } from 'learning-redux-store';
import Header from '../components/Header';
import ActivityIndicator from '../components/ActivityIndicator';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import loginRequired from '../middleware/loginRequired';
import ErrorText from '../components/BronzePayment/ErrorText';

const PaymentSection = styled.section`
    text-align: center;
    margin-bottom: 48px;
`;

const PaymentDescription = styled.p`
    font-size: 14px;
    margin-top: 48px;
`;

const PaymentButton = styled.input`
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
    transition: all 0.2s;
    padding: 8px 32px;
    font-size: 20px;
    letter-spacing: 0.1em;
    
    :hover, :active, :focus {
      color: #FFFFFF;
      background-color: #000000;
    }
`;

interface Props extends i18nProps, BronzeTestProps, RouteComponentProps<{ entry_id }> {
  bronze_test: BronzeTestState;
}

class BronzePayment extends React.Component<Props> {

  state = {
    formData: null,
    url: null,
    error: null
  };

  componentDidMount(): void {
    const { entry_id } = this.props.match.params;
    const params = queryString.parse(this.props.location.search);
    let { signature, key, expire } = params;
    signature = signature as string;
    key = key as string;
    expire = expire as string;
    this.props.requestBronzePayment({
      request: {
        entry_id,
        signature,
        key,
        expire
      },
      callback: payment => {
        this.setState({
          formData: payment.formData,
          url: payment.url
        });
      },
      failureCallback: error => {
        this.setState({ error });
      }
    });
  }

  render() {
    const { t } = this.props;

    if (this.state.error) {
      return <ErrorText error={this.state.error}/>;
    }

    if (this.state.formData === null || this.state.formData === undefined) {
      return (
        <PaymentSection>
          <ActivityIndicator color='#616161' size='4x'/>
        </PaymentSection>
      );
    }

    const formInputs: JSX.Element[] = [];
    const data = this.state.formData || [];
    Object.keys(data).forEach((key) => {
      const value = data[key];
      formInputs.push(<input type="hidden" name={key} value={value} key={key}/>);
    });

    return (
      <PaymentSection>
        <Header title={t('お支払い手続き')}/>
        <PaymentDescription>
          {t('下記のボタンからお支払いを行ってください。')}<br/>
          {t('GMOペイメントのサイトに移行します。')}
        </PaymentDescription>
        <form action={this.state.url || undefined} method="POST">
          {formInputs}
          <PaymentButton type="submit" value={t('お支払いの開始')}/>
        </form>
      </PaymentSection>
    );
  }
}

export default connect(
  (state: AppState) => ({
    bronze_test: state.bronze_test
  }),
  { requestBronzePayment }
)(withI18n()(loginRequired(BronzePayment)));