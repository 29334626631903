import React from 'react';
import styled from '../../helpers/styled';
import QuestionItem from './QuestionItem';
import { Question, QuestionCategory as Category } from 'learning-redux-store';

const QuestionCategorySection = styled.section`
    width: 1024px;
    max-width: 100%;
    margin: 24px auto 48px;
`;

const CategoryRadio = styled.input`
    display: none;
`;

const CategoryLabel = styled.label`
    cursor: pointer;
    text-align: center;
    display: block;
    font-size: 24px;
    letter-spacing: 0.1em;
    font-weight: bold;
    user-select: none;
    
     &::after {
      content: '';
      display: block;
      width: 12em;
      max-width: 100%;
      border-bottom: 4px solid crimson;
      margin: 8px auto 0;
    }
    
    span:before {
      content: "▼";
    }
    
    ${CategoryRadio}:checked ~ & {
      span:before {
        content: "▲";
      }
    }
`;

const QuestionBox = styled.div`
    height: 0;
    opacity: 0;
    overflow-y: hidden;
    transition: all 0.2s;
    
    ${CategoryRadio}:checked ~ & {
      height: auto;
      opacity: 1;
    }
`;

interface Props {
  category: Category;
  questions: Question[];
  onChangeCategory: (e) => void;
}

const QuestionCategory: React.FC<Props> = ({ category, questions, onChangeCategory }) => {

  let question_elements: JSX.Element[] = [];
  questions.forEach(question => {
    question_elements.push(<QuestionItem key={question.id} question={question}/>);
  });

  return (
    <QuestionCategorySection>
      <CategoryRadio type='checkbox'
                     name='question_category'
                     id={`question_category_${category.id}`}
                     value={category.id}
                     onChange={onChangeCategory}/>
      <CategoryLabel htmlFor={`question_category_${category.id}`}>
        {category.title} <span/>
      </CategoryLabel>
      <QuestionBox>
        {question_elements}
      </QuestionBox>
    </QuestionCategorySection>
  );
};

export default QuestionCategory;