import React from 'react';
import { connect } from 'react-redux';
import { AppState, PaymentProps, PaymentState, requestBulkPayment, requestPayment } from 'learning-redux-store/dist';
import styled from '../../helpers/styled';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import Header from '../Header';
import { Redirect } from 'react-router';
import { requestCartPayment } from 'learning-redux-store/dist/actions/payment';

const PaymentSection = styled.section`
    text-align: center;
    margin-bottom: 48px;
`;

const PaymentDescription = styled.p`
    font-size: 14px;
    margin-top: 48px;
`;

const PaymentButton = styled.input`
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
    transition: all 0.2s;
    padding: 8px 32px;
    font-size: 20px;
    letter-spacing: 0.1em;
    
    :hover, :active, :focus {
      color: #FFFFFF;
      background-color: #000000;
    }
`;

interface Props extends PaymentProps, i18nProps {
  cart?: boolean;
  code?: string;
  payment: PaymentState;
  target?: number;
}

class Payment extends React.Component<Props> {

  componentDidMount() {
    const { code, cart, target } = this.props;
    const campaign_code = code !== undefined && code.length === 0 ? undefined : code;
    if (cart) {
      this.props.requestCartPayment({
        campaign_code: campaign_code
      });
    } else if (target !== undefined) {
      this.props.requestBulkPayment({
        campaign_code: campaign_code,
        target: target,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { isProcessing, error, formData, url } = this.props.payment;
    if (isProcessing) {
      return (
        <PaymentSection>
          <PaymentDescription>{t('Now Loading...')}</PaymentDescription>
        </PaymentSection>
      );
    } else if (error) {
      return (
        <PaymentSection>
          <PaymentDescription>
            {t('エラーが発生しました。')}
          </PaymentDescription>
        </PaymentSection>
      );
    }

    if (url === null && formData !== null) {
      return (
        <Redirect to={formData['RetURL']}/>
      );
    }

    const formInputs: JSX.Element[] = [];
    const data = formData || [];
    Object.keys(data).forEach((key) => {
      const value = data[key];
      formInputs.push(<input type="hidden" name={key} value={value} key={key}/>);
    });

    return (
      <PaymentSection>
        <Header title={t('お支払い手続き')}/>
        <PaymentDescription>
          {t('下記のボタンからお支払いを行ってください。')}<br/>
          {t('GMOペイメントのサイトに移行します。')}
        </PaymentDescription>
        <form action={url || undefined} method="POST">
          {formInputs}
          <PaymentButton type="submit" value={t('お支払いの開始')}/>
        </form>
      </PaymentSection>
    );
  }
}

export default connect(
  (state: AppState) => ({
    payment: state.payment
  }),
  { requestPayment, requestBulkPayment, requestCartPayment }
)(withI18n()(Payment));
