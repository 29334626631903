import React from 'react';
import nl2br from '../../helpers/nl2br';
import styled, { css } from '../../helpers/styled';
import { combineTabletStyle } from '../../helpers/mediaQuery';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';

const AgreementBox = styled.div`
  width: 1120px;
  max-width: 100%;
  margin: 0 auto;
`;

const BoxPc = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 16px 16px 48px;
`;

const BoxMobile = css`
  margin: 16px;
`;

const Box = combineTabletStyle(BoxPc, BoxMobile);

const Title = styled.div`
  width: 12em;
  max-width: 100%;
  
  article.long & {
    width: 25em;
  }
`;

const Body = styled.div`
  flex: 1;
  min-width: 300px;
`;

const DecimalList = styled.ul`
  list-style: decimal;
  padding-left: 24px;
`;

const CircleList = styled.ul`
  list-style: disc;
  padding-left: 24px;
`;

interface Props extends i18nProps {
  isFront: Boolean
}

const BronzeAgreementText: React.FC<Props> = ({t, isFront}) => {
  return (
    <AgreementBox>
      <Box>
        <Title>■{t('個人情報')}</Title>
        <Body>
        {t('当検定試験は特定非営利活動法人日本料理アカデミーにより実施されます。')}<br/>
        {t('実施に当たりJapanese Cuisine comは、下記の個人情報を日本料理アカデミーに提供します。')}
        {nl2br(t('（あなたのID、メールアドレス、全講座購入の有無）'))}
        </Body>
      </Box>
      <Box>
        <Title>■{t('試験の運用')}</Title>
        <Body>
        {t('検定試験の運営は、認定団体である日本料理アカデミーが行い、当サイトは試験に関する一切の責任を負いません。')}
        </Body>
      </Box>
      <Box>
        <Title>■{t('受験料')}</Title>
        <Body>
        {t('30000円')}{t('（支払い方法はエントリー後に事務局からお知らせします）')}
        </Body>
      </Box>
      <Box>
        <Title>■{t('試験方法')}</Title>
        <Body>
        {t('スカイプによるビデオ試験（英語のみ）')}
        </Body>
      </Box>
      <Box>
        <Title>■{t('受験資格')}</Title>
        <Body>
        {t('下記の全要件を満たすものは受験資格を有する。')}
        <DecimalList>
          <li>{t('日本以外の国籍を持つ者で、日本料理の習得を希望する者')}</li>
          <li>{t('認定団体によるオンライン講習を終えた者')}</li>
          <li>{t('スカイプによる受験環境を自己負担で準備できる者')}</li>
          <li>{t('所定の受験料を所定の方法で払い込んだ者')}</li>
        </DecimalList>
        </Body>
      </Box>
      <Box>
        <Title>■{t('受験条件')}</Title>
        <Body>
        <CircleList>
          <li>{t('受験料は合否にかかわらず、一回の検定試験毎に支払う。')}</li>
          <li>{t('試験はすべて英語で実施する。他言語の対応は行わない。')}</li>
          <li>{t('スカイプが通信途絶した場合は再試験を行う。')}</li>
          <li>{t('通信途絶が複数回に渡り、試験不可の場合、検定料は払い戻す。')}</li>
        </CircleList>
        </Body>
      </Box>
      <Box>
        <Title>■{t('合否通知')}</Title>
        <Body>
        {t('メールにより、直接本人に通知する。')}
        </Body>
      </Box>
      <Box>
        <Title>■{t('検定の有効期限')}</Title>
        <Body>
        {t('合格者は、生涯ブロンズ認定者として認定される。')}<br/>
        {nl2br(t('合格者の情報は、日本料理アカデミー及び農林水産省に保管され、日本料理アカデミーが認定者の問い合わせに対応する。'))}
        </Body>
      </Box>
      <Box>
        <Title>■{t('認定者のメリット')}</Title>
        <Body>
        <div>{t('A certified person may')}</div>
        <CircleList>
          <li>{t('ブロンズ認定証の掲示が認められる。')}</li>
          <li>{t('ブロンズ認定者として、宣伝広報活動に利用できる。')}</li>
          <li>{t('ブロンズ認定バッジを購入できる。（有償）')}</li>
        </CircleList>
        </Body>
      </Box>
      <Box>
        <Title>■{t('スカイプ')}</Title>
        <Body>
        {t('スカイプのダウンロードはこちら')} →
        (<a href='https://www.skype.com/get-skype/' target='_blank'>{t('リンク')}</a>)<br/>
        {t('スカイプの使用方法はこちら')} →
        (<a href='https://support.skype.com/faq/FA11098/how-do-i-get-started-with-skype'
            target='_blank'>{t('リンク')}</a>)
        </Body>
      </Box>
      {(() => {
        if (isFront) {
          return null;
        } else {
          return (
            <>
              <Box>
                <Title>■{t('制度名称')}</Title>
                <Body>{t('「海外における日本料理の調理技能認定制度」に基づく「ブロンズ認定」')}</Body>
              </Box>
              <Box>
                <Title>■{t('ガイドライン制定')}</Title>
                <Body>{t('日本の農林水産省')}</Body>
              </Box>
              <Box>
                <Title>■{t('認定団体')}</Title>
                <Body>{t('農林水産省認定運用管理団体「特定非営利活動法人日本料理アカデミー」')}</Body>
              </Box>
              <Box>
                <Title>■{t('運営協力')}</Title>
                <Body>{t('株式会社ブルーマジック（Japanese Cuisine com）')}</Body>
              </Box>
            </>
          );
        }
      })()}
    </AgreementBox>
  );
};

export default withI18n()(BronzeAgreementText);