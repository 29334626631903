import * as React from 'react';
import { asset_endpoint } from '../../config/app';
import noImage from '../../images/no_image.png';
import styled, { css } from '../../helpers/styled';
import { combineMobileStyle } from '../../helpers/mediaQuery';
import { HashLink } from '../../helpers/react-router-hash-link';
import { Movie } from 'learning-redux-store';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';

const SkillItem = styled(HashLink)`
    display: block;
    width: 33%;
    padding-top: 16px;
    padding-bottom: 16px;
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    
    &:hover, &:active, &:focus {
      text-decoration: none;
      color: #000000;
    }

    :nth-child(3n + 1) {
      padding-right: 16px;
    }
    
    :nth-child(3n + 2) {
      padding-left: 8px; 
      padding-right: 8px;
    }
    
    :nth-child(3n) {
      padding-left: 16px;
    }
`;

const SkillItemMobile = css`
    width: 100%;
    :nth-child(3n + 1) {
      padding-right: 0;
    }
    
    :nth-child(3n + 2) {
      padding-left: 0; 
      padding-right: 0;
    }
    
    :nth-child(3n) {
      padding-left: 0;
    }
`;

const SkillItemCombined = combineMobileStyle(SkillItem, SkillItemMobile);

interface SelectBorderProps {
  active: boolean;
}

const SelectBorder = styled.div<SelectBorderProps>`
    width: 100%;
    border-top: ${({ active }) => active ? '5px solid crimson' : 'none'};
    height: 16px;
`;

const MovieImage = styled.img<{ animated: boolean }>`
    background-color: #000000;
    width: 100%;
    
    ${SkillItem}:hover & {
      display: ${({ animated }) => animated ? 'none' : 'block'};
    }
`;

const MoviePreviewImage = styled.img`
    background-color: #000000;
    width: 100%;
    display: none;
    
    ${SkillItem}:hover & {
      display: block;
    }
`;

const MovieTitle = styled.h4`
    text-align: center;
    margin-bottom: 8px;
`;

const MoviePrice = styled.p`
    color: #414141;
    text-align: center;
    margin-bottom: 0;
`;

interface Props extends i18nProps {
  movie: Movie;
}

const MovieSkillItem: React.FC<Props> = ({ movie, t }) => {

  let movieElements: JSX.Element[] = [];
  if (movie.image) {
    movieElements.push(
      <MovieImage
        key={`${movie.id}.1`}
        src={`${asset_endpoint}/uploads/movie_thumb/${movie.image}?w=300`}
        animated={movie.preview !== null}/>
    );
    if (movie.preview) {
      movieElements.push(
        <MoviePreviewImage
          key={`${movie.id}.2`}
          src={`${asset_endpoint}/uploads/movie_previews/${movie.preview}`}/>
      );
    }
  } else {
    movieElements.push(<MovieImage key={`${movie.id}.3`} src={noImage} animated={false}/>);
  }

  return (
    <SkillItemCombined key={movie.id}
                       to={`/categories/${movie.category_id}/${movie.id}#show`}
                       smooth>
      <SelectBorder active={false}/>
      {movieElements}
      <MovieTitle>{movie.title}</MovieTitle>
      {movie.campaign_price !== null && (
        <MoviePrice>{movie.campaign_price!.toLocaleString()}{t('円')}</MoviePrice>
      )}
      {movie.campaign_price === null && (
        <MoviePrice>{movie.price.toLocaleString()}{t('円')}</MoviePrice>
      )}
    </SkillItemCombined>
  );
};

export default withI18n()(MovieSkillItem);