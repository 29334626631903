import React from 'react';
import i18nProps from '../../helpers/i18n';
import styled from '../../helpers/styled';
import paperImage from '../../images/recipes/produce_background.png';
import { withI18n } from 'react-i18next';
import { HashLink } from '../../helpers/react-router-hash-link';
import { BulkPrice } from 'learning-redux-store/dist/data/bulk_price';
import nl2br from '../../helpers/nl2br';
import PriceView from './PriceView';

const Section = styled.section`
  background-image: url(${paperImage});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px;
  width: 824px;
  max-width: 100%;
  margin: 0 auto 24px;
`;

const HeadText = styled.h5`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

const TitleImageBox = styled.div`
  text-align: center;
  max-width: 100%;
`;

const TitleImage = styled.img`
  max-width: 100%;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 720px;
  max-width: 100%;
  margin: 16px auto 0;
`;

const Description = styled.div`
  flex: 1;
  min-width: 200px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.8em;
`;

const Chef = styled.div`
  flex: 1;
  min-width: 200px;
  margin-bottom: 16px;
  display: flex;
`;

const ChefImageBox = styled.div`
  width: 160px;
`;

const ChefImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ChefText = styled.div`
  flex: 1;
  padding: 0 8px;
`;

const PaymentInformation = styled.div`
  border-top: 7px solid #000000;
  margin-top: 16px;
  padding-top: 32px;
  text-align: center;
`;

const PaymentInformationText = styled.div`
  
`;

const PaymentLabel = styled.div`
  background-color: #000000;
  color: #FFFFFF;
  text-align: center;
  margin-top: 16px;
  font-size: 20px;
`;

const ProduceLabel = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 20px;
`;

const PaymentBox = styled(HashLink)`
  margin-top: 16px;
  border: 1px solid #000000;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  padding: 8px;
  
  &:hover, &:active, &:focus {
    color: #000000;
    background-color: #FFFFFF;
    text-decoration: none;
  }
`;

interface Props extends i18nProps {
  bulk_price: BulkPrice;
}

const ProduceBox: React.FC<Props> = ({ t, bulk_price }) => {
  return (
    <Section>
      <HeadText>{t('RECIPE MOVIE')}</HeadText>
      <TitleImageBox>
        <TitleImage src={require(`../../images/recipes/${t('produce_banner_png')}`)}/>
      </TitleImageBox>
      <Flex>
        <Description>
          {t('10年連続ミシュラン３星の京都の老舗料亭「菊乃井 本店」プロデュース。')}
          {t('21のレシピと一歩先をいくための調理技能映像を１点収録。')}
        </Description>
        <Chef>
          <ChefImageBox>
            <ChefImage src={require('../../images/recipes/murata_chef.png')}/>
          </ChefImageBox>
          <ChefText>
            {t('村田吉弘シェフプロデュースのもと、辻昌仁統括料理長が料理のポイントを分かりやすく紹介。')}
          </ChefText>
        </Chef>
      </Flex>
      <PaymentInformation>
        <PaymentInformationText>
          {t('各有料コンテンツはレシピ単位で購入します。')}<br/>
          {t('購入後は１年間動画を見ることが出来ます。')}
        </PaymentInformationText>
        <PaymentLabel>
          {t('セット価格がお得です！')}
        </PaymentLabel>
        <ProduceLabel>
          {t('「菊乃井 本店」プロデュースの全レシピ')}
        </ProduceLabel>
        <PriceView price={bulk_price.home_data}
                   campaign={bulk_price.home_campaign}/>
        <PaymentBox to={`/payment/all/1`}>
          {t('セット購入へ！')}
        </PaymentBox>
      </PaymentInformation>
    </Section>
  );
};

export default withI18n()(ProduceBox);
