import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import i18nProps from '../helpers/i18n';

const SuccessSignUp: React.FC<i18nProps> = ({t}) => {
  return (
    <section style={{ textAlign: 'center' }}>
      <Helmet>
        <title>{t('THANK YOU!')} | Japanese Cuisine.com</title>
      </Helmet>

      <h1>{t('E-mail Validation')}</h1>

      <p>
        {t('Thank you for your registration.')}<br/>
        {t('I sent a confirmation email to the email address you entered.')}
      </p>
      <p>
        {t('Please click on the link in the mail to complete registration.')}
      </p>
      <p>
        <Link to='/'>{t('Back to Home')}</Link>
      </p>
    </section>
  );
};

export default withI18n()(SuccessSignUp);