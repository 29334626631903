import React from 'react';
import { connect } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';
import {
  AppState,
  CategoryMoviesState,
  CategoryState,
  MovieState,
  requestCategoryDetail,
  requestCategoryMovies,
  requestMovieDetail
} from 'learning-redux-store';
import { withI18n } from 'react-i18next';
import Header from '../components/Header';
import Banner from '../components/Lesson/Banner';
import CategoryInfo from '../components/Lesson/CategoryInfo';
import Movies from '../components/Lesson/Movies';
import Footer from '../components/Lesson/Footer';
import ShowMovie from '../components/Lesson/ShowMovie';
import ActivityIndicator from '../components/ActivityIndicator';
import { LoadingFlex } from '../components/Lesson/Elements';
import CategoryProps from 'learning-redux-store/dist/props/category';
import CategoryMoviesProps from 'learning-redux-store/dist/props/category_movies';
import MovieProps from 'learning-redux-store/dist/props/movie';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet-async';
import i18nProps from '../helpers/i18n';

interface Props extends CategoryProps, CategoryMoviesProps, MovieProps, i18nProps, RouteComponentProps<{ category_id, movie_id }> {
  category: CategoryState;
  category_movies: CategoryMoviesState;
  movie: MovieState;
}

class Lesson extends React.Component<Props> {

  componentDidMount() {
    const { category_id, movie_id } = this.props.match.params;
    this.props.requestCategoryDetail(category_id);
    this.props.requestCategoryMovies(category_id);

    if (movie_id) {
      this.props.requestMovieDetail(movie_id);
    }
  }

  componentDidUpdate(prevProps) {
    const { movie_id } = this.props.match.params;
    const { movie_id: prev_movie_id } = prevProps.match.params;
    if (movie_id && prev_movie_id !== movie_id) {
      this.props.requestMovieDetail(movie_id);
    }
  }

  render() {
    const { category_id, movie_id } = this.props.match.params;
    const { category, category_movies, movie, t } = this.props;

    // ローディング
    if (category.isProcessing || !category.selectItem) {
      return <LoadingFlex><ActivityIndicator color='#212121' size='4x'/></LoadingFlex>;
    }

    return (
      <div id="top">
        <Helmet title={(() => {
          if (movie_id !== undefined && movie.data !== null) {
            return `${movie.data.title} | Japanese Cuisine.com`;
          } else {
            return `${category.selectItem.title} | Japanese Cuisine.com`;
          }
        })()}/>
        <StickyContainer>
          <Sticky>
            {({ style }) => <Header containerStyle={style}
                                    title="MANABI"
                                    subTitle={t('日本料理を学ぶ')}/>}
          </Sticky>
          <Banner category={category.selectItem}
                  category_movies={category_movies.data[category_id]}/>
          <CategoryInfo category={category.selectItem}/>
          <ShowMovie isShown={movie_id !== undefined && movie.data !== null}
                     type={category.selectItem.type}
                     movie={movie.data!}/>
          <Movies category={category.selectItem}
                  movies={category_movies.data[category_id] || []}/>
          <Footer/>
        </StickyContainer>
      </div>
    );
  }
}

export default connect(
  (state: AppState) => ({
    category: state.category,
    category_movies: state.category_movies,
    movie: state.movie
  }),
  { requestCategoryDetail, requestCategoryMovies, requestMovieDetail }
)(withI18n()(Lesson));