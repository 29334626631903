import React from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {sendNotification} from "learning-redux-store";
import i18next from 'i18next';

export default function guestCheck(WrappedComponent) {

    class guestCheckComponent extends WrappedComponent {

        state = {
            isAuthenticated: false
        };

        static getDerivedStateFromProps() {
            if (!localStorage.getItem('user_session')) {
                return {isAuthenticated: false};
            } else {
                return {isAuthenticated: true};
            }
        }

        render() {
            if (this.state.isAuthenticated) {
                this.props.sendNotification({message: i18next.t('既にログインしています。')});
                return <Redirect to='/'/>
            }
            return super.render();
        }
    }

    return connect(state => ({}), {sendNotification})(guestCheckComponent);
}