import React from 'react';
import { connect } from 'react-redux';
import { AppState, requestTokenRefresh } from 'learning-redux-store';
import UserRefreshProps from 'learning-redux-store/dist/props/user_refresh';

class TokenRefresh extends React.Component<UserRefreshProps> {

  componentDidMount() {
    this.props.requestTokenRefresh();
  }

  render() {
    return null;
  }
}

export default connect(
  (state: AppState) => ({
    user_refresh: state.user_refresh
  }),
  { requestTokenRefresh }
)(TokenRefresh);