import React from 'react';
import { Category } from 'learning-redux-store';
import styled from '../../helpers/styled';
import RecipeItem from './RecipeItem';

const Section = styled.section`
  padding: 16px 0;
`;

interface Props {
  categories: Category[];
}

const RecipeList: React.FC<Props> = ({ categories }) => {
  return (
    <Section>
      {categories.slice(1).map(category => (
        <RecipeItem category={category} key={category.id}/>
      ))}
    </Section>
  );
};

export default RecipeList;
