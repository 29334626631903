import styled from '../../helpers/styled';

export const UserInformationBox = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid #616161;
    width: 1140px;
    max-width: 100%;
    margin: 0 auto 8px;
    padding: 0 8px;
`;

export const LogoutButton = styled.button`
    padding: 4px 16px;
    background-color: #000000;
    color: #FFFFFF;
    border: 1px solid #000000;
    margin-top: 8px;
    font-size: 16px;
    transition: 0.2s all;
    
    &:hover, &:active, &:focus {
      color: #000000;
      background-color: #FFFFFF;
    }
`;

export const InformationTitle = styled.h2`
    font-size: 32px;
    width: 1140px;
    max-width: 100%;
    margin: 32px auto 24px;
    padding: 0 8px;
`;

export const InformationLeft = styled.div`
    width: 270px;
    padding: 16px 0;
    color: #616161;
    font-size: 20px;
`;

export const InformationRight = styled.div`
    flex: 1;
    max-width: 100%;
    padding: 16px 0;
`;