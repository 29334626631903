import * as React from 'react';
import i18nProps from '../helpers/i18n';
import CategoryProps from 'learning-redux-store/dist/props/category';
import {
  AppState,
  CampaignCodeState,
  CategoryState,
  clearCheckCampaignCode, requestBulkPrice,
  requestCategories, requestCheckCampaignCode
} from 'learning-redux-store';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import ActivityIndicator from '../components/ActivityIndicator';
import NotFound from './NotFound';
import CampaignCodeProps from 'learning-redux-store/dist/props/campaign_code';
import Payment from '../components/Lesson/Payment';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import ConfirmForm from '../components/Order/ConfirmForm';
import CampaignCodeForm from '../components/Order/CampaignCodeForm';
import AllDetail from '../components/Order/AllDetail';
import loginRequired from '../middleware/loginRequired';
import { RouteComponentProps } from 'react-router';

interface Props extends i18nProps, CampaignCodeProps, CategoryProps, RouteComponentProps<{ target }> {
  campaign_code: CampaignCodeState;
  category: CategoryState;
}

class OrderBulk extends React.Component<Props> {

  state = {
    code: '',
    price: null,
    ordered: false
  };

  price: number = 0;

  componentDidMount(): void {
    this.props.clearCheckCampaignCode();
    this.props.requestCategories();
    this.props.requestBulkPrice();
  }

  render() {
    const target = parseInt(this.props.match.params.target);
    const { category, campaign_code, t } = this.props;

    if (category.isProcessing || category.bulkPrice.data === 0) {
      return <section style={{ textAlign: 'center' }}><ActivityIndicator color={'#000000'}/></section>;
    } else if (category.error || category.data.length === 0) {
      return <NotFound/>;
    }

    if (target === 0) {
      this.price = category.bulkPrice.campaign || category.bulkPrice.data;
    } else if (target === 1) {
      this.price = category.bulkPrice.home_campaign || category.bulkPrice.home_data;
    } else if (target === -1) {
      this.price = category.bulkPrice.all_campaign || category.bulkPrice.all_data;
    } else {
      return <NotFound/>;
    }

    if (this.state.ordered) {
      return <Payment code={this.state.code} target={target}/>;
    }

    return (
      <section>
        <Helmet title={`${t('ご購入の確認')} | Japanese Cuisine.com`}/>
        <Header subTitle={t('ご購入の確認')}/>
        <AllDetail price={this.price} target={target}/>
        <CampaignCodeForm
          code={this.state.code}
          error={campaign_code.error}
          isProcessing={campaign_code.isProcessing}
          isHidden={this.state.price !== null && this.state.price !== undefined}
          onChangeCode={this.onChangeCode}
          onSubmit={this.onCheckCode}/>
        <ConfirmForm
          onSubmit={this.onSubmit}
          isHidden={campaign_code.error !== undefined && campaign_code.error !== null}
          itemPrice={this.price}
          price={this.state.price}/>
      </section>
    );
  }

  onChangeCode = (code: string) => {
    this.setState({ code: code || '' });
  };

  onCheckCode = () => {
    if (this.price === 0) {
      return;
    }

    if (this.state.code === undefined || this.state.code.length === 0) {
      this.setState({ price: 0 });
      this.props.clearCheckCampaignCode();
    } else {
      this.props.requestCheckCampaignCode({
        code: this.state.code,
        price: this.price,
        callback: this.onSuccessCampaignCode
      });
    }
  };

  onSuccessCampaignCode = (price: number) => {
    this.setState({ price });
  };

  onSubmit = () => {
    this.setState({ ordered: true });
  };
}

export default connect(
  (state: AppState) => ({
    category: state.category,
    campaign_code: state.campaign_code
  }),
  { requestCategories, requestBulkPrice, clearCheckCampaignCode, requestCheckCampaignCode }
)(withI18n()(loginRequired(OrderBulk)));
