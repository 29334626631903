import * as React from 'react';
import styled from '../../helpers/styled';
import MovieVideoPlayer from '../MovieVideoPlayer';
import { Movie } from 'learning-redux-store';
import { HashLink } from '../../helpers/react-router-hash-link';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';
import { isLogin } from '../../helpers/isLogin';

const ShowMovieSection = styled.section`
    width: 1024px;
    max-width: 100%;
    padding: 8px;
    margin: 0 auto;
    position: relative;
`;

const MovieAnchor = styled.div`
    position: absolute;
    top: -66px;
`;

const MovieTitle = styled.h3`
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.2em;
`;

const MovieContainer = styled.div`
    background-color: #000000;
    margin-bottom: 24px;
`;

const MoviePaymentInformation = styled.p`
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: 48px;
`;

const MoviePaymentLink = styled(HashLink)`
    font-weight: 700;
    font-size: 20px;
    color: white;
    background-color: #000000;
    border: 1px solid #000000;
    text-decoration: none;
    transition: 0.2s all;
    margin-top: 32px;
    padding: 8px 16px;
    display: inline-block;
    
    &:hover, &:active, &:focus {
      text-decoration: none;
      color: #000000;
      background-color: white;
    }
    
    span {
      font-weight: 400;
      font-size: 16px;
    }
`;

interface ShowMovieProps extends i18nProps {
  isShown: boolean;
  movie: Movie;
  type: number;
}

const ShowMovie: React.FC<ShowMovieProps> = ({ isShown, movie, type, t }) => {
  if (!isShown) {
    return null;
  }

  if (!movie.is_licensed) {
    if (type === 1) {
      return (
        <ShowMovieSection>
          <MovieAnchor id='show'/>
          <MoviePaymentInformation>
            {t('このレッスンは購入者特典となっております。')}<br/>
            {t('ぜひこの機会にMANABIのページからお好きなレッスンをご購入ください！')}<br/>
            <MoviePaymentLink to={'/manabi#top'}>MANABI <span>move on</span></MoviePaymentLink>
          </MoviePaymentInformation>
        </ShowMovieSection>
      );
    } else if (isLogin()) {
      return (
        <ShowMovieSection>
          <MovieAnchor id='show'/>
          <MoviePaymentInformation>
            {t('このレッスンは有料となっております。')}<br/>
            {t('ぜひこの機会にレッスンをご購入ください！')}<br/>
            <MoviePaymentLink to={`/cart/${movie.purchase_unit_id}`}>
              {t('お支払いページへ')}
            </MoviePaymentLink>
          </MoviePaymentInformation>
        </ShowMovieSection>
      );
    } else {
      window.location.href = `/auth/login?prev=/categories/${movie.category_id}/${movie.id}`;
      return null;
    }
  }

  return (
    <ShowMovieSection>
      <MovieAnchor id='show'/>
      <MovieTitle>{movie.title}</MovieTitle>
      <MovieContainer>
        <MovieVideoPlayer
          videoKey={movie.key}
          thumbKey={movie.image}
          isFree={type === 2}/>
      </MovieContainer>
    </ShowMovieSection>
  );
};

export default withI18n()(ShowMovie);
