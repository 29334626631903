import * as React from 'react';
import { Category } from 'learning-redux-store';
import i18nProps from '../../helpers/i18n';
import styled from '../../helpers/styled';
import { withI18n } from 'react-i18next';
import AspectImage from '../AspectImage';
import { asset_endpoint } from '../../config/app';
import noImage from '../../images/no_image.png';

const Section = styled.section`
  width: 768px;
  max-width: 100%;
  padding: 8px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;

const ImageBox = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
`;

const Image = styled(AspectImage)`
  width: 100%;
  height: 100%;
`;

const DetailBox = styled.div`
  height: 100px;
  flex: 1;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DetailText = styled.p`
  margin-bottom: 0;
`;

interface Props extends i18nProps {
  category: {
    item_image: string | null;
    title: string;
    price: number
  }
}

const CategoryDetail: React.FC<Props> = ({ category, t }) => {

  return (
    <Section>
      <ImageBox>
        {category.item_image ? (
          <Image src={`${asset_endpoint}/uploads/category_photos/${category.item_image}?w=300`}/>
        ) : (
          <Image src={noImage}/>
        )}
      </ImageBox>
      <DetailBox>
        <DetailText><strong>{t('ご購入商品')}</strong>: {category.title}</DetailText>
        <DetailText><strong>{t('価格')}</strong>: {category.price}{t('円')} + {t('税')}</DetailText>
        <DetailText><strong>{t('視聴期限')}</strong>: {t('ご購入から90日間')}</DetailText>
      </DetailBox>
    </Section>
  );
};

export default withI18n()(CategoryDetail);