import * as React from 'react';
import styled from '../../helpers/styled';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import { useMemo } from 'react';

const Section = styled.section`
  width: 768px;
  max-width: 100%;
  padding: 8px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;

const DetailBox = styled.div`
  height: 100px;
  flex: 1;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DetailText = styled.p`
  margin-bottom: 0;
`;


interface Props extends i18nProps {
  price: number;
  target: number;
}

const AllDetail: React.FC<Props> = ({ price, t, target }) => {

  const productName = useMemo(() => {
    if (target === 0) {
      return t('全レッスンセット');
    } else if (target === 1) {
      return t('「菊乃井 本店」プロデュースの全レシピ');
    } else if (target === -1) {
      return t('プロンズ・カリキュラム＋レシピ動画"');
    }
  }, [target]);

  return (
    <Section>
      <DetailBox>
        <DetailText><strong>{t('ご購入商品')}</strong>: {productName}</DetailText>
        <DetailText><strong>{t('価格')}</strong>: {price}{t('円')} + {t('税')}</DetailText>
        <DetailText><strong>{t('視聴期限')}</strong>: {t('ご購入から180日間')}</DetailText>
      </DetailBox>
    </Section>
  );
};

export default withI18n()(AllDetail);
