import styled, { css } from '../../helpers/styled';
import background from '../../images/top/about_background.png';
import { combineMobileStyle } from '../../helpers/mediaQuery';

export const SignInScreen = styled.div`
    background-image: url(${background});
    background-size: cover;
    background-position: bottom;
    min-height: 100vh;
`;

export const FlexPC = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding: 8px;
    min-height: 100vh;
    width: 1140px;
    max-width: 100%;
    margin: 0 auto;
`;

export const FlexMobile = css`
    flex-direction: column-reverse;
`;

export const Flex = combineMobileStyle(FlexPC, FlexMobile);