import * as React from 'react';
import i18nProps from '../helpers/i18n';
import { withI18n } from 'react-i18next';
import styled from '../helpers/styled';
import { HashLink } from '../helpers/react-router-hash-link';
import locale from '../helpers/locale';

const Box = styled.div`
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const BronzeLinkBanner: React.FC<i18nProps> = ({ t }) => {
  if (locale() === 'ja') {
    return <></>;
  }
  const bannerImage = require(`../images/${t('bronze_link_banner')}`);
  return (
    <Box>
      <HashLink to={`/bronze`}>
        <Image src={bannerImage}/>
      </HashLink>
    </Box>
  );
};

export default withI18n()(BronzeLinkBanner);