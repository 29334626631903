import React from 'react';
import { withI18n } from 'react-i18next';
import SectionHeader from './SectionHeader';
import styled, { css } from '../../helpers/styled';
import i18nProps from '../../helpers/i18n';
import { News as NewsItem } from 'learning-redux-store';
import { asset_endpoint } from '../../config/app';
import nl2br from '../../helpers/nl2br';
import locale from '../../helpers/locale';
import moment from 'moment';
import { combineMobileStyle, combineTabletMobileStyle } from '../../helpers/mediaQuery';

const NewsSection = styled.section`
    padding: 32px 0;
`;

const Anchor = styled.div`
    margin-top: -82px;
    padding-top: 82px;
`;

const NewsListBox = styled.div`
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #F1F1F1;
`;

const NewsListItemPc = styled.article`
  width: 33%;
  padding: 8px;
`;

const NewsListItemTablet = css`
  width: 50%;
`;

const NewsListItemMobile = css`
  width: 100%;
`;

const NewsListItem = combineTabletMobileStyle(NewsListItemPc, NewsListItemTablet, NewsListItemMobile);

const NewsBackground = styled.div`
  border-radius: 2px;
  background: #FFFFFF;
  height: 100%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: #D1D1D1;
  }
`;

const NewsImageBox = styled.div`
  height: 200px;
  text-align: center;
`;

const NewsImageBoxDummy = styled.div`
  height: 200px;
  text-align: center;
  background: #F1F1F1;
`;

const NewsImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const NewsPublished = styled.p`
  font-weight: 400;
`;

const NewsTitle = styled.h4`
  font-weight: 600;
`;

const NewsBody = styled.p`
  font-weight: 400;
`;

interface Props extends i18nProps {
  news: NewsItem[];
  onNewsClick: (news: NewsItem) => void;
}

const News: React.FC<Props> = ({ t, news, onNewsClick }) => {

  let elements: JSX.Element[] = [];
  news.forEach(item => {
    let published: string;
    if (locale() === 'en') {
      published = moment(item.published_at).format('MMMM. DD, YYYY');
    } else {
      published = moment(item.published_at).format('YYYY/MM/DD');
    }

    let body: string = '';
    if (item.body) {
      const split = item.body.split('\n');
      body = split.slice(0, 2).join('\n');
      if (split.length > 2) {
        body += '...';
      }
    }

    elements.push(
      <NewsListItem key={item.id}>
        <NewsBackground onClick={() => onNewsClick(item)}>
          {(() => {
            if (item.image) {
              return (
                <NewsImageBox>
                  <NewsImage src={`${asset_endpoint}/uploads/news_photos/${item.image}`}/>
                </NewsImageBox>
              );
            } else {
              return <NewsImageBoxDummy/>;
            }
          })()}
          <NewsPublished>{published}</NewsPublished>
          <NewsTitle>{item.title}</NewsTitle>
          <NewsBody>{nl2br(body)}</NewsBody>
        </NewsBackground>
      </NewsListItem>
    );
  });

  return (
    <NewsSection>
      <Anchor id="news"/>
      <SectionHeader>NEWS</SectionHeader>
      <NewsListBox>
        {elements}
      </NewsListBox>
    </NewsSection>
  );
};

export default withI18n()(News);