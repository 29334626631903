import React from 'react';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  AppState,
  ForgotPasswordState,
  requestForgotPassword,
  sendNotification,
  ForgotPasswordProps,
  NotificationProps
} from 'learning-redux-store';
import guestCheck from '../middleware/guestCheck';
import Header from '../components/Header';
import Footer from '../components/Instructor/Footer';
import ActivityIndicator from '../components/ActivityIndicator';
import { Container, Description, EmailField, ErrorMessage, SubmitButton } from '../components/ForgotPassword/Elements';
import i18nProps from '../helpers/i18n';

interface Props extends i18nProps, ForgotPasswordProps, NotificationProps {
  forgot_password: ForgotPasswordState;
}

class ForgotPassword extends React.Component<Props> {

  state = {
    email: ''
  };

  render() {
    const { t, forgot_password } = this.props;

    return (
      <div>
        <Helmet title={`${t('パスワードを忘れた方へ')} | Japanese Cuisine.com`}/>

        <Header subTitle={t('パスワードを忘れた方へ')}/>
        <Container>
          <Description>
            {t('ご登録のメールアドレスを入力してください。')}<br/>
            {t('メールアドレスをお忘れの場合は恐れ入りますがお問い合わせください。')}
          </Description>
          {forgot_password.error && <ErrorMessage>{t('エラーが発生しました。恐れ入りますがご入力が正しいかご確認ください。')}</ErrorMessage>}
          <EmailField type={'email'}
                      placeholder={t('メールアドレス')}
                      value={this.state.email}
                      onChange={this.onChangeField}/>
          <SubmitButton type='submit' onClick={this.onSubmit}>
            {forgot_password.isProcessing ? <ActivityIndicator/> : t('送信')}
          </SubmitButton>
        </Container>
        <Footer/>
      </div>
    );
  }

  onChangeField = (e) => {
    this.setState({ email: e.target.value });
  };

  onSubmit = () => {
    this.props.requestForgotPassword({
      email: this.state.email,
      callback: this.onSuccess
    });
  };

  onSuccess = () => {
    const { t } = this.props;
    this.props.sendNotification({
      message: t('メールアドレスにパスワードのリセット手続きをお送りしました。')
    });
    this.setState({
      email: ''
    });
  };
}

export default connect(
  (state: AppState) => ({
    forgot_password: state.forgot_password
  }),
  { requestForgotPassword, sendNotification }
)(withI18n()(guestCheck(ForgotPassword)));