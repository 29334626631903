import React from 'react';
import styled, { css } from '../../helpers/styled';
import { withI18n } from 'react-i18next';
import Slide from 'react-reveal/Slide';
import SectionHeader from './SectionHeader';
import contentManabiImage from '../../images/top/contents_manabi.png';
import contentDouguImage from '../../images/top/contents_dougu.png';
import FontAwesome from 'react-fontawesome';
import { combileNoteStyle, combineMobileStyle, combineTabletMobileStyle } from '../../helpers/mediaQuery';
import MemberBox from '../MemberBox';
import { HashLink } from '../../helpers/react-router-hash-link';
import i18nProps from '../../helpers/i18n';
import { asset_endpoint, dougu_url } from '../../config/app';
import VideoPlayer from '../VideoPlayer';
import locale from '../../helpers/locale';
import nl2br from '../../helpers/nl2br';

const ContentsSection = styled.section`
    padding: 32px 0;
`;

const Description = styled.p`
    text-align: center;
    font-size: 32px;
    letter-spacing: 0.1em;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 24px;
`;

const DescriptionMobile = css`
    font-size: 24px;
`;

const DescriptionCombined = combineMobileStyle(Description, DescriptionMobile);

const MoreInformation = styled.p`
    text-align: center;
    font-size: 16px;
    font-weight: 200;
    margin-bottom: 48px;
`;

const ContentBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-height: 400px;
    overflow: hidden;
`;

const ContentBoxMobile = css`
    height: auto;
`;

const ContentBoxCombine = combineMobileStyle(ContentBox, ContentBoxMobile);

const ManabiText = styled.div`
    width: 50%;
    background-color: #DC0027;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-decoration: none;
`;

const ShikomiText = styled.a`
    width: 50%;
    background-color: #13939F;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s;
    
    :hover {
      background-color: rgba(19,147,159,0.8);
      color: white;
      text-decoration: none;
    }
`;

const ContentMobile = css`
    width: 100%;
`;

const ManabiCombine = combineMobileStyle(ManabiText, ContentMobile);

const ShikomiCombine = combineMobileStyle(ShikomiText, ContentMobile);

const ContentArticle = styled.article`
    width: 70%;
    padding-right: 48px;
    padding-bottom: 48px;
`;

const ContentArticleMobile = css`
    width: 100%;
    padding: 16px 8px !important;
`;

const ContentArticleTablet = css`
    width: 100%;
    padding: 24px 8px !important;
`;

const ContentArticleCombine = combineTabletMobileStyle(ContentArticle, ContentArticleTablet, ContentArticleMobile);

const ContentArticleRight = styled.article`
    width: 70%;
    padding-left: 48px;
    padding-bottom: 48px;
`;

const ContentArticleCombineRight = combineTabletMobileStyle(ContentArticleRight, ContentArticleTablet, ContentArticleMobile);

const ContentTopHeaderPc = styled.h4`
    text-align: center;
    font-size: 32px;
    letter-spacing: 0.2em;
    &::after {
      content: '';
      border-bottom: 5px solid white;
      display: block;
      width: 100%;
      margin: 8px auto 0;
    }
`;

const ContentTopHeaderTablet = css`
    font-size: 20px;
`;

const ContentTopHeader = combileNoteStyle(ContentTopHeaderPc, ContentTopHeaderTablet);

const ContentSecondHeaderPc = styled.h4`
    text-align: center;
    font-size: 56px;
    font-weight: 900;
    letter-spacing: 0.1em;
    margin-bottom: 48px;
`;

const ContentSecondHeaderTablet = css`
    font-size: 40px;
`;

const ContentSecondHeader = combileNoteStyle(ContentSecondHeaderPc, ContentSecondHeaderTablet);

const ContentDescription = styled.p`
    font-size: 14px;
    font-weight: 200;
`;

const ContentImageBox = styled.div`
    width: 50%;
    max-width: 711px;
    background-color: #000000;
`;

const ContentImageMobile = css`
    width: 100%;
`;

const ContentImageCombine = combineMobileStyle(ContentImageBox, ContentImageMobile);

const ArticleMore = styled.p`
    color: white;
    font-weight: 700;
    letter-spacing: 0.3em;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const MoreArrow = styled(FontAwesome)`
    font-size: 20px !important;
    margin-left: 4px;
`;

const ImageBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const ManabiLink = styled(HashLink)`
  flex: 1;
  display: block;
  margin-left: 8px;
  background-color: black;
  
  &:first-of-type {
    margin-right: 8px;
    margin-left: 0;
  }
`;

const ManabiImage = styled.img`
  max-width: 100%;
  
  &:hover {
    opacity: 0.8;
  }
`;

const Contents: React.FC<i18nProps> = ({ t }) => {

  const manabi01Image = require(`../../images/top/${t('manabi_01_png')}`);
  const manabi02Image = require(`../../images/top/${t('manabi_02_png')}`);

  return (
    <ContentsSection id='contents'>
      <SectionHeader>CONTENTS</SectionHeader>
      <DescriptionCombined>
        {t('“日本料理”を極めるための２つのコンテンツ。')}<br/>
        {t('MANABIとDOUGU')}
      </DescriptionCombined>
      <MoreInformation>
        {t('技術やノウハウ・考え方を伝える日本料理の学習サイト「MANABI」。調理道具や教科書などの')}<br/>
        {t('ツールを入手する販売サイト「DOUGU」。二つのサイトで、技術と道具が一体化した“日本料理”の本質を学び、資格習得までをていねいにサポートしています。')}<br/>
      </MoreInformation>
      <Slide left>
        <ContentBoxCombine>
          <ManabiCombine>
            <ContentArticleCombine>
              <ContentTopHeader>{t('日本料理を学ぶ')}</ContentTopHeader>
              <ContentSecondHeader>MANABI</ContentSecondHeader>
              <ContentDescription>
                {t('調理技術だけではなく、日本の自然環境との関係まで含めて捉えるのが“日本料理”。')}
                {t('料理に対する「哲学」から、多種類の包丁の使い方にいたるまで、こまやかな動画でじっくりと理解が進みます。')}
              </ContentDescription>
              <ImageBox>
                <ManabiLink to='/manabi#start'>
                  <ManabiImage src={manabi01Image}/>
                </ManabiLink>
                <ManabiLink to='/recipe#top'>
                  <ManabiImage src={manabi02Image}/>
                </ManabiLink>
              </ImageBox>
            </ContentArticleCombine>
          </ManabiCombine>
          <ContentImageCombine>
            <VideoPlayer
              url={`${asset_endpoint}/movies/spirit_${locale()}/master.m3u8`}
              thumbUrl={contentManabiImage}
              autoPlay={false}/>
          </ContentImageCombine>
        </ContentBoxCombine>
      </Slide>
      <MemberBox/>
      <Slide right>
        <ContentBoxCombine>
          <ContentImageCombine style={{ marginLeft: 'auto' }}>
            <VideoPlayer
              url={`${asset_endpoint}/movies/tool_${locale()}/master.m3u8`}
              thumbUrl={contentDouguImage}
              autoPlay={false}/>
          </ContentImageCombine>
          <ShikomiCombine href={`${dougu_url}/locale/${locale()}?top=1`}>
            <ContentArticleCombineRight>
              <ContentTopHeader>{t('日本料理を揃える')}</ContentTopHeader>
              <ContentSecondHeader>DOUGU</ContentSecondHeader>
              <ContentDescription>
                {nl2br(t('魚の種類や部位ごとで使い分ける何種類もの包丁。根菜をすりおろすためだけのおろし金。さまざまな「専用道具」で組み立てられた日本料理の用具を高品質に取り揃え、いますぐ購入いただけます。'))}
              </ContentDescription>
              <ArticleMore>more<MoreArrow name='angle-right'/></ArticleMore>
            </ContentArticleCombineRight>
          </ShikomiCombine>
        </ContentBoxCombine>
      </Slide>
    </ContentsSection>
  );
};

export default withI18n()(Contents);
