import React from 'react';
import styled, { css } from '../helpers/styled';
import logoImage from '../images/site_name_logo_with_icon.png';
import SlideMenu from './SlideMenu';
import { combineMobileStyle } from '../helpers/mediaQuery';
import { HashLink } from '../helpers/react-router-hash-link';
import { connect } from 'react-redux';
import SignOutProps from 'learning-redux-store/dist/props/signOut';
import { requestSignOut } from 'learning-redux-store';

const HeaderView = styled.header`
    z-index: 2000;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    position: relative;
`;

const Title = styled.h1`
    margin: 0 0 0 10%;
    z-index: 2;
`;

const TitleMobile = css`
    display: none;
`;

const TitleCombined = combineMobileStyle(Title, TitleMobile);

const SiteLogoImage = styled.img`
    width: 200px;
    margin-bottom: 10px;
`;

const PageTopLink = styled.span`
    color: #000000;
    text-decoration: none;
    :hover, :active, :focus {
      color: #000000;
      text-decoration: none;
    }
`;

const Page = styled.h2`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 10% 0 0;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #000000;
    text-decoration: none;
`;

const PageMobile = css`
    margin: 0;
`;

const PageCombined = combineMobileStyle(Page, PageMobile);

const PageBigText = styled.span`
    font-size: 32px;
    display: block;
    font-weight: 700;
    margin-left: 16px;
`;

const ToggleBox = styled.div`
    display: -webkit-flex;
    align-items: center;
    -moz-margin-start: auto;
    -webkit-margin-start: auto;
    margin-left: auto;
    margin-right: 10%;
    display: flex;
    z-index: 2;
`;

const MenuTrigger = styled.a`
    display: inline-block;
    transition: all .2s;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    width: 35px;
    height: 44px;
`;

const MenuTriggerItem = styled.span`
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #616161;
    
    :nth-of-type(1) {
      top: 10px;
    }
    :nth-of-type(2) {
      top: 20px;
    }
    :nth-of-type(3) {
      bottom: 10px;
    }
`;

const ScrollTopBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

interface Props extends SignOutProps {
  containerStyle?: object;
  title?: string;
  subTitle?: string;
}

class Header extends React.Component<Props> {

  state = {
    menuShow: false,
    isFullscreen: false
  };

  componentDidMount(): void {
    document.addEventListener('webkitfullscreenchange', this.changeHandler, false);
    document.addEventListener('mozfullscreenchange', this.changeHandler, false);
    document.addEventListener('fullscreenchange', this.changeHandler, false);
    document.addEventListener('MSFullscreenChange', this.changeHandler, false);
  }

  componentWillUnmount(): void {
    document.removeEventListener('webkitfullscreenchange', this.changeHandler, false);
    document.removeEventListener('mozfullscreenchange', this.changeHandler, false);
    document.removeEventListener('fullscreenchange', this.changeHandler, false);
    document.removeEventListener('MSFullscreenChange', this.changeHandler, false);
  }

  render() {
    const { containerStyle, title, subTitle } = this.props;

    return (
      <div style={{display: this.state.isFullscreen ? 'none': 'block'}}>
        <HeaderView style={containerStyle}>
          <ScrollTopBar onClick={this.scrollTop}/>
          <TitleCombined style={{ display: (title || subTitle) ? '' : 'block' }}>
            <HashLink to="/#top" smooth>
              <SiteLogoImage src={logoImage} alt='japanese-cuisine'/>
            </HashLink>
          </TitleCombined>
          {(() => {
            if (title || subTitle) {
              return (
                <PageCombined>
                  <PageTopLink>{subTitle}</PageTopLink>
                  <PageBigText><PageTopLink>{title}</PageTopLink></PageBigText>
                </PageCombined>
              );
            } else {
              return null;
            }
          })()}
          <ToggleBox>
            <MenuTrigger type="button" onClick={this.clickMenu}>
              <MenuTriggerItem/>
              <MenuTriggerItem/>
              <MenuTriggerItem/>
            </MenuTrigger>
          </ToggleBox>
        </HeaderView>
        <SlideMenu
          menuShow={this.state.menuShow}
          onLogout={this.onLogout}
          onClickToggle={this.clickMenu}/>
      </div>
    );
  }

  clickMenu = () => {
    this.setState({
      menuShow: !this.state.menuShow
    });
  };

  onLogout = () => {
    this.props.requestSignOut();
    this.setState({
      menuShow: false
    });
  };

  scrollTop = () => {
    const root = document.getElementById('root');
    if (root) {
      root.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  };

  changeHandler = () => {
    const isFullscreen = document['fullScreenElement'] || document['webkitIsFullScreen'] === true || document['mozFullScreen'] || document['msFullscreenElement'];
    this.setState({ isFullscreen });
  };
}

export default connect(
  null,
  { requestSignOut }
  // @ts-ignore
)(Header);