import * as React from 'react';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import styled, { css } from '../../helpers/styled';
import { asset_endpoint } from '../../config/app';
import VideoPlayer from '../VideoPlayer';
import { combineMobileStyle } from '../../helpers/mediaQuery';
import elpvThumb from '../../images/top/elpv_thumb.png';

const SectionPc = styled.section`
  margin: 24px 0;
  padding: 0 10%;
`;

const SectionMobile = css`
  padding: 0 16px;
`;

const Section = combineMobileStyle(SectionPc, SectionMobile);

const PromotionVideo: React.FC<i18nProps> = ({ t }) => {
  const videoUrl = `${asset_endpoint}/movies/${t('elpv')}/master.m3u8`;

  return (
    <Section>
      <VideoPlayer url={videoUrl} thumbUrl={elpvThumb} autoPlay={false}/>
    </Section>
  );
};

export default withI18n()(PromotionVideo);