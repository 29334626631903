import React from 'react';
import styled, { css } from '../../helpers/styled';
import { combineMobileStyle } from '../../helpers/mediaQuery';
import { Category, Movie } from 'learning-redux-store';
import MovieSkillItem from './MovieSkillItem';
import MovieLectureItem from './MovieLectureItem';

const MoviesSection = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1024px;
    max-width: 100%;
    margin: 0 auto;
`;

const SkillBox = styled.div`
    width: 75%;
    padding: 8px 24px 8px 8px;
    border-right: 1px solid #a1a1a1;
    margin-bottom: 24px;
`;

const SkillBoxMobile = css`
    width: 100%;
    padding: 8px;
    border-right: none;
    margin-bottom: 0;
`;

const SkillBoxCombined = combineMobileStyle(SkillBox, SkillBoxMobile);

const LectureBox = styled.div`
    flex: 1;
    padding: 8px 8px 8px 24px;
`;

const LectureBoxMobile = css`
    padding: 8px;
`;

const LectureBoxCombined = combineMobileStyle(LectureBox, LectureBoxMobile);

const BoxHeader = styled.p`
    text-align: center;
    border: 1px solid #000000;
`;

const SkillFlex = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

interface Props {
  category: Category;
  movies: Movie[];
}

const Movies: React.FC<Props> = ({ category, movies }) => {

  let skills: JSX.Element[] = [];
  let lectures: JSX.Element[] = [];
  movies.forEach(movie => {
    if (movie.type === 0) {
      skills.push(<MovieSkillItem movie={movie} key={movie.id}/>);
    } else if (movie.type === 1) {
      lectures.push(<MovieLectureItem movie={movie} key={movie.id}/>);
    }
  });

  return (
    <MoviesSection>
      <SkillBoxCombined>
        <BoxHeader>{category.skill_count} skill</BoxHeader>
        <SkillFlex>
          {skills}
        </SkillFlex>
      </SkillBoxCombined>
      <LectureBoxCombined>
        <BoxHeader>{category.lecture_count} lecture</BoxHeader>
        {lectures}
      </LectureBoxCombined>
    </MoviesSection>
  );
};

export default Movies;

