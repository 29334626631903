import * as React from 'react';
import { connect } from 'react-redux';
import {
  AppState,
  requestQuestionCategoryList,
  requestQuestionList,
  QuestionCategoryState,
  QuestionCategoryProps,
  QuestionState,
  QuestionProps,
} from 'learning-redux-store';
import { withI18n } from 'react-i18next';
import { Sticky, StickyContainer } from 'react-sticky';
import Header from '../components/Header';
import QuestionCategory from '../components/Question/QuestionCategory';
import Footer from '../components/Instructor/Footer';
import { QuestionHeader, QuestionScreen } from '../components/Question/Elements';
import i18nProps from '../helpers/i18n';
import { Helmet } from 'react-helmet-async';

interface Props extends QuestionCategoryProps, QuestionProps, i18nProps {
  question_category: QuestionCategoryState;
  question: QuestionState;
}

class Question extends React.Component<Props> {

  componentDidMount() {
    this.props.requestQuestionCategoryList();
  }

  render() {
    const { t, question_category, question } = this.props;

    let categories: JSX.Element[] = [];
    question_category.data.forEach(category => {
      categories.push(
        <QuestionCategory
          key={category.id}
          category={category}
          questions={question.data[category.id] || []}
          onChangeCategory={this.onChangeCategory}/>
      );
    });

    return (
      <QuestionScreen>
        <Helmet title={`${t('よくあるご質問')} | Japanese Cuisine.com`}/>
        <StickyContainer>
          <Sticky>
            {({ style }) => <Header containerStyle={style} subTitle={t('よくあるご質問')}/>}
          </Sticky>
          <QuestionHeader>
            {t('質問のカテゴリーを選択してください。')}
          </QuestionHeader>
          {categories}
          <Footer/>
        </StickyContainer>
      </QuestionScreen>
    );
  }

  onChangeCategory = (e) => {
    this.props.requestQuestionList(e.target.value);
  };
}

export default connect(
  (state: AppState) => ({
    question_category: state.question_category,
    question: state.question
  }),
  { requestQuestionCategoryList, requestQuestionList }
)(withI18n()(Question));