import * as React from 'react';
import { withI18n } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Link, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import i18nProps from '../helpers/i18n';

interface Props extends RouteComponentProps, i18nProps {

}

const PaymentCancel: React.FC<Props> = ({ location, t }) => {
  const params = queryString.parse(location.search);
  const backLink = (() => {
    if (params.recent) {
      return <Link to={params.recent as string}>Back to Lesson</Link>;
    } else {
      return <Link to='/'>Back to Home</Link>;
    }
  })();
  return (
    <section style={{ textAlign: 'center' }}>
      <Helmet>
        <title>{t('Payment Cancel')} | Japanese Cuisine.com</title>
      </Helmet>

      <h1>{t('Payment Cancel')}</h1>

      <p>
        {t('The payment is canceled.')}
      </p>
      <p>
        {backLink}
      </p>
    </section>
  );
};

export default withI18n()(PaymentCancel);