import React from 'react';
import { withI18n } from 'react-i18next';
import Slider from 'react-slick';
import { Fade } from 'react-reveal';
import styled, { css } from '../../helpers/styled';
import ss1 from '../../images/top/slideshow/ss1.jpg';
import ss2 from '../../images/top/slideshow/ss2.jpg';
import ss3 from '../../images/top/slideshow/ss3.jpg';
import ss4 from '../../images/top/slideshow/ss4.jpg';
import ss5 from '../../images/top/slideshow/ss5.jpg';
import ss6 from '../../images/top/slideshow/ss6.jpg';
import ss7 from '../../images/top/slideshow/ss7.jpg';
import ss9 from '../../images/top/slideshow/ss9.jpg';
import ss10 from '../../images/top/slideshow/ss10.jpg';
import ss11 from '../../images/top/slideshow/ss11.jpg';
import { combineTabletMobileStyle } from '../../helpers/mediaQuery';
import nl2br from '../../helpers/nl2br';
import i18nProps from '../../helpers/i18n';

const SliderContainer = styled.div`
      overflow: hidden;
`;

const LeftArrow = styled.div`
    left: 16px !important;
    z-index: 1000;
    width: 40px !important;
    
    &:before {
      font-size: 40px !important;
      color: #000000 !important;
    }
`;

const RightArrow = styled.div`
    right: 16px !important;
    z-index: 1000;
    width: 40px !important;
    
    &:before {
      font-size: 40px !important;
      color: #000000 !important;
    }
`;

const WithTextBox = styled.div`
  position: relative;
  img {
    width: 100%;
    display: inline-block;
  }
`;

const FadeText = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  
  p {
    text-align: center;
    margin-bottom: 0;
    padding: 16px 8px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    letter-spacing: 0.2em;
    font-size: 20px;
    font-weight: 200;
  }
`;

const FadeTextTablet = css`
  p {
    font-size: 16px;
    letter-spacing: 0.1em;
  }
`;

const FadeTextMobile = css`
  p {
    padding: 8px 4px;
    font-size: 14px;
    letter-spacing: 0.1em;
  }
`;

const FadeTextCombined = combineTabletMobileStyle(FadeText, FadeTextTablet, FadeTextMobile);

class SlideShow extends React.Component<i18nProps> {

  state = {
    autoplaySpeed: 5000,
    currentPosition: 0,
    isFullscreen: false
  };

  settings = {
    infinite: true,
    autoplay: true,
    adaptiveHeight: false,
    pauseOnHover: false,
    fade: true,
    lazyLoad: 'ondemand',
    speed: 3000,
    arrows: true,
    prevArrow: <LeftArrow/>,
    nextArrow: <RightArrow/>
  };

  componentDidMount(): void {
    document.addEventListener('webkitfullscreenchange', this.changeHandler, false);
    document.addEventListener('mozfullscreenchange', this.changeHandler, false);
    document.addEventListener('fullscreenchange', this.changeHandler, false);
    document.addEventListener('MSFullscreenChange', this.changeHandler, false);
  }

  componentWillUnmount(): void {
    document.removeEventListener('webkitfullscreenchange', this.changeHandler, false);
    document.removeEventListener('mozfullscreenchange', this.changeHandler, false);
    document.removeEventListener('fullscreenchange', this.changeHandler, false);
    document.removeEventListener('MSFullscreenChange', this.changeHandler, false);
  }

  render() {
    let items: JSX.Element[] = [];

    const { t } = this.props;

    const ss0 = require(`../../images/top/slideshow/${t('ss0_png')}`);

    const ImageList: string[] = [
      ss0,
      ss1,
      ss2,
      ss0,
      ss3,
      ss4,
      ss0,
      ss5,
      ss6,
      ss0,
      ss7,
      ss9,
      ss0,
      ss10,
      ss11
    ];

    const TextList: (string | null)[] = [
      null,
      t('極めるとは極限を知ること。素材本来の旨味を極限まで引き出す伝来の技。'),
      t('膳とは、料理と器、一人用のテーブルの全て。膳の中に宇宙を閉じ込められている。'),
      null,
      t('すべての川が淀まぬ急流を持つ日本。美しき水が食の清潔さを創る。'),
      t('鮮(せん)は、新鮮さ。山海の美味を、素材のままに、活かし、食す、この国のならわし。'),
      null,
      t('すべての調理は、神(kami=TheCreator)への感謝から始まる。与えられた天啓を、ただ感じ取ること。'),
      t('旬とは、素材が熟すタイミング。機を逃さず、最上の幸福を素直に受け取る。'),
      null,
      t('米は常食する、日常にして特別なる種。神と人とをつなぐ文化の鍵。'),
      t('美とは調和する美しさ。料理と環境が、ひとつになり動き出す。'),
      null,
      t('季節は時間の区切り方。いまという豊かさを、華やかな形に整える。'),
      t('酒は神の水。心をゆるませ、うま味を強調する。')
    ];

    ImageList.forEach((item, index) => {
      const text = TextList[index] || undefined;
      if (text) {
        items.push(
          <WithTextBox key={index}>
            <img src={item} alt={text}/>
            <Fade bottom
                  delay={2000}
                  duration={1000}
                  spy={this.state.currentPosition === index}>
              <FadeTextCombined>
                <p>
                  {nl2br(text)}
                </p>
              </FadeTextCombined>
            </Fade>
          </WithTextBox>
        );
      } else {
        items.push(
          <WithTextBox key={index}>
            <img src={item} alt={text}/>
          </WithTextBox>
        );
      }
    });

    return (
      <SliderContainer style={{display: this.state.isFullscreen ? 'none' : 'block'}}>
        <Slider {...this.settings}
                autoplaySpeed={this.state.autoplaySpeed}
                beforeChange={this.beforeChange}>
          {items}
        </Slider>
      </SliderContainer>
    );
  }

  beforeChange = (_, newIndex) => {
    setTimeout(() => {
      let speed = 6000;
      if (newIndex % 3 === 0) {
        speed = 5000;
      }
      this.setState({
        autoplaySpeed: speed,
        currentPosition: newIndex
      });
    });
  };

  changeHandler = () => {
    const isFullscreen = document['fullScreenElement'] || document['webkitIsFullScreen'] === true || document['mozFullScreen'] || document['msFullscreenElement'];
    this.setState({ isFullscreen });
  };
}

export default withI18n()(SlideShow);