import React from 'react';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import styled, { css } from '../../helpers/styled';
import nl2br from '../../helpers/nl2br';
import { Category } from 'learning-redux-store';
import RecipeItem from './RecipeItem';
import { combineMobileStyle } from '../../helpers/mediaQuery';

const SectionPc = styled.section`
  margin-top: -80px;
  padding-bottom: 32px;
`;

const SectionMobile = css`
  margin-top: 0;
`;

const Section = combineMobileStyle(SectionPc, SectionMobile);

const IntroductionPc = styled.h3`
  color: white;
  margin: 0 0 24px 0;
  text-align: center;
  font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
  font-size: 32px;
  letter-spacing: 0.2em;
  font-weight: 200;
`;

const IntroductionMobile = css`
  font-size: 24px;
`;

const Introduction = combineMobileStyle(IntroductionPc, IntroductionMobile);

interface Props extends i18nProps {
  category?: Category;
}

const FreeRecipe: React.FC<Props> = ({t, category}) => {

  return (
    <Section id={'curriculum'}>
      <Introduction>
        {nl2br(t('まずは、無料レシピ動画を見てみましょう。'))}
      </Introduction>
      {category ? (
        <RecipeItem category={category}/>
      ) : <></>}
    </Section>
  )
};


export default withI18n()(FreeRecipe);
