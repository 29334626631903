import React from 'react';
import { withI18n } from 'react-i18next';
import styled, { css } from '../../helpers/styled';
import locale from '../../helpers/locale';
import { recaptcha_site_key } from '../../config/app';
import Recaptcha from 'react-google-invisible-recaptcha';
import ErrorInput from '../ErrorInput';
import ErrorTextArea from '../ErrorTextArea';
import i18nProps from '../../helpers/i18n';
import { combineMobileStyle } from '../../helpers/mediaQuery';

const FormPc = styled.form`
    width: 728px;
    max-width: 100%;
    padding: 24px 24px 48px;
    margin: 24px auto;
    background-color: #F5F5F5;
`;

const FormMobile = css`
    padding: 24px 8px 48px;
`;

const Form = combineMobileStyle(FormPc, FormMobile);

const Label = styled.label`
    font-weight: 700;
`;

const Input = styled(ErrorInput)`
    display: block;
    width: 100%;
    margin-bottom: 16px;
    padding: 4px;
    border: 1px solid #a1a1a1;
    background-color: transparent;
`;

const TextArea = styled(ErrorTextArea)`
    display: block;
    width: 100%;
    margin-bottom: 24px;
    border: 1px solid #a1a1a1;
    background-color: transparent;
    resize: vertical;
`;

const Button = styled.button`
    display: block;
    width: 100%;
    background-color: #000000;
    border: 1px solid #000000;
    color: white;
    font-size: 24px;
    padding: 8px;
    transition: all 0.2s;
    
    :hover, :active, :focus {
      background-color: #FFFFFF;
      color: #000000;
    }
`;

interface Props extends i18nProps {
  error: any;
  onSubmit: ({ name, email, country, body, recaptcha }) => void;
}

class ContactForm extends React.Component<Props> {

  state = {
    name: '',
    email: '',
    country: '',
    body: ''
  };

  recaptcha: any | null = null;

  render() {
    const { t, error } = this.props;
    return (
      <Form>
        <Label htmlFor="name">{t('氏名')}</Label>
        <Input name="name"
               id="name"
               type="text"
               error={error}
               value={this.state.name}
               onChange={this.onChangeField}/>
        <Label htmlFor="email">{t('メールアドレス')}</Label>
        <Input name="email"
               id="email"
               type="text"
               error={error}
               value={this.state.email}
               onChange={this.onChangeField}/>
        <Label htmlFor="country">{t('国名')}</Label>
        <Input name="country"
               id="country"
               type="text"
               error={error}
               value={this.state.country}
               onChange={this.onChangeField}/>
        <Label htmlFor="body">{t('本文')}</Label>
        <TextArea name="body"
                  id="body"
                  rows={5}
                  error={error}
                  value={this.state.body}
                  onChange={this.onChangeField}/>
        <Button type="button" onClick={this.onSubmit}>{t('送信')}</Button>

        <Recaptcha
          ref={ref => this.recaptcha = ref}
          locale={locale()}
          sitekey={recaptcha_site_key}
          onError={() => this.recaptcha.reset()}
          onResolved={this.onResolved}/>
      </Form>
    );
  }

  onChangeField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = () => {
    if (this.recaptcha) {
      this.recaptcha.execute();
    }
  };

  onResolved = () => {
    const { name, email, country, body } = this.state;
    const recaptcha = this.recaptcha.getResponse();
    this.props.onSubmit({ name, email, country, body, recaptcha });
    this.recaptcha.reset();
  };
}

export default withI18n()(ContactForm);