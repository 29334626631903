import React from 'react';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import i18nProps from '../../helpers/i18n';

const ErrorText = styled.p`
    text-align: center;
    color: red;
`;

interface Props extends i18nProps {
  error: string | null;
}

const ErrorMessage: React.FC<Props> = ({ error, t }) => {
  return (() => {
    switch (error) {
      case null:
        return null;
      default:
        return <ErrorText>{t('サインアップに失敗しました。')}</ErrorText>;
    }
  })();
};

export default withI18n()(ErrorMessage);