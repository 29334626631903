import React, { useEffect } from 'react';
import i18nProps from '../helpers/i18n';
import { withI18n } from 'react-i18next';
import HomemadeProps from 'learning-redux-store/dist/props/homemade';
import {
  AppState,
  CategoryState,
  HomemadeState,
  requestBulkPrice,
  requestHomemadeCategories
} from 'learning-redux-store';
import { connect } from 'react-redux';
import CategoryProps from 'learning-redux-store/dist/props/category';
import { RecipeBanner, RecipeScreen } from '../components/RecipeMovieTop/Elements';
import { Helmet } from 'react-helmet-async';
import { Sticky, StickyContainer } from 'react-sticky';
import Header from '../components/Header';
import FreeRecipe from '../components/RecipeMovieTop/FreeRecipe';
import Footer from '../components/Home/Footer';
import ProduceBox from '../components/RecipeMovieTop/ProduceBox';
import RecipeMemberBox from '../components/RecipeMovieTop/RecipeMemberBox';
import RecipeList from '../components/RecipeMovieTop/RecipeList';
import SetPriceBox from '../components/RecipeMovieTop/SetPriceBox';

interface Props extends i18nProps, HomemadeProps, CategoryProps {
  homemade: HomemadeState;
  category: CategoryState;
}

const RecipeMovieTop: React.FC<Props> = (
  { t, homemade, category, requestHomemadeCategories, requestBulkPrice }
) => {

  useEffect(() => {
    requestHomemadeCategories();
    requestBulkPrice();
  }, []);

  return (
    <RecipeScreen id='top'>
      <Helmet>
        <title>RECIPE MOVIE | Japanese Cuisine.com</title>
        <meta name='description'
              content={t('正当な“日本料理”の考え方と調理技能をコンテンツとして凝縮。数百年の伝統技能を、ネットで正しく学べます。')}/>
      </Helmet>
      <StickyContainer>
        <Sticky>
          {({ style }) => <Header containerStyle={style}
                                  title="MANABI"
                                  subTitle={t('日本料理を学ぶ')}/>}
        </Sticky>
        <RecipeBanner src={require(`../images/recipes/${t('recipe_banner_png')}`)}/>
        <FreeRecipe category={homemade.data[0]}/>
        <ProduceBox bulk_price={category.bulkPrice}/>
        <RecipeMemberBox/>
        <RecipeList categories={homemade.data}/>
        <SetPriceBox bulk_price={category.bulkPrice}/>
        <Footer/>
      </StickyContainer>
    </RecipeScreen>
  );
};

export default connect((state: AppState) => ({
  homemade: state.homemade,
  category: state.category
}), {
  requestHomemadeCategories, requestBulkPrice
})(withI18n()(RecipeMovieTop));
