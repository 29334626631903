import React from 'react';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import { Category } from 'learning-redux-store';

const CategoryInfoSection = styled.section`
    text-align: center;
    padding: 48px 8px;
`;

const CategoryTitle = styled.h2`
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.1em;
    
    &::after {
      content: '';
      display: block;
      width: 12em;
      max-width: 100%;
      border-bottom: 3px solid crimson;
      margin: 16px auto 0;
    }
`;

const CategoryDescription = styled.h1`
    font-size: 32px;
    margin: 16px 0;
    letter-spacing: 0.1em;
`;

const CategoryCount = styled.p`
    display: inline-block;
    margin: 0 auto 16px;
    padding: 2px 24px;
    font-weight: bold;
    border: 1px solid #000000;
`;

interface Props {
  category: Category
}

const CategoryInfo: React.FC<Props> = ({ category }) => {
  return (
    <CategoryInfoSection>
      <CategoryTitle>{category.curriculum_no}</CategoryTitle>
      <CategoryDescription>{category.title}</CategoryDescription>
      <CategoryCount>{category.skill_count} skill / {category.lecture_count} lecture</CategoryCount>
    </CategoryInfoSection>
  );
};

export default withI18n()(CategoryInfo);
