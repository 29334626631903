import styled from '../../helpers/styled';

export const NotFoundScreen = styled.div`
    padding-top: 32px;
    text-align: center;
`;

export const Links = styled.p`
    margin-top: 24px;
`;
