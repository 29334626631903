import * as React from 'react';
import styled, { css } from '../../helpers/styled';
import { combineTabletStyle } from '../../helpers/mediaQuery';

const HeaderPc = styled.h2`
  border: 1px solid #212121;
  padding: 16px 8px;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
`;

const HeaderMobile = css`
  font-size: 24px;
`;

const Header = combineTabletStyle(HeaderPc, HeaderMobile);

const BronzeSectionHeader: React.FC = ({ children }) => {
  return <Header>{children}</Header>;
};

export default BronzeSectionHeader;