import React from 'react';
import { withI18n } from 'react-i18next';
import FontAwesome from 'react-fontawesome';
import SectionHeader from './SectionHeader';
import styled, { css } from '../../helpers/styled';
import nl2br from '../../helpers/nl2br';
import background from '../../images/top/about_background.png';
import VideoPlayer from '../VideoPlayer';
import {
  combileNoteStyle,
  combineMobileStyle,
  combineTabletMobileStyle,
  combineTabletStyle
} from '../../helpers/mediaQuery';
import certification1Image from '../../images/top/certification_1.jpg';
import certification2Image from '../../images/top/certification_2.png';
import { HashLink } from '../../helpers/react-router-hash-link';
import i18nProps from '../../helpers/i18n';
import { asset_endpoint } from '../../config/app';
import locale from '../../helpers/locale';

const AboutUsSection = styled.section`
    background-image: url(${background});
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    padding-bottom: 64px;
    padding-top: 32px;
`;

const Description = styled.p<{locale: string}>`
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    text-align: center;
    font-size: 40px;
    letter-spacing: ${({locale}) => locale === 'ja' ? '0.5em' : '0.2em'};
    font-weight: 200;
    margin-top: 24px;
    margin-bottom: 24px;
`;

const DescriptionTablet = css<{locale: string}>`
    font-size: 32px;
    letter-spacing: ${({locale}) => locale === 'ja' ? '0.3em' : '0.2em'};
`;

const DescriptionMobile = css<{locale: string}>`
    font-size: 24px;
    letter-spacing: ${({locale}) => locale === 'ja' ? '0.2em' : '0.1em'};
`;

const DescriptionCombined = combineTabletMobileStyle(Description, DescriptionTablet, DescriptionMobile);

const MoreInformation = styled.p`
    text-align: center;
    font-size: 16px;
    font-weight: 200;
`;

const CertificationBigLink = styled(HashLink)`
    display: block;
    color: #000000;
    &:hover, &:active, &:focus {
      color: #000000;
    }
`;

const CertificationBigImage = styled.img`
    width: 768px;
    margin: 48px auto 8px;
    display: block;
`;

const CertificationBigImageMobile = css`
    width: auto;
    max-width: 100%;
    margin: 48px auto 8px;
`;

const CertificationBigImageCombined = combineTabletStyle(CertificationBigImage, CertificationBigImageMobile);

const CertificationBigInformation = styled.p`
    width: 768px;
    margin: 0 auto 24px;
`;

const CertificationBigInformationMobile = css`
    width: auto;
    margin: 0 8px 24px;
`;

const CertificationBigInformationCombined = combineTabletStyle(CertificationBigInformation, CertificationBigInformationMobile);

const CertificationBox = styled.div`
    background-color: rgba(255, 255, 255, 0.8);
    padding: 24px 32px 24px 32px;
    width: 1024px;
    margin: 64px auto 0;
`;

const CertificationMobile = css`
    width: auto;
    margin: 64px 8px 0;
`;

const CertificationCombined = combileNoteStyle(CertificationBox, CertificationMobile);

const VideoBox = styled.div`
    width: 100%;
    margin: 0 auto 8px;
    background-color: #000000;
`;

const BoxHeader = styled.h3`
    margin: -49px 48px 0 48px;
    min-height: 50px;
    background-color: #000000;
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 8px;
`;

const BoxHeaderMobile = css`
    margin: -49px 16px 0 16px;
`;

const BoxHeaderCombined = combineMobileStyle(BoxHeader, BoxHeaderMobile);

const CertificationFlex = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const CertificationArticle = styled.article`
    width: 50%;
    margin-top: 24px;
    :first-of-type {
      padding-right: 48px;
    }
    :last-of-type {
      padding-left: 48px;
    }
`;

const CertificationArticleMobile = css`
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
`;

const CertificationArticleCombined = combineMobileStyle(CertificationArticle, CertificationArticleMobile);

const ArticleImage = styled.img`
    width: 100%;
    margin-bottom: 8px;
    transition: opacity 0.2s;
`;

const ArticleDescription = styled.p`
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
`;

const ArticleLinks = styled.p`
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    min-height: 70px;
`;

const ArticleLink = styled.a`
    color: #212121;
    text-decoration: none;
    :hover {
      color: #212121;
    }
`;

const ArticleBoxLink = styled(HashLink)`
    display: block;
    color: #000000;
    margin-bottom: 16px;
    cursor: pointer;
    
    :hover {
      color: #000000;
      text-decoration: none;
    }
    
    &:hover ${ArticleImage}, &:active ${ArticleImage}, &:focus ${ArticleImage} {
      opacity: 0.6;
    }
`;

const ArticleMore = styled.p`
    color: #000000;
    font-weight: 700;
    letter-spacing: 0.3em;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    :hover {
      color: #000000;
      text-decoration: none;
      cursor: pointer;
    }
`;

const MoreArrow = styled(FontAwesome)`
    font-size: 20px !important;
    margin-left: 4px;
`;

const Anchor = styled.div`
    margin-top: -82px;
    padding-top: 82px;
`;

const BigInformation = styled.p`
    font-size: 24px;
`;

const Recommended = styled.p`

`;

const RecommendedTitle = styled.span`
    font-weight: 700;
`;

const AboutUs: React.FC<i18nProps> = ({ t }) => {
  return (
    <AboutUsSection>
      <Anchor id='about'/>
      <SectionHeader>ABOUT US</SectionHeader>
      <DescriptionCombined locale={locale()}>
        {nl2br(t('世界に向けて“日本料理”の\n神髄を感じていただきたい。'))}
      </DescriptionCombined>
      <MoreInformation>
        {nl2br(t(
          '自然との素材の共有を、神への感謝として料理に昇華している“日本料理”の世界。私たちは、\n' +
          'その文化的背景から調理法の構造、多様な調理道具まで、すべてをこのサイトで公開します。\n' +
          '日本料理の奥深さを、体験してください。'
        ))}
      </MoreInformation>
        <CertificationCombined>
          <BoxHeaderCombined>{t('日本料理の巨匠が指導・監修する調理技能認定制度')}</BoxHeaderCombined>
          <CertificationBigLink to="/manabi/instructor#top">
            <CertificationBigImageCombined src={certification1Image}/>
            <CertificationBigInformationCombined>
              {t('当サイトでは、「日本料理アカデミー」の協賛を受け、日本でも最高峰の調理解説と講義を担当。オンラインで心構えからこまかな技能までを動画で習得可能です。日本料理を調理するに必要な重要で基本的な技能を正しくべます。')}
            </CertificationBigInformationCombined>
          </CertificationBigLink>
          <CertificationFlex>
            <CertificationArticleCombined>
              <VideoBox>
                <VideoPlayer
                  url={`${asset_endpoint}/movies/about_us/master.m3u8`}
                  thumbUrl={certification1Image}
                  autoPlay={false}/>
              </VideoBox>
              <ArticleBoxLink to="/manabi#top">
                <BigInformation>
                  <strong>{t('日本料理を学ぶ')}</strong><br/>
                  {t('e-ラーニングシステム')}
                </BigInformation>
                <ArticleMore>more<MoreArrow name='angle-right'/></ArticleMore>
              </ArticleBoxLink>
              <Recommended>
                <RecommendedTitle>{t('Recommended environment')}</RecommendedTitle><br/>
                {t('Windows10 Edge, Chrome latest edition')}<br/>
                {t('Mac,iOS Safari, Chrome latest edition')}
              </Recommended>
            </CertificationArticleCombined>
            <CertificationArticleCombined>
              <ArticleBoxLink to="/manabi#top">
                <ArticleImage src={certification2Image}/>
                <BigInformation>
                  <strong>{t('調理技能認定制度')}</strong>
                </BigInformation>
                <ArticleDescription>
                  {nl2br(t('当サイトの認定制度は日本の農水省によって認証されている正式な技能資格。ゴールド・シルバー・ブロンズのうち「ブロンズ」の取得が可能です。試験官による遠隔判定など厳格な認定試験を通じて、日本の料亭などでも通用する実力を身につけられます。'))}
                </ArticleDescription>
                <ArticleMore>more<MoreArrow name='angle-right'/></ArticleMore>
              </ArticleBoxLink>
              <ArticleLinks>
                <ArticleLink href='http://www.maff.go.jp/j/shokusan/syokubun/tyori.html'
                             target='_blank'
                             rel="noopener noreferrer">
                  {t('海外における日本料理の調理技能認定制度')} >
                </ArticleLink>
              </ArticleLinks>
            </CertificationArticleCombined>
          </CertificationFlex>
        </CertificationCombined>
    </AboutUsSection>
  );
};

export default withI18n()(AboutUs);