import React from 'react';
import styled, { css } from '../../helpers/styled';
import FontAwesome from 'react-fontawesome';
import { withI18n } from 'react-i18next';
import { combineTabletStyle } from '../../helpers/mediaQuery';
import { HashLink } from '../../helpers/react-router-hash-link';
import i18nProps from '../../helpers/i18n';
import nl2br from '../../helpers/nl2br';

const MemberBox = styled.div`
    display: flex;
    width: 848px;
    max-width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
`;

const MemberButton = styled(HashLink)`
    display: flex;
    flex: 1;
    min-width: 400px;
    height: 80px;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-decoration: none;
    transition: all 0.2s;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.2em;
    line-height: 1em;
    margin: 16px 12px;
    :hover {
      background-color: #FFFFFF;
      color: #212121;
      text-decoration: none;
    }
`;

const MemberButtonMobile = css`
    width: 100%;
    min-width: 300px;
    margin: 16px 8px !important;
`;

const MemberButtonCombined = combineTabletStyle(MemberButton, MemberButtonMobile);

const MemberButtonSubText = styled.span`
    font-weight: 200;
    font-size: 12px;
    letter-spacing: 0;
    margin-left: 16px;
`;

const MemberButtonArrow = styled(FontAwesome)`
    font-size: 14px !important;
    margin-left: 4px;
`;

const RecipeMemberBox: React.FC<i18nProps> = ({ t }) => {
  return (
    <MemberBox>
      <MemberButtonCombined to='/auth/login#top'>
        {t('LOGIN')}
        <MemberButtonSubText>
          <MemberButtonArrow name='angle-right'/>
        </MemberButtonSubText>
      </MemberButtonCombined>
      <MemberButtonCombined to='/user#top'>
        {nl2br(t('MEMBERSHIP\nINFORMATION'))}
        <MemberButtonSubText>
          <MemberButtonArrow name='angle-right'/>
        </MemberButtonSubText>
      </MemberButtonCombined>
    </MemberBox>
  );
};

export default withI18n()(RecipeMemberBox);
