import React from 'react';
import NotificationSystem from 'react-notification-system';
import { connect } from 'react-redux';
import { AppState, NotificationState } from 'learning-redux-store';

interface Props {
  notification: NotificationState
}

class Notification extends React.Component<Props> {

  notificationSystem = React.createRef<NotificationSystem.System>();

  componentWillReceiveProps(nextProps: Readonly<Props>): void {
    const notification = this.notificationSystem.current;
    if (notification) {
      const level = nextProps.notification.level as ('error' | 'warning' | 'info' | 'success');
      notification.addNotification({
        message: nextProps.notification.message || "",
        level: level,
        position: 'tc'
      });
    }
  }

  render() {
    return <NotificationSystem ref={this.notificationSystem}/>;
  }
}

export default connect(
  (state: AppState) => ({
    notification: state.notification
  })
)(Notification);