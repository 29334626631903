import React from 'react';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import paperImage from '../../images/paper_background.png';
import bannerImage from '../../images/lesson/bronze_curriculum.png';
import UserWelcome from '../UserWelcome';
import { Category, Movie } from 'learning-redux-store';
import PaymentInfo from '../PaymentInfo';
import MoviePaymentInfo from './MoviePaymentInfo';
import { isLogin } from '../../helpers/isLogin';

const BannerSection = styled.section`
    background-image: url(${paperImage});
    background-size: cover;
    padding: 24px 8px;
    position: relative;
`;

const Image = styled.img`
    display: block;
    width: 650px;
    max-width: 100%;
    margin: 0 auto 16px;
`;

interface Props {
  category: Category;
  category_movies: Movie[];
}

const Banner: React.FC<Props> = ({ category, category_movies }) => {
  return (
    <BannerSection>
      <Image src={bannerImage}/>
      {(!category.is_licensed && category.type !== 1) && (
        <PaymentInfo category={category} isLogin={isLogin()}/>
      )}
      {(!category.is_licensed && category.type !== 1) && (
        <MoviePaymentInfo movies={category_movies} isLogin={isLogin()}/>
      )}
      <UserWelcome/>
    </BannerSection>
  );
};

export default withI18n()(Banner);