import * as React from 'react';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import styled, { css } from '../../helpers/styled';
import BronzeSectionHeader from './BronzeSectionHeader';
import StepBox from './StepBox';
import arrowImage from '../../images/bronze/arrow.png';
import arrowDownImage from '../../images/bronze/arrow_down.png';
import { combineTabletStyle } from '../../helpers/mediaQuery';

const Section = styled.section`
  padding: 16px;
`;

const StepListBoxPc = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StepListBoxMobile = css`
  flex-direction: column;
`;

const StepListBox = combineTabletStyle(StepListBoxPc, StepListBoxMobile);

const StepItemPc = styled.div`
  
`;

const StepItemMobile = css`
  width: 100%;
`;

const StepItem = combineTabletStyle(StepItemPc, StepItemMobile);

const StepHeader = styled.p`
  text-align: center;
  font-size: 20px;
  color: #414141;
`;

const HeaderBig = styled.span`
  font-size: 24px;
  color: #212121;
`;

const Arrow = styled.p`
  padding: 8px 16px;
`;

const ArrowImagePc = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const ArrowImageMobile = css`
  display: none;
`;

const ArrowImage = combineTabletStyle(ArrowImagePc, ArrowImageMobile);

const ArrowDownImagePc = styled.img`
  max-height: 100%;
  max-width: 100%;
  display: none;
`;

const ArrowDownImageMobile = css`
  display: inline;
`;

const ArrowDownImage = combineTabletStyle(ArrowDownImagePc, ArrowDownImageMobile);

interface Props extends i18nProps {
  onLogin: () => void;
  onAgreement: () => void;
  onEntry: () => void;
  isAuth: boolean;
  isAgreement: boolean;
}

const StepList: React.FC<Props> = ({ t, onLogin, onAgreement, onEntry, isAuth, isAgreement }) => {
  return (
    <Section id='steps'>
      <BronzeSectionHeader>{t('受験への3ステップ')}</BronzeSectionHeader>
      <StepListBox>
        <StepItem>
          <StepHeader>{t('step')}<HeaderBig>1</HeaderBig></StepHeader>
          <StepBox title={t('オンライン講座をすべて受講')}
                   action={t('ログインして受講を確認')}
                   isEnable={true}
                   onClick={onLogin}/>
        </StepItem>
        <Arrow>
          <ArrowImage src={arrowImage}/>
          <ArrowDownImage src={arrowDownImage}/>
        </Arrow>
        <StepItem>
          <StepHeader>{t('step')}<HeaderBig>2</HeaderBig></StepHeader>
          <StepBox title={t('受験規約に同意する')}
                   action={t('受験規約を表示')}
                   isEnable={isAuth}
                   onClick={onAgreement}/>
        </StepItem>
        <Arrow>
          <ArrowImage src={arrowImage}/>
          <ArrowDownImage src={arrowDownImage}/>
        </Arrow>
        <StepItem>
          <StepHeader>{t('step')}<HeaderBig>3</HeaderBig></StepHeader>
          <StepBox title={t('いますぐエントリー2')}
                   action={t('ENTRY')}
                   isEnable={isAuth && isAgreement}
                   onClick={onEntry}/>
        </StepItem>
      </StepListBox>
    </Section>
  );
};

export default withI18n()(StepList);