import * as React from 'react';
import styled from '../helpers/styled';

const PlayerVolumeWrap = styled.div`
    display: -webkit-flex;
    display: flex;
    align-items: center;
    height: 12px;
    width: 70px;
    margin-left: 8px;
`;

const PlayerVolume = styled.input`
    -webkit-appearance: none;
    appearance: none;
    background-color: #FFFFFF;
    height: 2px;
    width: 70px;
    padding: 0;

    &:focus,&:active {
        outline: none;
    }

    &::-webkit-slider-thumb, &::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        position: relative;
        border: none;
        width: 12px;
        height: 12px;
        display: block;
        background-color: #FFFFFF;
        border-radius: 50%;
        -webkit-border-radius: 50%;
    }
    
    &::-ms-thumb {
        appearance: none;
        cursor: pointer;
        position: relative;
        border: none;
        width: 12px;
        height: 12px;
        display: block;
        background-color: #FFFFFF;
        border-radius: 50%;
    }
    
    &::-ms-tooltip {
        display: none;
    }
    
    &::-ms-fill-lower {
        background-color: #FFFFFF;
    }
    
    &::-ms-fill-upper {
        background-color: #C5C5C5;
    }
`;

interface Props {
  volume: number;
  onSetVolume: (e) => void;
}

const VolumeBar: React.FC<Props> = ({ volume, onSetVolume }) => {
  const background = `-webkit-gradient(linear, left top, right top, color-stop(${volume}, #FFFFFF), color-stop(${volume}, #C5C5C5))`;

  return (
    <PlayerVolumeWrap>
      <PlayerVolume
        type='range' min={0} max={1} step='any'
        value={volume}
        style={{ background: background }}
        onChange={onSetVolume}
      />
    </PlayerVolumeWrap>
  );
};

export default VolumeBar;