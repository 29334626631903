import React from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import Header from '../components/Header';
import banner from '../images/instructor/instructor_banner.png';
import InstructorList from '../components/Instructor/InstructorList';
import Footer from '../components/Instructor/Footer';
import { connect } from 'react-redux';
import { AppState, InstructorState, requestInstructors, InstructorProps } from 'learning-redux-store';
import { Helmet } from 'react-helmet-async';
import { BannerImage } from '../components/Instructor/Elements';
import i18nProps from '../helpers/i18n';
import { withI18n } from 'react-i18next';

interface Props extends InstructorProps, i18nProps {
  instructor: InstructorState
}

class Instructor extends React.Component<Props> {

  componentDidMount() {
    this.props.requestInstructors();
  }

  render() {
    const { instructor, t } = this.props;

    return (
      <div id="top">
        <Helmet>
          <title>{t('MANABIの講師陣')} | Japanese Cuisine.com</title>
        </Helmet>
        <StickyContainer>
          <Sticky>
            {({ style }) => <Header containerStyle={style}
                                    subTitle={t('MANABIの講師陣')}/>}
          </Sticky>
          <BannerImage src={require(`../images/instructor/${t('instructor_banner_png')}`)}/>
          <InstructorList instructor={instructor}/>
          <Footer/>
        </StickyContainer>
      </div>
    );
  };
}

export default connect(
  (state: AppState) => ({
    instructor: state.instructor
  }),
  { requestInstructors }
)(withI18n()(Instructor));