import styled from "../helpers/styled";

const CloseButton = styled.span`
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
  
  &::before, &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #FFFFFF;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

export default CloseButton;