import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Links, NotFoundScreen } from '../components/NotFound/Elements';

const NotFound: React.FC = () => {
    return (
        <NotFoundScreen>
            <Helmet>
                <title>Page not found | Japanese Cuisine.com</title>
            </Helmet>
            <h1>Page not found</h1>
            <p>
                Sorry! The Page You Requested Could Not Found.
            </p>
            <Links>
                <Link to='/'>Back to Home</Link>
            </Links>
        </NotFoundScreen>
    )
};

export default NotFound;