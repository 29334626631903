import React from 'react';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';

const ErrorText = styled.p`
    text-align: center;
    color: red;
`;

interface Props {
  error: string | null;
}

const ErrorMessage: React.FC<Props> = ({ error }) => {
  return (() => {
    switch (error) {
      case null:
        return null;
      case 'already_verified':
        return <ErrorText>既にメールアドレスの認証が完了しています。</ErrorText>;
      case 'signature_expired':
        return <ErrorText>認証の有効期限が切れています。恐れ入りますが再度登録を行ってください。</ErrorText>;
      case 'invalid_signature':
        return <ErrorText>認証キーに誤りがあります。</ErrorText>;
      default:
        return <ErrorText>認証エラーが発生しました。</ErrorText>;
    }
  })();
};

export default withI18n()(ErrorMessage);