import * as React from 'react';
import styled from '../../helpers/styled';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';

const RecommendedText = styled.p`
    width: 848px;
    max-width: 100%;
    padding: 16px;
    margin: 0 auto;
`;

const RecommendedTitle = styled.span`
    font-weight: 700;
    margin-right: 8px;
`;

const Recommended: React.FC<i18nProps> = ({t}) => {
  return (
    <RecommendedText>
      <RecommendedTitle>[{t('Recommended environment')}]</RecommendedTitle>
      {t('Windows10 Edge, Chrome latest edition')} / {t('Mac,iOS Safari, Chrome latest edition')}
    </RecommendedText>
  )
};

export default withI18n()(Recommended);