import React from 'react';
import { connect } from 'react-redux';
import {
  AppState, LicenseState,
  requestLicenseHistory, requestOnlineTestLog,
  requestSignOut,
  requestUser,
  SignOutProps,
  UserProps,
  UserState,
  OnlineTestLogProps, OnlineTestLogState
} from 'learning-redux-store';
import { withI18n } from 'react-i18next';
import Header from '../components/Header';
import UserTable from '../components/UserInformation/UserTable';
import loginRequired from '../middleware/loginRequired';
import {
  InformationLeft, InformationRight,
  InformationTitle,
  LogoutButton,
  UserInformationBox
} from '../components/UserInformation/Elements';
import i18nProps from '../helpers/i18n';
import Footer from '../components/Instructor/Footer';
import LicenseTable from '../components/UserInformation/LicenseTable';
import LicenseProps from 'learning-redux-store/dist/props/license';
import OnlineTestLogTable from '../components/UserInformation/OnlineTestLogTable';

interface Props extends UserProps, SignOutProps, i18nProps, LicenseProps, OnlineTestLogProps {
  user: UserState;
  license: LicenseState;
  online_test_log: OnlineTestLogState;
}

class UserInformation extends React.Component<Props> {

  componentDidMount() {
    this.props.requestUser();
    this.props.requestLicenseHistory();
    this.props.requestOnlineTestLog();
  }

  render() {
    const { user, t, license, online_test_log } = this.props;
    if (!user || !user.data) {
      return <UserInformationBox/>;
    }
    return (
      <div>
        <Header/>
        <InformationTitle>{t('メンバー情報')}</InformationTitle>
        <UserInformationBox>
          <InformationLeft>
            {t('メンバーシップ')}<br/>
            <LogoutButton onClick={this.onLogout}>{t('ログアウト')}</LogoutButton>
          </InformationLeft>
          <InformationRight>
            <UserTable user={user.data}/>
          </InformationRight>
        </UserInformationBox>
        <UserInformationBox>
          <InformationLeft>
            {t('購入したレッスン')}
          </InformationLeft>
          <InformationRight>
            <LicenseTable licenses={license.data}/>
          </InformationRight>
        </UserInformationBox>
        <UserInformationBox>
          <InformationLeft>
            {t('受講したレッスン')}
          </InformationLeft>
          <InformationRight>
            <OnlineTestLogTable logs={online_test_log.data}/>
          </InformationRight>
        </UserInformationBox>
        <Footer/>
      </div>
    );
  }

  onLogout = () => {
    this.props.requestSignOut();
  };
}

export default connect(
  (state: AppState) => ({
    user: state.user,
    license: state.license,
    online_test_log: state.online_test_log
  }),
  { requestUser, requestSignOut, requestLicenseHistory, requestOnlineTestLog }
)(withI18n()(loginRequired(UserInformation)));