import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import queryString from 'query-string';
import {
  AppState,
  clearState,
  NotificationProps,
  requestSignIn,
  sendNotification,
  SignInProps,
  SignInState
} from 'learning-redux-store';
import Header from '../components/Header';
import LoginForm from '../components/SignIn/LoginForm';
import { Flex, SignInScreen } from '../components/SignIn/Elements';
import { RouteComponentProps } from 'react-router';
import RegisterInformation from '../components/SignUp/RegisterInformation';
import i18nProps from '../helpers/i18n';

interface Props extends SignInProps, NotificationProps, RouteComponentProps, i18nProps {
  signIn: SignInState
}

class SignIn extends React.Component<Props> {

  componentDidMount() {
    this.props.clearState();
  }

  render() {
    const { signIn } = this.props;

    return (
      <SignInScreen>
        <Helmet>
          <title>SignIn | Japanese Cuisine.com</title>
        </Helmet>

        <Header/>
        <Flex>
          <RegisterInformation/>
          <LoginForm onSubmit={this.onSubmit}
                     isProcessing={signIn.isProcessing}
                     error={signIn.error}/>
        </Flex>
      </SignInScreen>
    );
  }


  onSubmit = ({ email, password, recaptcha }) => {
    this.props.requestSignIn({
      request: {
        email, password, recaptcha
      },
      callback: this.onLoginSuccess
    });
  };

  onLoginSuccess = () => {
    const { t } = this.props;
    this.props.sendNotification({ message: t('ログインしました。') });
    const params = queryString.parse(this.props.location.search);
    if (params.prev) {
      if (params.additional) {
        this.props.history.push(params.prev as string + params.additional as string);
      } else {
        this.props.history.push(params.prev as string);
      }
    } else {
      window.location.href = '/manabi#curriculum';
    }
  };
}

export default connect(
  (state: AppState) => ({
    signIn: state.signIn
  }),
  { requestSignIn, clearState, sendNotification }
)(withI18n()(SignIn));