import * as React  from 'react';
import styled from '../helpers/styled';
import DatePicker, {DatePickerProps} from 'react-date-picker';

const ErrorText = styled.span`
    margin-bottom: 8px; 
    color: red;
    display: inline-block;
`;

interface Props extends DatePickerProps {
  error: { errors: object | null } | null;
  format: string;
}

const ErrorDatePicker: React.FC<Props> = (props) => {

  if (props.error) {
    const { errors } = props.error;

    if (errors && props.name && errors.hasOwnProperty(props.name) && errors[props.name]) {
      let errorOutput: JSX.Element[] = [];
      errors[props.name].forEach((error, index) => {
        errorOutput.push(<span key={`${props.name}_errors_${index}`}>{error}<br/></span>);
      });
      return (
        <div>
          <DatePicker {...props}/>
          <ErrorText>{errorOutput}</ErrorText>
        </div>
      );
    }
  }
  return <DatePicker {...props}/>;
};

export default ErrorDatePicker;