import React from 'react';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import i18next from 'i18next';
import i18nProps from '../../helpers/i18n';
import nl2br from '../../helpers/nl2br';

const ErrorText = styled.p`
    text-align: center;
    color: red;
`;

interface Props extends i18nProps {
  error: string | null;
}

const ErrorMessage: React.FC<Props> = ({ error, t }) => {
  return (() => {
    switch (error) {
      case null:
        return null;
      case 'failed':
        return <ErrorText>{nl2br(t('ログインに失敗しました。メールアドレスかパスワードに誤りがあります。'))}</ErrorText>;
      case 'require_verify':
        return <ErrorText>{nl2br(t('メールアドレスの認証が完了しておりません。'))}</ErrorText>;
      case 'invalid_recaptcha':
        return <ErrorText>{nl2br(t('ReCaptchaに失敗しました。'))}</ErrorText>;
      default:
        return <ErrorText>{nl2br(t('ログインに失敗しました。'))}</ErrorText>;
    }
  })();
};

export default withI18n()(ErrorMessage);