import React, { useEffect } from 'react';
import i18nProps from '../helpers/i18n';
import { Sticky, StickyContainer } from 'react-sticky';
import { RouteComponentProps } from 'react-router';
import {
  AppState,
  CategoryMoviesState,
  CategoryState,
  requestCategoryDetail,
  requestCategoryMovies
} from 'learning-redux-store';
import CategoryProps from 'learning-redux-store/dist/props/category';
import CategoryMoviesProps from 'learning-redux-store/dist/props/category_movies';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import { LoadingFlex } from '../components/Lesson/Elements';
import ActivityIndicator from '../components/ActivityIndicator';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import EmptyCategoryView from '../components/RecipeView/EmptyCategoryView';
import Footer from '../components/RecipeView/Footer';
import ShowRecipeMovie from '../components/RecipeView/ShowRecipeMovie';

interface Props extends i18nProps, CategoryProps, CategoryMoviesProps, RouteComponentProps<{ category_id }> {
  category: CategoryState;
  category_movies: CategoryMoviesState;
}

const RecipeView: React.FC<Props> = (
  { t, category, category_movies, requestCategoryDetail, requestCategoryMovies, match: { params: { category_id } } }
) => {

  useEffect(() => {
    requestCategoryDetail(category_id);
    requestCategoryMovies(category_id);
  }, [category_id]);

  // ローディング
  if (category.isProcessing || !category.selectItem || category_movies.isProcessing) {
    return <LoadingFlex><ActivityIndicator color='#212121' size='4x'/></LoadingFlex>;
  }

  if (!category_movies.data[category_id] || category_movies.data[category_id].length === 0) {
    return (
      <div id="top">
        <Helmet title={(() => {
          return `${category.selectItem.title} | Japanese Cuisine.com`;
        })()}/>
        <StickyContainer>
          <Sticky>
            {({ style }) => <Header containerStyle={style}
                                    title="MANABI"
                                    subTitle={t('日本料理を学ぶ')}/>}
          </Sticky>
          <EmptyCategoryView/>
          <Footer/>
        </StickyContainer>
      </div>
    );
  }

  return (
    <div id="top">
      <Helmet title={(() => {
        return `${category.selectItem.title} | Japanese Cuisine.com`;
      })()}/>
      <StickyContainer>
        <Sticky>
          {({ style }) => <Header containerStyle={style}
                                  title="MANABI"
                                  subTitle={t('日本料理を学ぶ')}/>}
        </Sticky>
        <ShowRecipeMovie
          isShown={true}
          movie={category_movies.data[category_id][0]}
          category={category.selectItem}/>
        <Footer/>
      </StickyContainer>
    </div>
  );
};

export default connect((state: AppState) => ({
  category: state.category,
  category_movies: state.category_movies
}), {
  requestCategoryDetail, requestCategoryMovies
})(withI18n()(RecipeView));
