import * as React from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import i18next from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import locale from './helpers/locale';
import AppRouting from './AppRouting';
import './styles/app.scss';
import Notification from './components/Notification';
import TokenRefresh from './containers/TokenRefresh';
import store from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';
import { google_analytics_code } from './config/app';
import { BrowserRouter } from 'react-router-dom';

let persistor = persistStore(store);

i18next
  .use(reactI18nextModule)
  .init({
    lng: locale(),
    fallbackLng: 'en',
    fallbackNS: 'translation',
    resources: {
      en: {
        translation: require('./i18n/en.json')
      },
      ja: {
        translation: require('./i18n/ja.json')
      },
      zh: {
        translation: require('./i18n/zh.json')
      }
    }
  });

const Analytics = props => {
  if (google_analytics_code) {
    ReactGA.pageview(
      props.location.pathname + props.location.search + props.location.hash
    );
  }
  return null;
};

const Routes = AppRouting({ children: Analytics });

class App extends React.Component {

  componentDidMount(): void {
    if (google_analytics_code) {
      ReactGA.initialize(
        google_analytics_code,
        { debug: process.env.ENV === 'dev' }
      );
    }
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <Helmet htmlAttributes={{ lang: locale() }}/>
            <div className="App">
              <Notification/>
              <BrowserRouter>
                <Routes/>
              </BrowserRouter>
              <TokenRefresh/>
            </div>
          </HelmetProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
