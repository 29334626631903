import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { withI18n } from 'react-i18next';
import i18nProps from '../helpers/i18n';
import styled from '../helpers/styled';
import Banner from '../components/BronzeInformation/Banner';
import AboutBronze from '../components/BronzeInformation/AboutBronze';
import StepList from '../components/BronzeInformation/StepList';
import Footer from '../components/BronzeInformation/Footer';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppState, BronzeTestState, requestBronzeCheck, requestBronzeEntry } from 'learning-redux-store';
import BronzeTestProps from 'learning-redux-store/dist/props/bronze_test';
import ActivityIndicator from '../components/ActivityIndicator';
import { confirmAlert } from 'react-confirm-alert';
import EntryDialog from '../components/BronzeInformation/EntryDialog';
import BronzeAgreementText from '../components/BronzeInformation/BronzeAgreementText';
import BronzeSectionHeader from '../components/BronzeInformation/BronzeSectionHeader';

const Section = styled.article`

`;

function animate(elem, style, unit, from, to, time, prop) {
  if (!elem) {
    return;
  }
  const start = new Date().getTime(),
    timer = setInterval(function() {
      const step = Math.min(1, (new Date().getTime() - start) / time);
      if (prop) {
        elem[style] = (from + step * (to - from)) + unit;
      } else {
        elem.style[style] = (from + step * (to - from)) + unit;
      }
      if (step === 1) {
        clearInterval(timer);
      }
    }, 25);
  if (prop) {
    elem[style] = from + unit;
  } else {
    elem.style[style] = from + unit;
  }
}

interface Props extends i18nProps, RouteComponentProps, BronzeTestProps {
  bronze_test: BronzeTestState;
}

class BronzeInformation extends React.Component<Props> {

  componentDidMount(): void {
    this.props.requestBronzeCheck();
  }

  render() {
    const { t, bronze_test } = this.props;

    if (bronze_test.isProcessing) {
      return <ActivityIndicator/>;
    }

    return (
      <>
        <Helmet>
          <title>{t('ブロンズ認定オンラインテスト')} | Japanese Cuisine.com</title>
        </Helmet>
        <Section>
          <Banner onEntry={this.onEntryJump}/>
          <AboutBronze/>
          <BronzeSectionHeader>{t('受験規約')}</BronzeSectionHeader>
          <BronzeAgreementText isFront={true}/>
          <StepList
            isAuth={bronze_test.allowEntry}
            isAgreement={localStorage.getItem('bronze_agreement') != null}
            onEntry={this.onEntryButton}
            onAgreement={this.onAgreement}
            onLogin={this.onLogin}/>
          <Footer/>
        </Section>
      </>
    );
  }

  onEntryJump = () => {
    const target = document.getElementById('steps');
    if (target) {
      animate(
        document.scrollingElement || document.documentElement,
        'scrollTop',
        '',
        0,
        target.offsetTop,
        200,
        true
      );
    }
  };

  onEntryButton = () => {
    confirmAlert({
      customUI: ({ onClose }) => <EntryDialog onClose={onClose} onSubmit={this.onEntryExec}/>
    });
  };

  onEntryExec = () => {
    const { t } = this.props;
    this.props.requestBronzeEntry({
      callback: () => {
        alert(t('エントリーしました。'));
        this.props.history.push('/');
      },
      failureCallback: () => {
        alert(t('エントリーできませんでした。恐れ入りますが、しばらく経ってから再度やり直してください。'));
      }
    });
  };

  onAgreement = () => {
    this.props.history.push('/bronze/agreement');
  };

  onLogin = () => {
    this.props.history.push('/user');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };
}

export default connect(
  (state: AppState) => ({
    bronze_test: state.bronze_test
  }),
  { requestBronzeCheck, requestBronzeEntry }
)(withI18n()(BronzeInformation));