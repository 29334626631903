import * as React from 'react';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import { Category } from 'learning-redux-store';
import { HashLink } from '../../helpers/react-router-hash-link';
import { BulkPrice } from 'learning-redux-store/dist/data/bulk_price';
import CampaignPriceLabel from '../CampaignPriceLabel';

const Section = styled.div`
  width: 720px;
  max-width: 100%;
  position: relative;
  margin: 24px auto;
  background-color: #FFFFFF;
  padding: 16px 24px;
  text-align: center;
`;

const BannerText = styled.p`
  text-align: center;
  background-color: #000000;
  color: #FFFFFF;
`;

const PaymentBox = styled(HashLink)`
  margin-top: 16px;
  border: 1px solid #000000;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  padding: 8px;
  
  &:hover, &:active, &:focus {
    color: #000000;
    background-color: #FFFFFF;
    text-decoration: none;
  }
`;

const PriceBig = styled.span`
  font-size: 2em;
  white-space: nowrap;
  
  &:last-of-type {
    margin: 0 8px 0 0;
  }
  
  &:first-of-type {
    margin: 0 8px;
  }
`;

const DelText = styled.span`
  font-size: 80%;
  display: inline-block;
  position: relative;
  margin-right: 4px;
  
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    border-top: solid 2px #000;
    position: absolute;
    left: 0 ;  
    top: calc(50% - 2px);
  }
  
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    border-top: solid 2px #000;
    position: absolute;
    left: 0 ;  
    bottom: calc(50% - 6px);
  }
`;

interface Props extends i18nProps {
  categories: Category[],
  bulk_price: BulkPrice;
}

const SetPaymentInfo: React.FC<Props> = ({ t, categories, bulk_price }) => {
  const allLicensed = categories.map(function(category) {
    return category.is_licensed;
  }).reduce((is_licensed, total_licensed) => {
    if (!total_licensed) {
      return false;
    }
    return is_licensed;
  }, true);

  if (allLicensed || bulk_price.data === 0) {
    return null;
  }

  return (
    <Section>
      <p>
        {t('各有料コンテンツはレッスン単位で購入します。')}<br/>
        {t('購入後90日間レッスン内すべての動画を見ることができます。')}
      </p>
      <BannerText>{t('レッスンのセット購入がお得です！')}</BannerText>
      <p>{t('ブロンズカリキュラム')} {t('全レッスン')}</p>
      {(() => {
        if (bulk_price.campaign) {
          return (
            <>
              <PriceBig>
                {t('180日間')}
              </PriceBig>
              <PriceBig>
                {t('¥')}<DelText>{bulk_price.data.toLocaleString()}</DelText>
                 {bulk_price.campaign.toLocaleString()}
              </PriceBig>
            </>
          );
        } else {
          return (
            <PriceBig>
              <DelText>{t('180日間')}</DelText>{t('180日間')} {t('¥')}{bulk_price.data.toLocaleString()}
            </PriceBig>
          );
        }
      })()}
      {t('見放題')}
      <PaymentBox to={`/payment/all/0`}>
        {t('セット購入へ')}
      </PaymentBox>
    </Section>
  );
};

export default withI18n()(SetPaymentInfo);
