import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import News from '../components/Home/News';
import { Sticky, StickyContainer } from 'react-sticky';
import SlideShow from '../components/Home/SlideShow';
import AboutUs from '../components/Home/AboutUs';
import Contents from '../components/Home/Contents';
import Footer from '../components/Home/Footer';
import { withI18n } from 'react-i18next';
import i18nProps from '../helpers/i18n';
import LatestNews from '../components/Home/LatestNews';
import CampaignBanner from '../components/Home/CampaignBanner';
import PromotionVideo from '../components/Home/PromotionVideo';
import BronzeLinkBanner from '../components/BronzeLinkBanner';
import NewsProps from 'learning-redux-store/dist/props/news';
import { AppState, NewsState, requestNewsList, News as NewsItem } from 'learning-redux-store';
import { connect } from 'react-redux';
import NewsDetail from '../components/Home/NewsDetail';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

interface Props extends i18nProps, NewsProps {
  news: NewsState;
}

class Home extends React.Component<Props> {

  state = {
    selectNews: null
  };

  componentDidMount(): void {
    this.props.requestNewsList();
  }

  componentWillUnmount(): void {
    clearAllBodyScrollLocks();
  }

  render() {
    const { t, news } = this.props;

    return (
      <div id="top">
        <Helmet>
          <title>Japanese Cuisine.com</title>
          <meta
            name='description'
            content={t('当サイトでは、「日本料理アカデミー」の協賛を受け、日本でも最高峰の調理解説と講義を担当。オンラインで心構えからこまかな技能までを動画で習得可能です。日本料理を調理するに必要な重要で基本的な技能を正しくべます。')}/>
        </Helmet>
        <SlideShow/>
        <StickyContainer>
          <Sticky>
            {({ style }) => <Header containerStyle={style}/>}
          </Sticky>
          <LatestNews news={news.data} onClickNews={this.onNewsClick}/>
          <CampaignBanner/>
          <PromotionVideo/>
          <AboutUs/>
          <BronzeLinkBanner/>
          <Contents/>
          <News news={news.data} onNewsClick={this.onNewsClick}/>
          <Footer/>
        </StickyContainer>
        <NewsDetail
          news={this.state.selectNews}
          onClose={this.onCloseNewsDetail}/>
      </div>
    );
  }

  onNewsClick = (news: NewsItem) => {
    this.setState({ selectNews: news });
    setTimeout(() => {
      disableBodyScroll(document.getElementById('modal'));
    }, 100);
  };

  onCloseNewsDetail = () => {
    enableBodyScroll(document.getElementById('modal'));
    this.setState({ selectNews: null });
  };
}

export default connect(
  (state: AppState) => ({
    news: state.news
  }),
  { requestNewsList }
)(withI18n()(Home));