import React from 'react';
import styled from '../../helpers/styled';
import Recaptcha from 'react-google-invisible-recaptcha';
import { withI18n } from 'react-i18next';
import locale from '../../helpers/locale';
import { recaptcha_site_key } from '../../config/app';
import logo from '../../images/site_logo_black.png';
import AspectImage from '../AspectImage';
import { HashLink } from '../../helpers/react-router-hash-link';
import ErrorMessage from './ErrorMessage';
import ActivityIndicator from '../ActivityIndicator';
import i18nProps from '../../helpers/i18n';

const LoginFormSection = styled.form`
    width: 400px;
    max-width: 100%;
    margin: 24px auto;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 48px 16px;
    border-radius: 2px;
`;

const FormHeader = styled(AspectImage)`
    margin-top: 0;
    margin-bottom: 24px;
    max-width: 100%;
    height: 100px;
`;

const FormTitle = styled.h2`
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-align: center;
`;

const FormInput = styled.input`
    display: block;
    width: 100%;
    margin-bottom: 8px;
    font-size: 20px;
    padding: 4px 8px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #000000;
    outline: none;
    color: #000000;
`;

const FormButton = styled.button`
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 48px;
    font-size: 24px;
    font-weight: 700;
    border: 1px solid #13939F;
    border-radius: 4px;
    background-color: #13939F;
    color: white;
    transition: all 0.2s;
`;

const SignUpButton = styled(HashLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 24px;
    font-size: 24px;
    font-weight: 700;
    border: 1px solid #388E3C;
    border-radius: 4px;
    background-color: #388E3C;
    color: white;
    
    :hover, :focus {
      color: white;
      text-decoration: none;
    }
`;

interface Props extends i18nProps {
  onSubmit: ({ email, password, recaptcha }) => void,
  error: any | null,
  isProcessing: boolean
}

class LoginForm extends React.Component<Props> {

  state = {
    email: '',
    password: ''
  };

  recaptcha: Recaptcha | null = null;

  onChangeField(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit() {
    if (this.recaptcha) {
      this.recaptcha.execute();
    }
  }

  onResolved() {
    const { email, password } = this.state;
    const recaptcha = this.recaptcha.getResponse();
    this.props.onSubmit({ email, password, recaptcha });
    this.recaptcha.reset();
  }

  render() {
    const { email, password } = this.state;
    const { error, isProcessing, t } = this.props;

    return (
      <LoginFormSection>
        <FormHeader src={logo}/>

        <FormTitle>{t('ログイン')}</FormTitle>

        <ErrorMessage error={error}/>

        <FormInput type="email"
                   name="email"
                   placeholder={t('メールアドレス')}
                   value={email}
                   onChange={this.onChangeField.bind(this)}/>

        <FormInput type="password"
                   name="password"
                   placeholder={t('パスワード')}
                   value={password}
                   onChange={this.onChangeField.bind(this)}/>

        <HashLink smooth={false} to={'/auth/password/forgot'}>
          {t('パスワードを忘れた方へ')}
        </HashLink>

        <FormButton type="button"
                    onClick={this.onSubmit.bind(this)}
                    disabled={isProcessing}>
          {isProcessing ? <ActivityIndicator/> : t('ログイン')}
        </FormButton>

        <SignUpButton to="/auth/register">
          {t('新規登録')}
        </SignUpButton>

        <Recaptcha
          ref={ref => this.recaptcha = ref}
          locale={locale()}
          sitekey={recaptcha_site_key}
          onError={() => this.recaptcha.reset()}
          onResolved={this.onResolved.bind(this)}/>
      </LoginFormSection>
    );
  }
}

export default withI18n()(LoginForm);