import React from 'react';
import { withI18n } from 'react-i18next';
import styled, { css } from '../../helpers/styled';
import AspectImage from '../AspectImage';
import { asset_endpoint } from '../../config/app';
import nl2br from '../../helpers/nl2br';
import { combineMobileStyle } from '../../helpers/mediaQuery';
import noImage from '../../images/no_image.png';
import Fade from 'react-reveal/Fade';
import { InstructorState } from 'learning-redux-store';
import i18nProps from '../../helpers/i18n';

const InstructorListSection = styled.section`

`;

const Instructor = styled.article`
    padding: 8px;
    margin-bottom: 48px;
`;

const InstructorNo = styled.h3`
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.2em;
    
    &::after {
      content: '';
      display: block;
      width: 16em;
      max-width: 100%;
      border-bottom: 4px solid #000000;
      margin: 16px auto 0;
    }
`;

const InstructorName = styled.h2`
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    text-align: center;
    font-size: 40px;
    letter-spacing: 0.2em;
    font-weight: 400;
    margin-bottom: 4px;
`;

const InstructorRuby = styled.h4`
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0.1em;
    font-weight: 200;
    margin-bottom: 24px;
`;

const InstructorPosition = styled.p`
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.1em;
    font-weight: 200;
    margin-bottom: 24px;
`;

const InstructorFlex = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
`;

const InstructorImage = styled(AspectImage)`
    width: 50%;
    height: 300px;
`;

const InstructorImageMobile = css`
    width: 100%;
    margin-bottom: 8px;
`;

const InstructorImageCombined = combineMobileStyle(InstructorImage, InstructorImageMobile);

const InstructorDescription = styled.p`
    flex: 1;
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    padding: 0 16px;
`;

const ErrorView = styled.p`
    text-align: center;
    font-size: 20px;
    margin: 24px 16px;
`;

interface Props extends i18nProps {
  instructor: InstructorState
}

const InstructorList: React.FC<Props> = ({ t, instructor }) => {

  let element: JSX.Element[] = [];
  if (instructor.data.length === 0 && instructor.error) {
    element.push(
      <ErrorView>{nl2br(t('読み込みエラーが発生しました。\nしばらく経ってから再度やり直してください。'))}</ErrorView>
    );
  }
  instructor.data.forEach((instructor, index) => {
    element.push(
      <Instructor key={instructor.id}>
        <InstructorNo>instructor {index + 1}</InstructorNo>
        <InstructorName>{instructor.name}</InstructorName>
        <InstructorRuby>{instructor.ruby}</InstructorRuby>
        <InstructorPosition>{instructor.position}</InstructorPosition>
        <Fade top>
          <InstructorFlex>
            {(() => {
              if (instructor.image) {
                return <InstructorImageCombined
                  src={`${asset_endpoint}/uploads/instructor_photos/${instructor.image}?h=300`}/>;
              } else {
                return <InstructorImageCombined src={noImage}/>;
              }
            })()}
            <InstructorDescription>
              {nl2br(instructor.description)}
            </InstructorDescription>
          </InstructorFlex>
        </Fade>
      </Instructor>
    );
  });

  return (
    <InstructorListSection>
      {element}
    </InstructorListSection>
  );
};

export default withI18n()(InstructorList);