import * as React from 'react';
import {
  AppState,
  CartProps,
  CartState,
  NotificationProps,
  requestAddCart,
  sendNotification
} from 'learning-redux-store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import ActivityIndicator from '../components/ActivityIndicator';
import styled from '../helpers/styled';
import loginRequired from '../middleware/loginRequired';

const LoadingFlex = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
`;

interface Props extends CartProps, NotificationProps, RouteComponentProps<{ purchase_unit_id }> {
  cart: CartState;
}

class AddCart extends React.Component<Props> {

  componentDidMount(): void {
    const { purchase_unit_id } = this.props.match.params;
    this.props.requestAddCart(purchase_unit_id);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.cart.isProcessing) {

    } else if (nextProps.cart.error) {
      nextProps.history.push('/cart');
    } else {
      nextProps.history.push('/cart');
    }
  }

  render() {
    return <LoadingFlex><ActivityIndicator color={'#000000'}/></LoadingFlex>;
  }
}

export default connect(
  (state: AppState) => ({
    cart: state.cart
  }),
  { requestAddCart, sendNotification }
)(loginRequired(AddCart));