import React from 'react';
import styled from '../../helpers/styled';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import nl2br from '../../helpers/nl2br';
import locale from '../../helpers/locale';

const Section = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const PriceBox = styled.div`
`;

const BigText = styled.span`
  font-size: 32px;
  font-weight: 700;
`;

const SeparateText = styled.span`
  display: inline-block;
  margin: 0 8px;
`;

const DelText = styled.span`
  display: inline-block;
  position: relative;
  
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    border-top: solid 2px #000;
    position: absolute;
    left: 0 ;  
    top: calc(50% - 2px);
  }
  
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    border-top: solid 2px #000;
    position: absolute;
    left: 0 ;  
    bottom: calc(50% - 6px);
  }
`;

const Unlimited = styled.div`
  color: red;
  line-height: 1em;
  font-weight: bold;
  font-size: 16px;
  margin: 0 8px;
`;

const For = styled.div`
  margin: 8px 8px 0;
  line-height: 32px;
`;

const ExpireText = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

interface Props extends i18nProps {
  price: number;
  campaign: number | null;
}

const PriceView: React.FC<Props> = ({ price, campaign, t }) => {
  const isZh = locale() === 'zh';
  return (
    <Section>
      {isZh ? (
        <ExpireText>
          一年
        </ExpireText>
      ) : <></>}
      <PriceBox>
        {campaign ? (
          <>
            <DelText>{price.toLocaleString()}{t('JPY')}</DelText>
            <SeparateText>{t('から')}</SeparateText>
            <BigText>{campaign.toLocaleString()}{t('JPY')}</BigText>
          </>
        ) : <BigText>{price.toLocaleString()}{t('JPY')}</BigText>}
      </PriceBox>
      <Unlimited>
        {nl2br(t('ストリーム見放題'))}
      </Unlimited>
      <For>
        {t('へ')}
      </For>
      {!isZh ? (
        <ExpireText>
          {t('180日間')}
        </ExpireText>
      ) : <></>}
    </Section>
  );
};

export default withI18n()(PriceView);
