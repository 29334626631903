import React from "react"
import FontAwesome from "react-fontawesome";

interface Props {
    color?: string;
    size?: string;
}

const ActivityIndicator: React.FC<Props> = ({color = "#FFFFFF", size = "2x"}) => {
    // @ts-ignore
    return <FontAwesome name="spinner" spin size={size} style={{color: color}}/>
};

export default ActivityIndicator;