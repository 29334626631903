import * as React from 'react';
import styled from '../helpers/styled';
import i18nProps from '../helpers/i18n';
import { withI18n } from 'react-i18next';
import locale from '../helpers/locale';

const Warning = styled.p`
    text-align: center;
    font-weight: 700;
    font-size: 20px;
`;

const WarningText: React.FC<i18nProps> = ({ t }) => {
  if (locale() !== 'ja') {
    return <></>;
  }
  return (
    <Warning>{t('注）「海外における日本料理の調理技能認定制度」は日本国籍の方は対象としておりません。')}</Warning>
  );
};

export default withI18n()(WarningText);