import React from 'react';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import AspectImage from '../AspectImage';
import { HashLink } from '../../helpers/react-router-hash-link';
import logo from '../../images/site_logo_black.png';
import ErrorInput from '../ErrorInput';
import ErrorMessage from './ErrorMessage';
import ActivityIndicator from '../ActivityIndicator';
import i18nProps from '../../helpers/i18n';
import ErrorDatePicker from '../ErrorDatePicker';
import locale from '../../helpers/locale';

const RegisterFormSection = styled.form`
    width: 400px;
    max-width: 100%;
    margin: 24px auto;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 48px 16px;
    border-radius: 2px;
    overflow-y: auto;
`;

const FormHeader = styled(AspectImage)`
    margin-top: 0;
    margin-bottom: 24px;
    max-width: 100%;
    height: 100px;
`;

const FormTitle = styled.h2`
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-align: center;
`;

const FormInput = styled(ErrorInput)`
    display: block;
    width: 100%;
    margin-bottom: 8px;
    font-size: 20px;
    padding: 4px 8px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #000000;
    outline: none;
    color: #000000;
`;

const FormDatePicker = styled(ErrorDatePicker)`
    display: block;
    width: 100%;
    margin-bottom: 8px;
    font-size: 20px;
    padding: 4px 8px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #000000;
    outline: none;
    color: #000000;
`;

const FormButton = styled.button`
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 48px;
    font-size: 24px;
    font-weight: 700;
    border: 1px solid #13939F;
    border-radius: 4px;
    background-color: #13939F;
    color: white;
    transition: all 0.2s;
`;

const SignInButton = styled(HashLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 24px;
    font-size: 24px;
    font-weight: 700;
    border: 1px solid #388E3C;
    border-radius: 4px;
    background-color: #388E3C;
    color: white;
    
    :hover, :focus {
      color: white;
      text-decoration: none;
    }
`;

interface Props extends i18nProps {
  error: any | null;
  onChangeField: (e) => void;
  onSubmit: () => void;
  isProcessing: boolean,
  state: { email: string, password: string, first_name: string, middle_name: string, last_name: string, birth: string }
}

const RegisterForm: React.FC<Props> = ({ t, error, onSubmit, isProcessing, onChangeField, state }) => {

  const { email, password, first_name, middle_name, last_name, birth } = state;

  return (
    <RegisterFormSection>
      <FormHeader src={logo}/>

      <FormTitle>{t('新規登録')}</FormTitle>

      <ErrorMessage error={error}/>

      <FormInput type="email"
                 name="email"
                 placeholder="E-Mail"
                 value={email}
                 error={error}
                 onChange={onChangeField}/>

      <FormInput type="password"
                 name="password"
                 placeholder="Password"
                 value={password}
                 error={error}
                 onChange={onChangeField}/>

      <FormInput type='text'
                 name='first_name'
                 placeholder='First Name'
                 value={first_name}
                 error={error}
                 onChange={onChangeField}/>

      <FormInput type='text'
                 name='middle_name'
                 placeholder='Middle Name'
                 value={middle_name}
                 error={error}
                 onChange={onChangeField}/>

      <FormInput type='text'
                 name='last_name'
                 placeholder='Last Name'
                 value={last_name}
                 error={error}
                 onChange={onChangeField}/>

      <label>Birth Day</label>
      <FormDatePicker
        name={'birth'}
        error={error}
        onChange={onChangeField}
        locale={locale()}
        format={'y/MM/dd'}
        value={isNaN(new Date(birth).getTime()) ? undefined : new Date(birth)}
      />

      <FormButton type="button"
                  onClick={onSubmit}
                  disabled={isProcessing}>
        {isProcessing ? <ActivityIndicator/> : t('新規登録')}
      </FormButton>

      <SignInButton to="/auth/login">{t('ログイン')}</SignInButton>
    </RegisterFormSection>
  );
};

export default withI18n()(RegisterForm);