import React from 'react';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import {
  requestResetPassword,
  ResetPasswordState,
  sendNotification,
  ResetPasswordProps,
  AppState,
  NotificationProps
} from 'learning-redux-store';
import guestCheck from '../middleware/guestCheck';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Instructor/Footer';
import ActivityIndicator from '../components/ActivityIndicator';
import { Container, Description, ErrorMessage, InputField, SubmitButton } from '../components/ResetPassword/Elements';
import { RouteComponentProps } from 'react-router';
import i18nProps from '../helpers/i18n';

interface Props extends RouteComponentProps<{ 'token' }>, i18nProps, ResetPasswordProps, NotificationProps {
  reset_password: ResetPasswordState
}

class ResetPassword extends React.Component<Props> {

  state = {
    email: '',
    password: '',
    password_confirmation: '',
    token: null
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    this.setState({ token });
  }

  render() {
    const { t, reset_password } = this.props;

    return (
      <div>
        <Helmet title={`${t('パスワード再発行手続き')} | Japanese Cuisine.com`}/>

        <Header subTitle={t('パスワード再発行手続き')}/>
        <Container>
          <Description>
            {t('現在のメールアドレスと新しいパスワードを入力してください。')}
          </Description>
          {reset_password.error && (
            <ErrorMessage>{t('エラーが発生しました。恐れ入りますがご入力が正しいかご確認ください。')}</ErrorMessage>
          )}
          <InputField type={'email'}
                      placeholder={t('メールアドレス')}
                      value={this.state.email}
                      error={reset_password.error}
                      name={'email'}
                      onChange={this.onChangeField}/>
          <InputField type={'password'}
                      placeholder={t('新しいパスワード')}
                      value={this.state.password}
                      error={reset_password.error}
                      name={'password'}
                      onChange={this.onChangeField}/>
          <InputField type={'password'}
                      placeholder={t('パスワードの確認')}
                      value={this.state.password_confirmation}
                      error={reset_password.error}
                      name={'password_confirmation'}
                      onChange={this.onChangeField}/>
          <SubmitButton type='submit' onClick={this.onSubmit}>
            {reset_password.isProcessing ? <ActivityIndicator/> : t('送信')}
          </SubmitButton>
        </Container>
        <Footer/>
      </div>
    );
  }

  onChangeField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = () => {
    this.props.requestResetPassword({
      request: {
        email: this.state.email,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
        token: this.state.token || ''
      },
      callback: this.onSuccess
    });
  };

  onSuccess = () => {
    const { t } = this.props;
    this.props.sendNotification({
      message: t('パスワードをリセットしました。')
    });
    this.props.history.push('/auth/login');
  };
}

export default connect(
  (state: AppState) => ({
    reset_password: state.reset_password
  }),
  { requestResetPassword, sendNotification }
)(withI18n()(guestCheck(ResetPassword)));