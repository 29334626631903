import * as React from 'react';
import styled from '../../helpers/styled';
import { Movie } from 'learning-redux-store';
import { withI18n } from 'react-i18next';
import { HashLink } from '../../helpers/react-router-hash-link';
import i18nProps from '../../helpers/i18n';
import CampaignPriceLabel from '../CampaignPriceLabel';

const Section = styled.div`
  width: 630px;
  max-width: 100%;
  position: relative;
  margin: 24px auto;
  background-color: #FFFFFF;
  padding: 16px 24px;
`;

const Header = styled.p`
  text-align: center;
  background-color: #000000;
  color: #FFFFFF;
`;

const MovieBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
  padding: 8px;
  
  &:last-child {
    border-bottom: none;
  }
`;

const MovieTitle = styled.p`
  margin: 0;
  flex: 1;
`;

const MovieLink = styled(HashLink)`

`;

const MovieAlreadyBuy = styled.p`
  margin: 0 0 0 auto;
`;

const MovieBuyButton = styled(HashLink)`
  margin-left: auto;
  border: 1px solid #000000;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  padding: 8px;
  
  &:hover, &:active, &:focus {
    color: #000000;
    background-color: #FFFFFF;
    text-decoration: none;
  }
`;

interface Props extends i18nProps {
  movies: Movie[] | undefined;
  isLogin: boolean;
}

const MoviePaymentInfo: React.FC<Props> = ({ movies, isLogin, t }) => {
  if (movies === undefined) {
    return null;
  }
  let elements: JSX.Element[] = [];
  movies.forEach(movie => {
    elements.push(
      <MovieBox key={movie.id}>
        <MovieTitle>
          <MovieLink to={`/categories/${movie.category_id}/${movie.id}#show`} smooth>{movie.title}</MovieLink>
          <br/>
          {t('90日間')} {t('¥')}<CampaignPriceLabel price={movie.price}
                                                  campaign_price={movie.campaign_price}
                                                  campaign_rate={movie.campaign_rate}/></MovieTitle>
        {isLogin ? (
          movie.is_licensed ? (
              <MovieAlreadyBuy>{t('購入済')}</MovieAlreadyBuy>
            ) :
            movie.is_licensed || (
              <MovieBuyButton to={`/cart/${movie.purchase_unit_id}`}>
                {t('ご購入へ')}
              </MovieBuyButton>
            )
        ) : (
          <MovieBuyButton to={`/auth/login?prev=/categories/${movie.category_id}`}>
            {t('ご購入へ')}
          </MovieBuyButton>
        )}
      </MovieBox>
    );
  });

  return (
    <Section>
      <Header>{t('レッスンの単品購入はこちら ※セット購入がお得です！')}</Header>
      {elements}
    </Section>
  );
};

export default withI18n()(MoviePaymentInfo);