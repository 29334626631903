import React from 'react';
import styled, { css } from '../../helpers/styled';
import { HashLink } from '../../helpers/react-router-hash-link';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import { combineMobileStyle } from '../../helpers/mediaQuery';
import PriceView from './PriceView';

const Box = styled.div`
  border: 4px solid #E65100;
  position: relative;
  width: 824px;
  max-width: 100%;
  margin: 24px auto;
  overflow: hidden;
  padding: 16px;
  text-align: center;
`;

const NumberLabelPc = styled.div`
    position: absolute;
    left: -140px;
    top: -64px;
    text-align: center;
    font-weight: bold;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #E65100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #FFFFFF;
    font-size: 16px;
    width: 300px;
    height: 150px;
`;

const NumberLabelMobile = css`
  position: relative;
  width: auto;
  height: auto;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  top: auto;
  left: auto;
  margin: -16px -16px 8px;
`;

const NumberLabel = combineMobileStyle(NumberLabelPc, NumberLabelMobile);

const BigNumber = styled.div`
   font-size: 24px;
   font-weight: 700;
   margin-top: -8px;
`;

const PaymentBox = styled(HashLink)`
  margin-top: 16px;
  border: 1px solid #000000;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  padding: 8px;
  
  &:hover, &:active, &:focus {
    color: #000000;
    background-color: #FFFFFF;
    text-decoration: none;
  }
`;

const Description = styled.div`

`;

const SetLabel = styled.div`
  background-color: black;
  color: white;
  margin-top: 16px;
`;

const ProduceLabel = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

interface Props extends i18nProps {
  price: number;
  campaign: number | null;
  title: string;
  description: string;
  index: string;
  target: number;
}

const SetPriceItem: React.FC<Props> = ({ index, price, campaign, title, description, t, target }) => {
  return (
    <Box>
      <NumberLabel>SET PRICE<BigNumber>{index}</BigNumber></NumberLabel>
      <Description>
        {description}<br/>
        {t('購入後は１年間動画を見ることが出来ます。')}
      </Description>
      <SetLabel>
        {t('セット価格がお得です！')}
      </SetLabel>
      <ProduceLabel>
        {title}
      </ProduceLabel>
      <PriceView price={price}
                 campaign={campaign}/>
      <PaymentBox to={`/payment/all/${target}`}>
        {t('セット購入へ！')}
      </PaymentBox>
    </Box>
  );
};

export default withI18n()(SetPriceItem);
