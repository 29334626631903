import React from 'react';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import queryString from 'query-string';
import ErrorMessage from '../components/UserVerify/ErrorMessage';
import {
  AppState,
  NotificationProps,
  requestVerify,
  sendNotification,
  UserVerifyProps,
  UserVerifyState
} from 'learning-redux-store';
import { RouteComponentProps } from 'react-router';
import i18nProps from '../helpers/i18n';

interface Props extends RouteComponentProps<{ user_id }>, UserVerifyProps, NotificationProps, i18nProps {
  userVerify: UserVerifyState;
}

class UserVerify extends React.Component<Props> {

  componentDidMount() {
    const { user_id } = this.props.match.params;
    const params = queryString.parse(this.props.location.search);
    let { signature, key, expire, additional } = params;
    signature = signature as string;
    key = key as string;
    expire = expire as string;
    additional = additional as string;

    this.props.requestVerify({
      request: {
        user_id,
        signature,
        key,
        expire,
        additional
      },
      callback: this.onSuccess
    });
  }

  render() {
    const { t } = this.props;
    const { error } = this.props.userVerify;

    if (error) {
      return (
        <section>
          <ErrorMessage error={error}/>
        </section>
      );
    }

    return (
      <section>
        {t('現在メールアドレスを確認中です・・・')}
      </section>
    );
  }

  onSuccess = () => {
    const { t } = this.props;
    const params = queryString.parse(this.props.location.search);

    this.props.sendNotification({
      message: t('メールアドレスの認証に成功しました。')
    });
    if (params.additional) {
      this.props.history.push('/');
    } else {
      this.props.history.push('/auth/login');
    }
  };
}

export default connect(
  (state: AppState) => ({
    userVerify: state.userVerify
  }),
  { requestVerify, sendNotification }
)(withI18n()(UserVerify));