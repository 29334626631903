import styled from '../../helpers/styled';
import { HashLink } from '../../helpers/react-router-hash-link';

export const Section = styled.section`
  width: 1140px;
  max-width: 100%;
  padding: 16px 8px;
  margin: 0 auto;
`;

export const SectionHeader = styled.h2`
  border-bottom: 1px solid #a1a1a1;
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

export const Information = styled.p`

`;

export const TotalPrice = styled.p`
  text-align: right;
  border-bottom: 1px solid #000000;
  
  span {
    font-size: 1.5em;
    font-weight: bold;
  }
`;

export const BackLinkBox = styled.p`
  text-align: right;
`;

export const BackLink = styled(HashLink)`
  color: #000000;
  text-decoration: underline;
  
  &:hover, &:active, &:focus {
    color: #000000;
  }
`;

export const OrderBox = styled.p`
  text-align: center;
  margin-top: 24px;
`;

export const OrderButton = styled(HashLink)`
  color: #FFFFFF;
  background-color: #000000;
  border: 1px solid #000000;
  transition: 0.2s all;
  padding: 8px 16px;
  
  &:hover, &:active, &:focus {
    color: #000000;
    text-decoration: none;
    background-color: #FFFFFF;
  }
`;