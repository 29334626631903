import React from 'react';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import { Sticky, StickyContainer } from 'react-sticky';
import Header from '../components/Header';
import About from '../components/ManabiHome/About';
import Footer from '../components/ManabiHome/Footer';
import MemberBox from '../components/MemberBox';
import FreeMovies from '../components/ManabiHome/FreeMovies';
import Curriculum from '../components/ManabiHome/Curriculum';
import { AppState, CategoryState, requestBulkPrice, requestCategories } from 'learning-redux-store';
import { Helmet } from 'react-helmet-async';
import RegisterInformation from '../components/ManabiHome/RegisterInformation';
import Start from '../components/ManabiHome/Start';
import { ManabiBanner, ManabiScreen } from '../components/ManabiHome/Elements';
import CategoryProps from 'learning-redux-store/dist/props/category';
import i18nProps from '../helpers/i18n';
import Recommended from '../components/ManabiHome/Recommended';
import BronzeLinkBanner from '../components/BronzeLinkBanner';
import WarningText from '../components/WarningText';

interface Props extends CategoryProps, i18nProps {
  category: CategoryState;
}

class ManabiHome extends React.Component<Props> {

  componentDidMount() {
    this.props.requestCategories();
    this.props.requestBulkPrice();
  }

  render() {
    const { category, t } = this.props;

    return (
      <ManabiScreen id="top">
        <Helmet>
          <title>MANABI | Japanese Cuisine.com</title>
          <meta name='description'
                content={t('正当な“日本料理”の考え方と調理技能をコンテンツとして凝縮。数百年の伝統技能を、ネットで正しく学べます。')}/>
        </Helmet>
        <StickyContainer>
          <Sticky>
            {({ style }) => <Header containerStyle={style}
                                    title="MANABI"
                                    subTitle={t('日本料理を学ぶ')}/>}
          </Sticky>
          <ManabiBanner src={require(`../images/manabi/${t('manabi_banner_png')}`)}/>
          <About/>
          <BronzeLinkBanner/>
          <Start/>
          <WarningText/>
          <MemberBox/>
          <Recommended/>
          <FreeMovies/>
          <RegisterInformation/>
          <BronzeLinkBanner/>
          <Curriculum category={category}/>
          <Footer/>
        </StickyContainer>
      </ManabiScreen>
    );
  }
}

export default connect(
  (state: AppState) => ({
    category: state.category
  }),
  { requestCategories, requestBulkPrice }
)(withI18n()(ManabiHome));