import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import guestCheck from '../middleware/guestCheck';
import { AppState, requestSignUp, SignUpProps, SignUpState } from 'learning-redux-store';
import RegisterForm from '../components/SignUp/RegisterForm';
import Header from '../components/Header';
import { Flex, SignUpScreen } from '../components/SignUp/Elements';
import { RouteComponentProps } from 'react-router';
import RegisterInformation from '../components/SignUp/RegisterInformation';

interface Props extends SignUpProps, RouteComponentProps {
  signUp: SignUpState;
}

class SignUp extends React.Component<Props> {

  state = {
    email: '',
    password: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    birth: '2010-01-01 00:00:00'
  };

  render() {
    const { error, isProcessing } = this.props.signUp;

    return (
      <SignUpScreen>
        <Helmet>
          <title>SignUp | Japanese Cuisine.com</title>
        </Helmet>
        <Header/>
        <Flex>
          <RegisterInformation/>
          <RegisterForm
            error={error}
            state={this.state}
            onSubmit={this.onSubmit}
            onChangeField={this.onChangeField}
            isProcessing={isProcessing}/>
        </Flex>
      </SignUpScreen>
    );
  }

  onChangeField = (e) => {
    if (e instanceof Date) {
      this.setState({ birth: `${e.getFullYear()}/${e.getMonth() + 1}/${e.getDate()}` });
    } else if (e === null) {
      this.setState({ birth: '' });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onSubmit = () => {
    this.props.requestSignUp({
      request: { ...this.state },
      callback: this.onSuccess
    });
  };

  onSuccess = () => {
    this.props.history.push('/auth/register/success');
  };
}

export default connect(
  (state: AppState) => ({
    signUp: state.signUp
  }),
  { requestSignUp }
)(withI18n()(guestCheck(SignUp)));