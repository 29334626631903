import React from 'react';
import { withI18n } from 'react-i18next';
import styled, { css } from '../../helpers/styled';
import FontAwesome from 'react-fontawesome';
import background from '../../images/manabi/manabi_about_background.png';
import informationImage from '../../images/manabi/information_image.png';
import { HashLink } from '../../helpers/react-router-hash-link';
import { combileNoteStyle, combineMobileStyle } from '../../helpers/mediaQuery';
import i18nProps from '../../helpers/i18n';
import nl2br from '../../helpers/nl2br';
import WarningText from '../WarningText';

const AboutSection = styled.section`
    background-image: url(${background});
    background-size: cover;
    padding: 32px 8px;
`;

const Banner = styled.img`
    display: block;
    max-width: 100%;
    margin: 0 auto 24px;
`;

const Description = styled.p`
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    text-align: left;
    font-size: 32px;
    letter-spacing: 0.2em;
    font-weight: 200;
    width: 900px;
    max-width: 100%;
    margin: 24px auto;
`;

const DescriptionMobile = css`
    font-size: 24px;
`;

const DescriptionCombined = combineMobileStyle(Description, DescriptionMobile);

const Flex = styled.div`
    width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const FlexMobile = css`
    width: 100%;
`;

const FlexCombined = combileNoteStyle(Flex, FlexMobile);

const InformationBox = styled.div`
    flex: 1;
    padding-right: 100px;
`;

const InformationBoxMobile = css`
    padding-right: 0;
`;

const InformationBoxCombined = combileNoteStyle(InformationBox, InformationBoxMobile);

const ActionBox = styled.div`
    width: 250px;
    padding-top: 22px;
`;

const ActionBoxMobile = css`
    width: 100%;
    padding-right: 0;
`;

const ActionBoxCombined = combileNoteStyle(ActionBox, ActionBoxMobile);

const InformationHeaderTop = styled.h3`
    font-size: 20px;
`;

const InformationHeaderSecond = styled.h4`
    font-size: 32px;
    font-weight: 700;
`;

const InformationFlex = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const InformationText = styled.p`
    font-size: 14px;
    width: 50%;
    line-height: 1.3em;
`;

const InformationTextMobile = css`
    width: 100%;
`;

const InformationTextCombined = combineMobileStyle(InformationText, InformationTextMobile);

const InformationImageBox = styled(HashLink)`
    display: block;
    width: 50%;
    color: #000000;
    text-decoration: none;
    :hover {
      color: #000000;
      text-decoration: none;
    }
`;

const InformationImageBoxMobile = css`
    width: 100%;
`;

const InformationImageBoxCombined = combineMobileStyle(InformationImageBox, InformationImageBoxMobile);

const InformationImage = styled.img`
    width: 100%;
    transition: opacity 0.2s;
    
    ${InformationImageBoxCombined}:hover & {
      opacity: 0.8;
    }
`;

const InformationImageFlex = styled.div`
    display: flex;
    flex-direction: row;
`;

const InformationMore = styled.p`
    font-weight: 700;
`;

const InformationMoreLink = styled.p`
    margin-left: auto;
    margin-bottom: 0;
    font-weight: 700;
    letter-spacing: 0.2em;
`;

const MoreArrow = styled(FontAwesome)`
    font-weight: 700 !important;
`;

const ActionButton = styled.a`
    display: block;
    width: 100%;
    color: #000000;
    border: 1px solid #000000;
    padding: 16px;
    font-size: 16px;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: all 0.2s;
    margin-bottom: 16px;
    :hover {
      color: #FFFFFF;
      background-color: #000000;
      text-decoration: none;
    }
`;


const ActionButtonLink = styled(HashLink)`
    display: block;
    width: 100%;
    color: #000000;
    border: 1px solid #000000;
    padding: 16px;
    font-size: 16px;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: all 0.2s;
    margin-bottom: 16px;
    :hover {
      color: #FFFFFF;
      background-color: #000000;
      text-decoration: none;
    }
`;

const ActionArrow = styled(FontAwesome)`
    font-size: 18px !important;
`;

const About: React.FC<i18nProps> = ({t}) => {
  return (
    <AboutSection>
      <WarningText/>
      <Banner src={require(`../../images/manabi/${t('manabi_about_banner_png')}`)}/>
      <DescriptionCombined>
        {nl2br(t('正当な“日本料理”の考え方と調理技能をコンテンツとして凝縮。数百年の伝統技能を、ネットで正しく学べます。'))}<br/>
      </DescriptionCombined>
      <FlexCombined>
        <InformationBoxCombined>
          <InformationHeaderTop>{t('日本料理の巨匠が指導・監修する調理技能認定制度')}</InformationHeaderTop>
          <InformationHeaderSecond>{t('ブロンズ取得のための技能＋文化')}</InformationHeaderSecond>
          <InformationFlex>
            <InformationTextCombined>
              {t('何百年も続く日本料理の神髄を、わかりやすく動画でまとめた当サイトは、「海外における日本料理の調理技能認定制度」に則った公的で正式なもの。')}
              {t('日本の一流店の名匠が監修するコンテンツは繰り返し習得し、認定を得られれば、日本料理店のスタッフとして通用する本格的なもの。')}
              {t('オンラインでブロンズ認定までを的確にサポートしています。')}
            </InformationTextCombined>
            <InformationImageBoxCombined to='/manabi/instructor#top'>
              <InformationImage src={informationImage}/>
              <InformationImageFlex>
                <InformationMore>
                  {t('日本料理の巨匠たちが講師')}
                </InformationMore>
                <InformationMoreLink>
                  more<MoreArrow name='angle-right'/>
                </InformationMoreLink>
              </InformationImageFlex>
            </InformationImageBoxCombined>
          </InformationFlex>
        </InformationBoxCombined>
        <ActionBoxCombined>
          <ActionButton href="http://www.maff.go.jp/j/shokusan/syokubun/tyori.html"
                        target="_blank"
                        rel="noopener noreferrer">
            {nl2br(t('海外における日本料理の調理技能認定制度とは'))}<ActionArrow name='angle-right'/>
          </ActionButton>
          <ActionButtonLink to="/questions">
            {t('よくあるご質問')} <ActionArrow name='angle-right'/>
          </ActionButtonLink>
          <ActionButtonLink to="/contact">
            {t('お問い合わせ')} <ActionArrow name='angle-right'/>
          </ActionButtonLink>
        </ActionBoxCombined>
      </FlexCombined>
    </AboutSection>
  );
};

export default withI18n()(About);