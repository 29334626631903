import React from 'react';
import styled from '../../helpers/styled';
import { withI18n } from 'react-i18next';
import banner from '../../images/manabi/curricuulum_banner.png';
import UserWelcome from '../UserWelcome';
import paperImage from '../../images/paper_background.png';
import CurriculumItem from './CurriculumItem';
import i18nProps from '../../helpers/i18n';
import { CategoryState } from 'learning-redux-store';
import PaymentInfo from '../PaymentInfo';
import SetPaymentInfo from './SetPaymentInfo';
import BronzeLinkBanner from '../BronzeLinkBanner';
import WarningText from '../WarningText';

const CurriculumSection = styled.section`

`;

const Diamond = styled.div`
    margin: 0 auto;
    display: block;
    width: 5rem;
    height: 5rem;
    background: #FFFFFF;

    transform:
      translateY(-2.5rem)
      rotate(45deg)
      skew(
        calc((90deg - 60deg) / 2),
        calc((90deg - 60deg) / 2)
      );
`;

const CurriculumListBox = styled.div`
    background-image: url(${paperImage});
    background-size: cover;
    overflow: hidden;
    padding: 0 8px 24px;
`;

const CurriculumBanner = styled.img`
    display: block;
    max-width: 100%;
    margin: 0 auto 32px;
`;

const CurriculumHero = styled.p`
    width: 720px;
    max-width: 100%;
    margin: 0 auto 24px;
`;

const CurriculumPaymentInfo = styled(PaymentInfo)`
    width: 720px;
`;

interface Props extends i18nProps {
  category: CategoryState;
}

const Curriculum: React.FC<Props> = ({ t, category }) => {

  let element: JSX.Element[] = [];
  category.data.forEach((category, index) => {
    element.push(<CurriculumItem category={category} key={index}/>);
  });

  return (
    <CurriculumSection id={'curriculum'}>
      <CurriculumListBox>
        <Diamond/>
        <CurriculumBanner src={banner}/>
        <CurriculumHero>
          {t('当サイトのコンテンツは、どの順番で受講しても、充分に習得が可能です。')}<br/>
          {t('あなたのもっとも気になる調理技法から学習をはじめてください。')}<br/>
          {t('各コンテンツには関連する日本文化に関して、日本の料理マスターが簡潔に解説。')}<br/>
          {t('その独特の考え方は、あなたを驚かせることでしょう。')}<br/>
          {t('どこから入っても深淵な日本料理の神髄にたどり着ける。')}<br/>
          {t('いますぐ新しい調理方法と出会ってください。')}
        </CurriculumHero>
        <WarningText/>
        <SetPaymentInfo categories={category.data}
                        bulk_price={category.bulkPrice}/>
        <UserWelcome/>
        {element}
        <SetPaymentInfo categories={category.data}
                        bulk_price={category.bulkPrice}/>
        <BronzeLinkBanner/>
      </CurriculumListBox>
    </CurriculumSection>
  );
};

export default withI18n()(Curriculum);