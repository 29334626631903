import React from 'react';
import ReactPlayer from 'react-player';
import FontAwesome from 'react-fontawesome';
import screenfull from 'screenfull';
import Duration from './Duration';
import SeekBar from './SeekBar';
import VolumeBar from './VolumeBar';
import styled from '../helpers/styled';
import ActivityIndicator from './ActivityIndicator';
import { withI18n } from 'react-i18next';
import i18nProps from '../helpers/i18n';
import { findDomElement } from '../helpers/findDomElement';

const PlayerWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
`;

const Player = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

interface PlayBigButtonProps {
  isShown: boolean;
}

const PlayBigButton = styled.div<PlayBigButtonProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #FFFFFF;
    margin: auto;
    width: 100px;
    height: 100px;
    display: ${({ isShown }) => isShown ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50px;
    padding-left: 8px;
`;

const PlayLoading = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PlayError = styled.p`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    background-color: #000000;
`;

const ErrorHeader = styled.span`
    font-size: 32px;
`;

interface ThumbnailProps {
  isShown: boolean;
}

const Thumbnail = styled.div<ThumbnailProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: ${({ isShown }) => isShown ? 'flex' : 'none'};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`;

interface PlayerControlsProps {
  isShow: boolean;
  isEnabled: boolean;
}

const PlayerControls = styled.div<PlayerControlsProps>`
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ isShow }) => isShow ? '50px !important' : 0};
    opacity: ${({ isShow }) => isShow ? '1 !important' : 0};
    overflow: hidden;
    transition: all 0.1s;
    padding: 0 8px;
    color: #FFFFFF;
    display: ${({ isEnabled }) => isEnabled ? 'block' : 'none'};
`;

const PlayerButtons = styled.div`
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const PlayerIcon = styled(FontAwesome)`
    color: #FFF;
    cursor: pointer;
    width: 28px;
`;

const PlayerDuration = styled.div`
    display: flex;
    display: -webkit-flex;
    margin-left: 8px;
    margin-right: 8px;
`;

const PlayerMuteIcon = styled.div`
    width: 28px;
    margin-left: 8px;
    cursor: pointer;
`;

interface Props extends i18nProps {
  url: string;
  autoPlay: boolean;
  thumbUrl?: string;
}

class VideoPlayer extends React.Component<Props> {

  state = {
    playing: false,
    volume: 0.8,
    muted: false,
    duration: 0,
    played: 0,
    seeking: false,
    isReady: false,
    error: false,
    touchCountDown: 0,
    startedPlay: false
  };

  timeoutHandle: number | null = null;
  playerWithControl = React.createRef<HTMLDivElement>();
  player: ReactPlayer | null = null;

  pausePlayButton = () => {
    if (!this.state.playing && !this.state.startedPlay) {
      this.setState({ startedPlay: true });
    }
    this.setState({ playing: !this.state.playing });
  };

  onPlay = () => {
    this.setState({ playing: true });
  };

  onPause = () => {
    this.setState({ playing: false });
  };

  onEnded = () => {
    this.setState({ playing: false });
  };

  toggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  onSeekMouseDown = () => {
    this.setState({ seeking: true, playing: false });
  };
  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) });
  };
  onSeekMouseUp = e => {
    this.setState({ seeking: false, playing: true });
    if (this.player) {
      this.player.seekTo(parseFloat(e.target.value));
    }
  };

  onProgress = state => {
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  onSetVolume = e => {
    this.setState({
      volume: parseFloat(e.target.value),
      muted: false
    });
  };

  onDuration = (duration) => {
    this.setState({ duration });
  };

  onReady = () => {
    this.setState({ isReady: true });
    const { autoPlay } = this.props;
    if (autoPlay) {
      this.setState({ playing: true, startedPlay: true });
    }
  };

  onError = (e) => {
    this.setState({ error: true });
    if (e.name === 'NotAllowedError') {
      this.setState({ startedPlay: false });
    }
  };

  onClickFullscreen = () => {
    const isFullscreen = document['fullScreenElement'] || document['webkitIsFullScreen'] === true || document['mozFullScreen'] || document['msFullscreenElement'];
    if (isFullscreen && screenfull.enabled) {
      screenfull.exit();
    } else {
      if (screenfull.enabled) {
        screenfull.request(this.playerWithControl.current);
      } else {
        const vid = findDomElement(this.player).getElementsByTagName('video')[0];
        vid.removeAttribute('playsinline');
        vid.removeAttribute('webkit-playsinline');
        vid.pause();
        vid.play();
      }
    }
  };

  changeFullscreen = () => {
    const isFullscreen = document['fullScreenElement'] || document['webkitIsFullScreen'] === true || document['mozFullScreen'] || document['msFullscreenElement'];
    if (!isFullscreen) {
      const vid = findDomElement(this.player).getElementsByTagName('video')[0];
      vid.setAttribute('playsinline', '');
      vid.setAttribute('webkit-playsinline', '');
      vid.removeAttribute('controls');
    }
  };

  touchPlayer = () => {
    this.setState({
      touchCountDown: 5
    });
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
    this.timeoutHandle = setTimeout(() => {
      this.countDownControlShow();
    }, 1000);
  };


  countDownControlShow = () => {
    this.setState({
      touchCountDown: this.state.touchCountDown - 1
    });
    if (this.state.touchCountDown !== 0) {
      this.timeoutHandle = setTimeout(() => {
        this.countDownControlShow();
      }, 1000);
    }
  };

  ref = player => {
    this.player = player;
  };

  componentDidMount() {
    this.playerWithControl = React.createRef();
    findDomElement(this.player).getElementsByTagName('video')[0].addEventListener('webkitendfullscreen', this.changeFullscreen);
  }

  componentWillUnmount() {
    findDomElement(this.player).getElementsByTagName('video')[0].removeEventListener('webkitendfullscreen', this.changeFullscreen);

    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }

  render() {
    const { url, thumbUrl, t } = this.props;
    const { playing, volume, muted, duration, played, isReady, error, touchCountDown, startedPlay } = this.state;

    return (
      <PlayerWrapper ref={this.playerWithControl}
                     onTouchStart={this.touchPlayer}
                     onMouseMove={this.touchPlayer}>
        <Player
          ref={this.ref}
          url={url}
          playing={playing}
          volume={volume}
          muted={muted}
          onPlay={this.onPlay}
          onEnded={this.onEnded}
          onPause={this.onPause}
          onProgress={this.onProgress}
          onDuration={this.onDuration}
          onReady={this.onReady}
          onError={this.onError}
          width="100%"
          height='100%'
          config={{
            file: {
              attributes: {
                preload: 'none'
              },
              hlsOptions: {
                maxBufferSize: 5 * 1024 * 1024
              }
            }
          }}
          playsinline
        />
        <Thumbnail isShown={thumbUrl !== undefined && !startedPlay}
                   style={{ backgroundImage: `url(${thumbUrl})` }}/>
        {(() => {
          if (isReady) {
            return (
              <PlayBigButton isShown={!startedPlay} onClick={this.pausePlayButton}>
                <FontAwesome name={'play'} size={'4x'}/>
              </PlayBigButton>
            );
          } else if (error) {
            return (
              <PlayError>
                <ErrorHeader>:(</ErrorHeader>
                {t('申し訳ありませんが、読み込みエラーが発生しました。')}<br/>
                {t('しばらく経ってから再度やり直してください。')}
              </PlayError>
            );
          } else {
            return (
              <PlayLoading>
                <ActivityIndicator color='#FFFFFF' size="4x"/>
              </PlayLoading>
            );
          }
        })()}
        <PlayerControls isShow={!playing || touchCountDown > 0} isEnabled={startedPlay}>
          <SeekBar played={played}
                   onSeekChange={this.onSeekChange}
                   onSeekMouseDown={this.onSeekMouseDown}
                   onSeekMouseUp={this.onSeekMouseUp}/>
          <PlayerButtons>
            {(() => {
              if (playing) {
                return <PlayerIcon
                  size='2x'
                  onClick={this.pausePlayButton}
                  name="pause"/>;
              } else {
                return <PlayerIcon
                  size='2x'
                  onClick={this.pausePlayButton}
                  name="play"/>;
              }
            })()}
            <PlayerMuteIcon onClick={this.toggleMuted}>
              <PlayerIcon
                size='2x'
                name={muted ? 'volume-off' : (volume > 0.5 ? 'volume-up' : 'volume-down')}/>
            </PlayerMuteIcon>
            <VolumeBar volume={volume}
                       onSetVolume={this.onSetVolume}/>
            <PlayerDuration>
              <Duration seconds={duration * played}/>
              <span> / </span>
              <Duration seconds={duration}/>
            </PlayerDuration>
            <PlayerIcon
              size='2x'
              onClick={this.onClickFullscreen}
              style={{ marginRight: 8, marginLeft: 'auto' }}
              name="expand"/>
          </PlayerButtons>
        </PlayerControls>
      </PlayerWrapper>
    );
  }
}

export default withI18n()(VideoPlayer);