import * as React from 'react';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import { License } from 'learning-redux-store';
import { HashLink } from '../../helpers/react-router-hash-link';

const Table = styled.table`
  width: 100%;
  min-width: 270px;
  border-collapse: collapse;
  table-layout: fixed;
  
  thead {
    border-bottom: 1px solid #000000;
  }
  
  tr {
    width: 100%;
    display: flex;
    
    &:not(:last-of-type) {
      border-bottom: 1px solid #a1a1a1;    
    }
  }
  
  th, td {
    display: block;
    padding: 4px 0;
  }
  
  th:nth-of-type(1), td:nth-of-type(1) {
    width: 33%;
  }
  
  th:nth-of-type(2), td:nth-of-type(2) {
    width: 33%;
  }
  
  th:nth-of-type(3), td:nth-of-type(3) {
    width: 33%;
  }
`;

interface Prop extends i18nProps {
  licenses: License[];
}

const LicenseTable: React.FC<Prop> = ({ t, licenses }) => {

  let elements: JSX.Element[] = [];
  licenses.forEach(function(license, index) {
    elements.push(
      <tr key={index}>
        <td>
          <HashLink to={`/categories/${license.item_path}`}>
            {license.title}
          </HashLink>
        </td>
        <td>{license.expired_at}</td>
        <td>
          {license.is_expired && <HashLink to={`/cart/${license.id}`}>{t('再購入')}</HashLink>}
        </td>
      </tr>
    );
  });

  return (
    <section>
      <Table>
        <thead>
        <tr>
          <th>{t('レッスン名')}</th>
          <th>{t('視聴期限')}</th>
          <th>{t('アクション')}</th>
        </tr>
        </thead>
        <tbody>
        {elements}
        </tbody>
      </Table>
    </section>
  );
};

export default withI18n()(LicenseTable);