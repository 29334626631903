import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import asyncLocalStorage from '../helpers/asyncLocalStorage';
import queryString from 'query-string';

interface Props extends RouteComponentProps<{ locale }> {
}

class LocaleChanger extends React.Component<Props> {

  componentDidMount(): void {
    let locale = this.props.match.params.locale;
    if (locale === undefined || locale === null) {
      locale = 'en';
    }
    asyncLocalStorage
      .setItem('set-locale', locale)
      .then(() => {
        const params = queryString.parse(this.props.location.search);
        if (params.prev) {
          if (this.props.location.hash) {
            window.location.href = `${params.prev}${this.props.location.hash}`;
          } else {
            window.location.href = `${params.prev}`;
          }
        } else if (document.referrer && document.referrer !== window.location.href) {
          window.location.href = document.referrer;
        } else {
          window.location.href = '/';
        }
      });
  }

  render() {
    return null;
  }
}

export default LocaleChanger;