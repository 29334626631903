import * as React from 'react';
import styled from '../helpers/styled';
import { connect } from 'react-redux';
import { AppState, requestUpdateUser, requestUser, UserState } from 'learning-redux-store';
import UserProps from 'learning-redux-store/dist/props/user';
import { HashLink } from '../helpers/react-router-hash-link';
import i18nProps from '../helpers/i18n';
import { withI18n } from 'react-i18next';
import { isLogin } from '../helpers/isLogin';

const WelcomeBox = styled.div`
    background-color: #000000;
    padding: 16px 24px;
    width: 630px;
    max-width: 100%;
    margin: 0 auto 24px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WelcomeText = styled.p`
    flex: 1;
    margin: 0;
    font-size: 16px;
    text-align: center;
`;

const WelcomeUser = styled.p`
    width: 70%;
    margin: 0;
    font-size: 20px;
    font-weight: 700;
`;

const WelcomeBoxLink = styled(HashLink)`
    background-color: #000000;
    padding: 16px 24px;
    width: 628px;
    max-width: 100%;
    margin: 0 auto 24px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: 1px solid #000000;
    transition: 0.2s all;
    
    &:hover, &:active, &:focus {
      text-decoration: none;
      background-color: #FFFFFF;
      color: #000000;
    }
`;

interface Props extends UserProps, i18nProps {
  user: UserState;
}

class UserWelcome extends React.Component<Props> {

  componentDidMount() {
    if (!this.props.user.isProcessing) {
      this.props.requestUser();
    }
  }

  render() {
    const { user, t } = this.props;
    if (user.data === null || !isLogin()) {
      return (
        <WelcomeBoxLink to={'/auth/login'}>{t('Please Login')}</WelcomeBoxLink>
      );
    } else {
      return (
        <WelcomeBox>
          <WelcomeText>{t('welcome')}</WelcomeText>
          <WelcomeUser>
            {user.data.first_name} {user.data.middle_name} {user.data.last_name}
          </WelcomeUser>
        </WelcomeBox>
      );
    }
  }
}

export default connect(
  (state: AppState) => ({
    user: state.user
  }),
  { requestUser, requestUpdateUser }
)(withI18n()(UserWelcome));