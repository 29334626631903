import * as React from 'react';
import UserProps from 'learning-redux-store/dist/props/user';
import i18nProps from '../helpers/i18n';
import Header from '../components/Header';
import { withI18n } from 'react-i18next';
import styled, { css } from '../helpers/styled';
import ErrorInput from '../components/ErrorInput';
import { AppState, requestUpdateUser, requestUser, sendNotification, UserState } from 'learning-redux-store';
import { connect } from 'react-redux';
import ErrorDatePicker from '../components/ErrorDatePicker';
import Footer from '../components/Instructor/Footer';
import NotificationProps from 'learning-redux-store/dist/props/notification';
import { RouteComponentProps } from 'react-router';
import loginRequired from '../middleware/loginRequired';
import { combineMobileStyle } from '../helpers/mediaQuery';
import locale from '../helpers/locale';

const UpdateFormPc = styled.div`
  width: 416px;
  max-width: 100%;
  margin: 32px auto;
  padding: 24px 24px 48px;
  background-color: #F5F5F5;
`;

const UpdateFormMobile = css`
  padding: 24px 8px 48px;
`;

const UpdateForm = combineMobileStyle(UpdateFormPc, UpdateFormMobile);

const Input = styled(ErrorInput)`
  display: block;
  margin-bottom: 24px;
  width: 100%;
  border: 1px solid #a1a1a1;
  background-color: transparent;
`;

const DatePicker = styled(ErrorDatePicker)`
  display: block;
  border: 1px solid #a1a1a1;
  width: 400px;
  max-width: 100%;
`;

const Submit = styled.button`
  margin-top: 24px;
  background-color: #000000;
  color: #FFFFFF;
  border: 1px solid #000000;
  transition: 0.2s all;
  display: block;
  padding: 4px 16px;
  width: 100%;
  
  &:hover, &:active, &:focus {
    color: #000000;
    background-color: #FFFFFF;
  }
  
  &:disabled {
    background-color: #616161;
    border-color: #616161;
  }
`;

interface Prop extends UserProps, i18nProps, NotificationProps, RouteComponentProps {
  user: UserState;
}

class UpdateUser extends React.Component<Prop> {

  state = {
    email: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    birth: '',
    initialized: false,
    defaultEmail: ''
  };

  componentDidMount(): void {
    this.props.requestUser();
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    const { user } = nextProps;

    if (!user.isProcessing && user.data && !prevProps.initialized) {
      return {
        email: user.data.email,
        first_name: user.data.first_name,
        middle_name: user.data.middle_name,
        last_name: user.data.last_name,
        birth: user.data.birth,
        initialized: true,
        defaultEmail: user.data.email
      };
    } else {
      return null;
    }
  }

  onChangeField = (e) => {
    if (e instanceof Date) {
      this.setState({ birth: `${e.getFullYear()}/${e.getMonth() + 1}/${e.getDate()}` });
    } else if (e === null) {
      this.setState({ birth: '' });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const { t, user } = this.props;

    return (
      <section>
        <Header subTitle={t('アカウント情報の変更')}/>
        <UpdateForm>
          <label htmlFor={'email'}>{t('メールアドレス')}</label>
          <Input
            error={user.error}
            id={'email'}
            name='email'
            onChange={this.onChangeField}
            value={this.state.email}/>
          <label htmlFor={'first_name'}>{t('ファーストネーム')}</label>
          <Input
            error={user.error}
            id={'first_name'}
            name='first_name'
            onChange={this.onChangeField}
            value={this.state.first_name}/>
          <label htmlFor={'middle_name'}>{t('ミドルネーム')}</label>
          <Input
            error={user.error}
            id={'middle_name'}
            name='middle_name'
            onChange={this.onChangeField}
            value={this.state.middle_name}/>
          <label htmlFor={'last_name'}>{t('ファミリーネーム')}</label>
          <Input
            error={user.error}
            id={'last_name'}
            name='last_name'
            onChange={this.onChangeField}
            value={this.state.last_name}/>
          <label htmlFor={'birth'}>{t('生年月日')}</label><br/>
          <DatePicker
            error={user.error}
            name={'birth'}
            locale={locale()}
            format={'y/MM/dd'}
            onChange={this.onChangeField}
            value={isNaN(new Date(this.state.birth).getTime()) ? undefined : new Date(this.state.birth)}/>
          <Submit onClick={this.onUpdate} disabled={user.isProcessing}>{t('アカウント情報の変更')}</Submit>
        </UpdateForm>
        <Footer/>
      </section>
    );
  }

  onUpdate = () => {
    const changeEmail = this.state.email !== this.state.defaultEmail;
    this.props.requestUpdateUser({
      callback: this.onSuccessUpdate,
      updateData: {
        email: changeEmail ? this.state.email : undefined,
        first_name: this.state.first_name,
        middle_name: this.state.middle_name,
        last_name: this.state.last_name,
        birth: this.state.birth
      }
    });
  };

  onSuccessUpdate = (requireVerify: boolean) => {
    const { t, history } = this.props;
    if (requireVerify) {
      this.props.sendNotification({
        message: t('メールアドレス変更の確認メールを送信しましたのでご確認ください。'),
        level: 'success'
      });
    } else {
      this.props.sendNotification({
        message: t('アカウント情報を変更しました。'),
        level: 'success'
      });
    }
    history.push('/user');
  };
}

export default connect(
  (state: AppState) => ({
    user: state.user
  }),
  { requestUser, requestUpdateUser, sendNotification }
)(withI18n()(loginRequired(UpdateUser)));