import React from 'react';
import styled from '../../helpers/styled';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';

const Section = styled.p`
  color: red;
  text-align: center;
  padding: 48px;
`;

const EmptyCategoryView: React.FC<i18nProps> = ({t}) => {
  return (
    <Section>
      {t('このカテゴリに動画が設定されていません。')}
    </Section>
  );
};

export default withI18n()(EmptyCategoryView);
