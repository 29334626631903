import React from 'react';
import Slide from 'react-reveal/Slide';
import styled, { css } from '../../helpers/styled';
import { combineMobileStyle } from '../../helpers/mediaQuery';
import { HashLink } from '../../helpers/react-router-hash-link';
import FontAwesome from 'react-fontawesome';
import { withI18n } from 'react-i18next';
import { asset_endpoint } from '../../config/app';
import noImage from '../../images/no_image.png';
import AspectImage from '../AspectImage';
import { Category } from 'learning-redux-store';
import getBytes from '../../helpers/getBytes';

const CategoryBox = styled(HashLink)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px 24px 24px 24px;
    width: 720px;
    max-width: 100%;
    margin: 0 auto 24px;
    background-color: #FFFFFF;
    color: #000000;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    
    :hover, :active, :focus {
      text-decoration: none;
      color: #000000;
    }
`;

const CategoryCatchphrase = styled.p`
    width: 100%;
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.1em;
    padding-right: 42px;
`;

interface CategoryImageProps {
  animated: boolean;
}

const CategoryImage = styled(AspectImage)<CategoryImageProps>`
    width: 50%;
    max-height: 100%;
    display: block;
    
    ${CategoryBox}:hover & {
      display: ${({ animated }) => animated ? 'none' : 'block'};
    }
`;

const CategoryImageMobile = css`
    width: 100%;
    height: 190px;
    margin-bottom: 8px;
`;

const CategoryImageCombined = combineMobileStyle(CategoryImage, CategoryImageMobile);

const CategoryPreviewImage = styled(AspectImage)`
    width: 50%;
    max-height: 100%;
    display: none;
    
    ${CategoryBox}:hover & {
      display: block;
    }
`;

const CategoryPreviewImageMobile = css`
    width: 100%;
    height: 190px;
    margin-bottom: 8px;
`;

const CategoryPreviewImageCombined = combineMobileStyle(CategoryPreviewImage, CategoryPreviewImageMobile);

const CategoryInformation = styled.div`
    flex: 1;
    padding: 0 16px;
    text-align: center;
`;

const CategoryTitle = styled.h5`
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    
    &::after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 2px solid crimson;
      margin: 8px auto 0;
    }
`;

const CategoryDescription = styled.p<{ isLong: boolean }>`
    font-size: ${({ isLong }) => isLong ? '18px' : '24px'};
    margin: 8px 0;
`;

const CategoryCount = styled.p`
    display: inline-block;
    margin: 0 auto 16px;
    padding: 4px 16px;
    border: 1px solid #000000;
`;

const CategoryMove = styled.div`
    background-color: #000000;
    padding: 8px;
    color: #FFFFFF;
    width: 200px;
    margin: 0 auto;
    font-size: 16px;
    text-decoration: none;
    transition: all 0.2s;
    cursor: pointer;
    border: 1px solid #000000;
    
    :hover, :active, :focus {
      text-decoration: none;
      color: #000000;
      background-color: #FFFFFF;
    }
`;

const MoveArrow = styled(FontAwesome)`
    font-weight: 700 !important;
    font-size: 16px !important;
    margin-left: 8px;
`;

const FreeIcon = styled.div`
    position: absolute;
    right: -40px;
    top: 10px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #E65100;
    color: #FFFFFF;
    font-size: 16px;
    padding: 5px 45px;
`;

interface Props {
  category: Category;
}

const CurriculumItem: React.FC<Props> = ({ category }) => {
  return (
    <Slide left>
      <CategoryBox to={`/categories/${category.id}#top`}>
        {category.type === 2 && <FreeIcon>FREE</FreeIcon>}
        <CategoryCatchphrase>{category.description}</CategoryCatchphrase>
        {category.image && (
          <CategoryImageCombined
            src={`${asset_endpoint}/uploads/category_photos/${category.image}?w=360`}
            animated={category.preview !== null}
          />
        )}
        {category.image === null && (
          <CategoryImageCombined src={noImage}/>
        )}
        {category.preview && (
          <CategoryPreviewImageCombined
            src={`${asset_endpoint}/uploads/category_previews/${category.preview}`}/>
        )}
        <CategoryInformation>
          <CategoryTitle>{category.curriculum_no}</CategoryTitle>
          <CategoryDescription isLong={getBytes(category.title) > 24}>
            {category.title}
          </CategoryDescription>
          <CategoryCount>{category.skill_count} skill / {category.lecture_count} lecture</CategoryCount>
          <CategoryMove>
            move on <MoveArrow name="angle-right"/>
          </CategoryMove>
        </CategoryInformation>
      </CategoryBox>
    </Slide>
  );
};

export default withI18n()(CurriculumItem);