import * as React from 'react';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';
import styled from '../../helpers/styled';

const Section = styled.div`
  background: white;
  border-radius: 4px;
  box-shadow:0 2px 6px 3px #878787;
  padding: 16px;
  margin: 4px;
  text-align: center;
  min-width: 300px;
`;

const Header = styled.h3`
  margin-top: 0;
  margin-bottom: 16px;
`;

const EntryButton = styled.button`
  background-color: #0288D1;
  border: 1px solid #0288D1;
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 8px;
  min-width: 200px;
  margin-bottom: 16px;
  
  &:hover {
    background-color: #FFFFFF;
    color: #0288D1;
  }
`;

const HelpText = styled.p`
  margin-bottom: 24px;
`;

const CancelButton = styled.button`
  border: none;
  background-color: #FFFFFF;
  color: #2a88bd;
`;

interface Props extends i18nProps {
  onSubmit: () => void;
  onClose: () => void;
}

const EntryDialog: React.FC<Props> = ({ t, onSubmit, onClose }) => {
  return (
    <Section>
      <Header>{t('オンラインテストに申し込む')}</Header>
      <EntryButton onClick={() => {
        onSubmit();
        onClose();
      }}>{t('エントリーボタン')}</EntryButton>
      <HelpText>{t('自動返信メールが送られますのでご確認ください。')}</HelpText>
      <CancelButton onClick={onClose}>{t('キャンセル')}</CancelButton>
    </Section>
  );
};

export default withI18n()(EntryDialog);