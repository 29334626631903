import React from 'react';
import VideoPlayer from './VideoPlayer';
import { asset_endpoint, movie_endpoint } from '../config/app';
import { asyncLocalStorage } from 'learning-redux-store';
import ActivityIndicator from './ActivityIndicator';

interface Props {
  videoKey: string;
  thumbKey: string | null;
  isFree?: boolean;
}

class MovieVideoPlayer extends React.Component<Props> {

  state = {
    token: null
  };

  componentDidMount(): void {
    asyncLocalStorage()
      .getItem('user_session')
      .then(token => ({token}))
      .then(token => this.setState(token));
  }

  render() {
    const { videoKey, thumbKey, isFree = false } = this.props;
    const { token } = this.state;

    // ログイン不要
    if (!token) {
      if (!isFree) {
        return <ActivityIndicator/>
      } else if (thumbKey) {
        return (
          <VideoPlayer url={`${movie_endpoint}/${videoKey}/master.m3u8`}
                       thumbUrl={`${asset_endpoint}/uploads/movie_thumb/${thumbKey}`}
                       autoPlay={true}/>
        );
      } else {
        return (
          <VideoPlayer url={`${movie_endpoint}/${videoKey}/master.m3u8`}
                       autoPlay={true}/>
        );
      }
    }
    // ログイン必要
    if (thumbKey) {
      return (
        <VideoPlayer url={`${movie_endpoint}/${videoKey}/master.m3u8?t=${token}`}
                     thumbUrl={`${asset_endpoint}/uploads/movie_thumb/${thumbKey}`}
                     autoPlay={true}/>
      );
    } else {
      return (
        <VideoPlayer url={`${movie_endpoint}/${videoKey}/master.m3u8?t=${token}`}
                     autoPlay={true}/>
      );
    }
  }
};

export default MovieVideoPlayer;