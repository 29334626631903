import * as React from 'react';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';

const Section = styled.section`
  text-align: center;
  padding: 16px;
  color: red;
`;

interface Props extends i18nProps {
  error: any;
}

const ErrorText: React.FC<Props> = ({ error, t }) => {
  let message = 'エラーが発生しました。';

  if (error === 'invalid_signature') {
    message = 'URLに誤りがあります。';
  } else if (error === 'signature_expired') {
    message = '支払い有効期限が過ぎています。';
  } else if (error === 'already_purchased') {
    message = '既に支払済です。';
  }

  return (
    <Section>
      {t(message)}
    </Section>
  );
};

export default withI18n()(ErrorText);