import React from 'react';
import styled from '../helpers/styled';

const Container = styled.div`
    text-align: center;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top, center;
`;

const AspectImage: React.FC<React.ImgHTMLAttributes<{}>> = ({ style, src, className }) => {
  return (
    <Container style={{ ...style, backgroundImage: `url(${src})` }} className={className}/>
  );
};

export default AspectImage;