import * as React from 'react';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';
import styled from '../../helpers/styled';
import ErrorInput from '../ErrorInput';
import ActivityIndicator from '../ActivityIndicator';

const Section = styled.section`
  width: 768px;
  max-width: 100%;
  padding: 8px;
  margin: 0 auto;
`;

const Information = styled.p`

`;

const ErrorText = styled.p`
  color: red;
`;

const Input = styled(ErrorInput)`
  display: block;
  width: 400px;
  max-width: 100%;
  font-size: 20px;
`;

const Button = styled.button`
  background-color: #000000;
  border: 1px solid #000000;
  margin-top: 24px;
  outline: none;
  appearance: none;
  color: #FFFFFF;
  padding: 8px 24px;
  transition: 0.2s all;
  
  &:hover, &:active, &:focus {
    background-color: #FFFFFF;
    color: #000000;
  }
`;

interface Props extends i18nProps {
  onChangeCode: (code: string) => void;
  code: string;
  error: any | null;
  onSubmit: () => void;
  isProcessing: boolean;
  isHidden: boolean;
}

const CampaignCodeForm: React.FC<Props> = ({ t, onChangeCode, code, onSubmit, error, isProcessing, isHidden }) => {
  if (isHidden) {
    return null;
  }
  return (
    <Section>
      <Information>
        {t('キャンペーンコードをお持ちの場合は入力してください。')}
      </Information>
      {error && (
        <ErrorText>{t('キャンペーンコードに誤りがあります。')}</ErrorText>
      )}
      <Input
        error={error}
        name={'code'}
        onChange={e => onChangeCode(e.target.value)}
        value={code}/>
      <Button onClick={onSubmit}>
        {isProcessing && <ActivityIndicator color='#616161'/>}
        {isProcessing || t('ご注文確認')}
      </Button>
    </Section>
  );
};

export default withI18n()(CampaignCodeForm);