import * as React from 'react';
import { Cart } from 'learning-redux-store';
import styled from '../../helpers/styled';
import AspectImage from '../AspectImage';
import { asset_endpoint } from '../../config/app';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import noImage from '../../images/no_image.png';
import { HashLink } from '../../helpers/react-router-hash-link';

const Box = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #a1a1a1;
  padding: 16px 8px;
  align-items: center;
  
  &:last-of-type {
    border-bottom: none;
  }
`;

const Image = styled(AspectImage)`
  width: 96px;
  height: 54px;
`;

const Description = styled.div`
  flex: 1;
`;

const Title = styled.h4`
  margin: 0 0 4px;
`;

const TitleLink = styled(HashLink)`
  text-decoration: none;
  color: #000000;
  &:hover, &:active, &:focus {
    text-decoration: underline;
    color: #000000;
  }
`;

const Price = styled.p`
  margin: 0;
`;

const DeleteButton = styled.button`
  outline: none;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #a1a1a1;
`;

interface Props extends i18nProps {
  item: Cart;
  onDelete: (id: number) => void;
}

const CartItem: React.FC<Props> = ({ item, t, onDelete }) => {
  return (
    <Box>
      {item.item_image === null && (
        <Image src={noImage}/>
      )}
      {item.item_image && item.type === 1 && (
        <Image src={`${asset_endpoint}/uploads/category_photos/${item.item_image}?w=200`}/>
      )}
      {item.item_image && item.type === 2 && (
        <Image src={`${asset_endpoint}/uploads/movie_thumb/${item.item_image}?w=200`}/>
      )}
      <Description>
        <Title>
          {item.target === 0 ? (
            <TitleLink to={`/categories/${item.item_path}`}>
              {item.type === 1 && `${t('レッスン')} / `}
              {item.type === 2 && `${t('動画')} / `}
              {item.title}
            </TitleLink>
          ) : (
            <TitleLink to={`/recipe/categories/${item.item_path}`}>
              家庭料理 / {item.title}
            </TitleLink>
          )}
        </Title>
        <Price>{t('¥')}{item.price.toLocaleString()}</Price>
      </Description>
      <DeleteButton onClick={() => onDelete(item.id)}>×</DeleteButton>
    </Box>
  );
};

export default withI18n()(CartItem);
