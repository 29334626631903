import * as React from 'react';
import { withI18n } from 'react-i18next';
import i18nProps from '../helpers/i18n';
import nl2br from '../helpers/nl2br';
import styled from '../helpers/styled';
import Header from '../components/Header';
import { Helmet } from 'react-helmet-async';

const Table = styled.table`
  margin: 24px auto;
  border-collapse: collapse;
  
  tr {
    border: 1px solid #000000;
  }
  th {
    background-color: #c1c1c1;
    border-right: 1px solid #000000;
  }
  
  th, td {
    padding: 8px;
  }
`;

interface Prop extends i18nProps {

}

const Law: React.FC<Prop> = ({ t }) => {
  return (
    <div>
      <Helmet title={t('特定商取引法に基づく表記 | Japanese Cuisine.com')}/>
      <Header title='特定商取引法に基づく表記'/>
      <Table>
        <tr>
          <th>{t('販売社名')}</th>
          <td>{t('株式会社ブルーマジック')}</td>
        </tr>
        <tr>
          <th>{t('運営統括責任者')}</th>
          <td>{t('三好 邦浩')}</td>
        </tr>
        <tr>
          <th>{t('所在地')}</th>
          <td>{nl2br(t('東京都港区南麻布4-13-5 麻布矢野ビル4階'))}</td>
        </tr>
        <tr>
          <th>{t('電話番号')}</th>
          <td>{t('03-5422-7913')}</td>
        </tr>
        <tr>
          <th>{t('メールアドレス')}</th>
          <td>info@bluemagic.co.jp</td>
        </tr>
        <tr>
          <th>{t('販売URL')}</th>
          <td>https://japanese-cuisine.com/</td>
        </tr>
        <tr>
          <th>{t('お支払い方法')}</th>
          <td>{t('クレジットカード')}<br/>{t('銀聯カード')}</td>
        </tr>
        <tr>
          <th>{t('商品代金以外の必要金額')}</th>
          <td>{t('なし：送料は商品代金に込み')}</td>
        </tr>
        <tr>
          <th>{t('販売数量')}</th>
          <td>{t('１個から')}</td>
        </tr>
        <tr>
          <th>{t('お申込み有効期限')}</th>
          <td>{t('カード決済につき即時')}</td>
        </tr>
        <tr>
          <th>{t('商品引渡し時期')}</th>
          <td>{t('指定日が無ければご注文後10日営業日以内で発送致します。')}</td>
        </tr>
        <tr>
          <th>{t('商品引渡し方法')}</th>
          <td>{t('当方にて手配後、運送会社による配送')}</td>
        </tr>
        <tr>
          <th>{t('返品・不良品について')}</th>
          <td>{nl2br(t('「不良品・当社の商品の間違え」の場合は当社が負担いたします。\n' +
            '配送途中の破損などの事故がございましたら、弊社までご連絡下さい。\n' +
            '送料・手数料ともに弊社負担で早急に新品をご送付致します。\n' +
            '\n' +
            '【返品対象】\n' +
            '「不良品・当社の商品の間違え」の場合\n' +
            '\n' +
            '【返品時期】\n' +
            'ご購入後8日以内にご連絡があった場合に返金可能となります。\n' +
            '\n' +
            '【返品方法】\n' +
            'メールにて返金要請してください。\n' +
            '20日以内にご購入代金を指定の口座へお振込みいたします。'))}</td>
        </tr>
        <tr>
          <th>{t('表現、及び商品に関する注意書き')}</th>
          <td>{t('本商品に示された表現や再現性には個人差があり、必ずしも利益や効果を保証したものではございません。')}</td>
        </tr>
      </Table>
    </div>
  );
};

export default withI18n()(Law);