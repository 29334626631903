import * as React from 'react';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';
import styled, { css } from '../../helpers/styled';
import { combineTabletStyle } from '../../helpers/mediaQuery';
import StepBox from './StepBox';

const TopTitlePc = styled.div`
  min-height: 100px;
  border-left: 20px #000000 solid;
  padding: 8px 266px 8px 32px;
  display: flex;
  align-items: center;
  font-size: 32px;
  letter-spacing: 0.1em;
  color: white;
  background: rgb(224,0,49);
  background: linear-gradient(90deg, rgba(224,0,49,1) 0%, rgba(163,13,79,1) 72%, rgba(145,16,87,1) 82%, rgba(85,24,121,1) 100%);
`;

const TopTitleMobile = css`
  padding-right: 8px;
  font-size: 20px;
`;

const TopTitle = combineTabletStyle(TopTitlePc, TopTitleMobile);

const BannerFieldPc = styled.div`
  display: flex;
  flex-direction: row;
  margin: -50px 16px 0;
`;

const BannerFieldMobile = css`
  flex-direction: column;
`;

const BannerField = combineTabletStyle(BannerFieldPc, BannerFieldMobile);

const ImageBox = styled.div`
  flex: 1;
`;

const BannerImage = styled.img`
  max-width: 100%;
  max-height: 300px;
  margin: 66px 0 16px;
`;

const ImageTextPc = styled.p`
  color: #616161;
  font-size: 20px;
`;

const ImageTextMobile = css`
  font-size: 16px;
`;

const ImageText = combineTabletStyle(ImageTextPc, ImageTextMobile);

interface Props extends i18nProps {
  onEntry: () => void;
}

const Banner: React.FC<Props> = ({ t, onEntry }) => {
  const bannerImage = require(`../../images/bronze/${t('bronze_test_banner')}`);

  return (
    <div>
      <TopTitle>{t('ネットで取れる、日本政府制定のブロンズ認定')}</TopTitle>
      <BannerField>
        <ImageBox>
          <BannerImage src={bannerImage}/>
          <ImageText>{t('「海外における日本料理の調理技能認定制度」に基づく＜ブロンズ認定＞')}</ImageText>
        </ImageBox>
        <StepBox title={t('いますぐエントリー')} action={t('ENTRY')} isEnable={true} onClick={onEntry}/>
      </BannerField>
    </div>
  );
};

export default withI18n()(Banner);