import React from 'react';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import { User } from 'learning-redux-store';
import i18nProps from '../../helpers/i18n';
import { HashLink } from '../../helpers/react-router-hash-link';

const UserTableSection = styled.section`
  line-height: 1.8em;
`;

const FlexBox = styled.div`
  display: flex;
`;

const LeftInformation = styled.p`
  color: #616161;
  
  strong {
    color: #000000;
  }
`;

const RightLinkBox = styled.div`
  margin-left: auto;
`;

interface Props extends i18nProps {
  user: User;
}

const UserTable: React.FC<Props> = ({ user, t }) => {
  return (
    <UserTableSection>
      <FlexBox>
        <LeftInformation>
          <strong>{user.email}</strong><br/>
          {t('パスワード')}: ********<br/>
          {t('氏名')}: {user.first_name} {user.middle_name} {user.last_name}<br/>
          {t('生年月日')}: {user.birth}
        </LeftInformation>
        <RightLinkBox>
          <HashLink to={'/user/update'}>{t('アカウント情報の変更')}</HashLink><br/>
          <HashLink to={'/user/update_password'}>{t('パスワードの変更')}</HashLink>
        </RightLinkBox>
      </FlexBox>
    </UserTableSection>
  );
};

export default withI18n()(UserTable);