import * as React from 'react';
import styled from '../helpers/styled';
import { withI18n } from 'react-i18next';
import i18nProps from '../helpers/i18n';

const DelText = styled.span`
  font-size: 80%;
  display: inline-block;
  position: relative;
  
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    border-top: solid 2px #000;
    position: absolute;
    left: 0 ;  
    top: calc(50% - 2px);
  }
  
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    border-top: solid 2px #000;
    position: absolute;
    left: 0 ;  
    bottom: calc(50% - 6px);
  }
`;

interface Props extends i18nProps {
  price: number;
  campaign_price: number | null;
  campaign_rate: number;
}

const CampaignPriceLabel: React.FC<Props> = ({ price, campaign_price, campaign_rate, t }) => {
  if (campaign_price !== null && campaign_price !== undefined) {
    return (
      <>
        <DelText>{price.toLocaleString()}</DelText> {campaign_price.toLocaleString()}
      </>
    );
  } else {
    return <>{price.toLocaleString()}</>;
  }
};

export default withI18n()(CampaignPriceLabel);