import * as React from 'react';
import { connect } from 'react-redux';
import {
  AppState,
  CampaignCodeState,
  CartState,
  clearCheckCampaignCode,
  requestCheckCampaignCode,
  requestGetCart
} from 'learning-redux-store';
import { withI18n } from 'react-i18next';
import loginRequired from '../middleware/loginRequired';
import i18nProps from '../helpers/i18n';
import CampaignCodeProps from 'learning-redux-store/dist/props/campaign_code';
import Header from '../components/Header';
import ActivityIndicator from '../components/ActivityIndicator';
import NotFound from './NotFound';
import CategoryDetail from '../components/Order/CategoryDetail';
import { Helmet } from 'react-helmet-async';
import CampaignCodeForm from '../components/Order/CampaignCodeForm';
import ConfirmForm from '../components/Order/ConfirmForm';
import Payment from '../components/Lesson/Payment';
import MovieDetail from '../components/Order/MovieDetail';
import CartProps from 'learning-redux-store/dist/props/cart';

interface Props extends i18nProps, CampaignCodeProps, CartProps {
  campaign_code: CampaignCodeState;
  cart: CartState;
}

class Order extends React.Component<Props> {

  state = {
    code: '',
    price: null,
    ordered: false
  };

  componentDidMount(): void {
    this.props.clearCheckCampaignCode();
    this.props.requestGetCart();
  }

  render() {
    const { campaign_code, cart, t } = this.props;

    if (cart.isProcessing) {
      return <section style={{ textAlign: 'center' }}><ActivityIndicator color={'#000000'}/></section>;
    } else if (cart.error || cart.items.length === 0) {
      return <NotFound/>;
    }
    if (this.state.ordered) {
      return <Payment
        cart={true}
        code={this.state.code}/>;
    }

    let elements: JSX.Element[] = [];
    let totalPrice = 0;
    cart.items.forEach(item => {
      totalPrice += item.price;
      if (item.type === 1) {
        elements.push(<CategoryDetail category={item}/>);
      } else if (item.type === 2) {
        elements.push(<MovieDetail movie={item}/>);
      }
    });

    return (
      <section>
        <Helmet title={`${t('ご購入の確認')} | Japanese Cuisine.com`}/>
        <Header subTitle={t('ご購入の確認')}/>
        {elements}
        <CampaignCodeForm
          code={this.state.code}
          error={campaign_code.error}
          isProcessing={campaign_code.isProcessing}
          isHidden={this.state.price !== null && this.state.price !== undefined}
          onChangeCode={this.onChangeCode}
          onSubmit={this.onCheckCode}/>
        <ConfirmForm
          onSubmit={this.onSubmit}
          isHidden={campaign_code.error !== undefined && campaign_code.error !== null}
          itemPrice={totalPrice}
          price={this.state.price}/>
      </section>
    );
  }

  onChangeCode = (code: string) => {
    this.setState({ code: code || '' });
  };

  onCheckCode = () => {
    const { cart } = this.props;
    const totalPrice = cart.items.map(item => {
      return item.price
    }).reduce((previousValue, currentValue) => {
      return previousValue + currentValue;
    }, 0);
    if (this.state.code === undefined || this.state.code.length === 0) {
      this.setState({ price: 0 });
      this.props.clearCheckCampaignCode();
    } else {
      this.props.requestCheckCampaignCode({
        code: this.state.code,
        price: totalPrice,
        callback: this.onSuccessCampaignCode
      });
    }
  };

  onSuccessCampaignCode = (price: number) => {
    this.setState({ price });
  };

  onSubmit = () => {
    this.setState({ ordered: true });
  };
}

export default connect(
  (state: AppState) => ({
    campaign_code: state.campaign_code,
    cart: state.cart
  }),
  {
    requestCheckCampaignCode,
    clearCheckCampaignCode,
    requestGetCart
  }
)(withI18n()(loginRequired(Order)));