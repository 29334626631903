import React from 'react';
import styled from '../helpers/styled';
import { HashLink as Link } from '../helpers/react-router-hash-link';
import CloseButton from './CloseButton';
import siteLogo from '../images/site_logo_no_alpha.png';
import { withI18n } from 'react-i18next';
import i18nProps from '../helpers/i18n';
import { isLogin } from '../helpers/isLogin';
import locale from '../helpers/locale';
import { dougu_url } from '../config/app';

const SlideMenuContainer = styled.div`
    position: fixed;
    transition: right .2s;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    width: 400px;
    max-width: 100%;
    height: 100%;
    text-align: center;
    z-index: 4000;
    overflow-y: auto;
`;

const SlideMenuLogoLink = styled(Link)``;

const SlideMenuLogo = styled.img`
    width: 300px;
    margin: 56px auto 8px;
`;

const SlideMenuUl = styled.ul`
    list-style: none;
    padding: 24px 0 0 0;
`;

const SlideMenuLi = styled.li`
    margin-bottom: 24px;
`;

const SlideMenuChangeSection = styled(SlideMenuLi)`
    &::before {
      content: '';
      display: block;
      padding-top: 24px;
      border-top: 3px solid white;
      width: 13em;
      margin: 0 auto;
    }
`;

const SlideMenuLink = styled(Link)`
    color: white;
    text-decoration: none;
    letter-spacing: 0.3em;
    font-weight: bold;
    cursor: pointer;
    :hover, :active, :focus {
      color: #a1a1a1;
    }
`;

const SlideMenuOutLink = styled.a`
    color: white;  
    text-decoration: none;
    letter-spacing: 0.3em;
    font-weight: bold;
    cursor: pointer;
    :hover, :active, :focus {
      color: #a1a1a1;
    }
`;

const SlideMenuAdditional = styled.span`
    font-size: 12px;
    color: white;
    &::before {
      content: '\\A';
      white-space: pre;
    }
`;

const SlideCloseButton = styled(CloseButton)`
    top: 16px;
    right: 24px;
    height: 40px;
    width: 40px;
`;

const DropView = styled.div`
    position: fixed;
    z-index: 3000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

const SwitchLocaleBox = styled.div`
    padding: 8px;
    color: #FFFFFF;
`;

const SwitchLocaleButton = styled.a`
    outline: none;
    background: none;
    border: none;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    
    &:hover, &:active, &:focus {
      text-decoration: none;
      color: #FFFFFF;
    }
`;

interface Props extends i18nProps {
  menuShow: boolean;
  onClickToggle: () => void;
  onLogout: () => void;
}

const SlideMenu: React.FC<Props> = ({ menuShow, onClickToggle, onLogout, t }) => {

  const DropViewIfMenuShow = () => {
    if (menuShow) {
      return <DropView onClick={onClickToggle}/>;
    } else {
      return null;
    }
  };

  return (
    <section>
      <SlideMenuContainer style={{ right: menuShow ? 0 : -400 }}>
        <SlideMenuLogoLink smooth to="/#top">
          <SlideMenuLogo src={siteLogo}/>
        </SlideMenuLogoLink>
        <SwitchLocaleBox>
          <SwitchLocaleButton href='/locale/en'>English</SwitchLocaleButton> /
          <SwitchLocaleButton href='/locale/zh'>中文</SwitchLocaleButton> /
          <SwitchLocaleButton href='/locale/ja'>日本語</SwitchLocaleButton>
        </SwitchLocaleBox>
        <SlideMenuUl>
          <SlideMenuLi>
            <SlideMenuLink smooth to="/#latest-news">NEWS</SlideMenuLink>
            <SlideMenuAdditional>{t('お知らせ')}</SlideMenuAdditional>
          </SlideMenuLi>
          <SlideMenuLi>
            <SlideMenuLink smooth to="/#about">ABOUT US</SlideMenuLink>
            <SlideMenuAdditional>{t('日本料理comについて')}</SlideMenuAdditional>
          </SlideMenuLi>
          <SlideMenuLi>
            <SlideMenuLink smooth to="/manabi#top">MANABI</SlideMenuLink>
            <SlideMenuAdditional>{t('日本料理を学ぶ')}</SlideMenuAdditional>
          </SlideMenuLi>
          <SlideMenuLi>
            <SlideMenuOutLink href={`${dougu_url}/locale/${locale()}?top=1`}>DOUGU</SlideMenuOutLink>
            <SlideMenuAdditional>{t('日本料理を揃える')}</SlideMenuAdditional>
          </SlideMenuLi>
          {isLogin() ? (
            <SlideMenuChangeSection>
              <SlideMenuLink smooth to="/user">MEMBERSHIP<br/>INFORMATION</SlideMenuLink>
              <SlideMenuAdditional>{t('会員情報')}</SlideMenuAdditional>
            </SlideMenuChangeSection>
          ) : (
            <SlideMenuChangeSection>
              <SlideMenuLink smooth to="/auth/login">LOGIN</SlideMenuLink>
              <SlideMenuAdditional>{t('ログイン')}</SlideMenuAdditional>
            </SlideMenuChangeSection>
          )}
          {isLogin() && (
            <SlideMenuLi>
              <SlideMenuLink smooth to="/cart">CART</SlideMenuLink>
              <SlideMenuAdditional>{t('カート')}</SlideMenuAdditional>
            </SlideMenuLi>
          )}
          {isLogin() && (
            <SlideMenuLi>
              <SlideMenuOutLink onClick={onLogout}>LOGOUT</SlideMenuOutLink>
              <SlideMenuAdditional>{t('ログアウト')}</SlideMenuAdditional>
            </SlideMenuLi>
          )}
          <SlideMenuChangeSection>
            <SlideMenuLink smooth to="/contact">CONTACT</SlideMenuLink>
            <SlideMenuAdditional>{t('お問い合わせ')}</SlideMenuAdditional>
          </SlideMenuChangeSection>
          <SlideMenuLi>
            <SlideMenuLink smooth to="/questions">FAQ</SlideMenuLink>
            <SlideMenuAdditional>{t('よくあるご質問')}</SlideMenuAdditional>
          </SlideMenuLi>
        </SlideMenuUl>
        <SlideCloseButton onClick={onClickToggle}/>
      </SlideMenuContainer>
      <DropViewIfMenuShow/>
    </section>
  );
};

export default withI18n()(SlideMenu);