import * as React from 'react';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';
import styled, { css } from '../../helpers/styled';
import { HashLink } from '../../helpers/react-router-hash-link';
import { combineMobileStyle } from '../../helpers/mediaQuery';
import { News } from 'learning-redux-store';
import moment from 'moment';
import locale from '../../helpers/locale';

const SectionPc = styled.section`
  margin: 24px 0;
  padding: 0 10% 0 10%;
`;

const SectionMobile = css`
  padding: 0 16px;
`;

const Section = combineMobileStyle(SectionPc, SectionMobile);

const Anchor = styled.div`
    margin-top: -82px;
    padding-top: 82px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  padding: 8px 0;
  
  & + & {
    border-top: 1px dashed #616161;
  }
`;

const ItemHeader = styled.p`
  font-size: 20px;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  
  border-bottom: 1px dashed #212121;
`;

const Red = styled.span`
  color: red;
`;

const Link = styled(HashLink)`
`;

const DummyLink = styled.span`
  color: #3097D1;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    color: #216a94;
    text-decoration: underline;
  }
`;

interface Props extends i18nProps {
  news: News[];
  onClickNews: (news: News) => void;
}

const LatestNews: React.FC<Props> = ({ t, news, onClickNews }) => {

  let elements: JSX.Element[] = [];
  if (news.length > 0) {
    let item = news[0];
    const pickups = news.filter(i => i.pickup);
    if (pickups.length > 0) {
      item = pickups[0];
    }
    let published: string;
    if (locale() === 'en') {
      published = moment(item.published_at).format('MMMM. DD, YYYY');
    } else {
      published = moment(item.published_at).format('YYYY/MM/DD');
    }
    elements.push(
      <Item key={item.id}>
        <DummyLink onClick={() => onClickNews(item)}>
          {published} {item.title}
        </DummyLink>
      </Item>
    );
  }

  return (
    <Section>
      <Anchor id='latest-news'/>
      <ItemHeader>
        <Red>{t('最新')}</Red>{t('NEWS!')}
      </ItemHeader>
      <List>
        <Item>
          <Link to={'/manabi#curriculum'}>{t('2020/11/9 Japanese Cuisine 開設１周年キャンペーン実施中')}</Link>
        </Item>
        <Item>
          <Link to={'/recipe#curriculum'}>{t('2020/11/9 MANABIにレシピ動画が登場：匠が教え２２のコンテンツが新規追加')}</Link>
        </Item>
        {elements}
      </List>
    </Section>
  );
};

export default withI18n()(LatestNews);
