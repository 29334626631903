import * as React from 'react';
import i18nProps from '../helpers/i18n';
import styled, { css } from '../helpers/styled';
import { combineTabletStyle } from '../helpers/mediaQuery';
import { withI18n } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet-async';
import nl2br from '../helpers/nl2br';
import BronzeAgreementText from '../components/BronzeInformation/BronzeAgreementText';

const Section = styled.article`
`;

const TopTitlePc = styled.div`
  min-height: 100px;
  border-left: 20px #000000 solid;
  padding: 8px 8px 8px 32px;
  display: flex;
  align-items: center;
  font-size: 32px;
  letter-spacing: 0.1em;
  color: white;
  background: rgb(224,0,49);
  background: linear-gradient(90deg, rgba(224,0,49,1) 0%, rgba(163,13,79,1) 72%, rgba(145,16,87,1) 82%, rgba(85,24,121,1) 100%);
`;

const TopTitleMobile = css`
  padding-right: 8px;
  font-size: 20px;
`;

const TopTitle = combineTabletStyle(TopTitlePc, TopTitleMobile);

const AgreementBox = styled.div`
  width: 1120px;
  max-width: 100%;
  margin: 0 auto;
`;

const BoxPc = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 16px 16px 48px;
`;

const BoxMobile = css`
  margin: 16px;
`;

const Box = combineTabletStyle(BoxPc, BoxMobile);

const Title = styled.div`
  width: 12em;
  max-width: 100%;
  
  article.long & {
    width: 25em;
  }
`;

const Body = styled.div`
  flex: 1;
  min-width: 300px;
`;

const DecimalList = styled.ul`
  list-style: decimal;
  padding-left: 24px;
`;

const CircleList = styled.ul`
  list-style: disc;
  padding-left: 24px;
`;

const ButtonBox = styled.div`
  text-align: center;
  margin: 48px;
`;

const AgreementButton = styled.label`
  border: 1px solid #212121;
  padding: 8px 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 400px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  &:before {
    content: '';
    display: block;
    height: 25px;
    width: 25px;
    margin-right: 4px;
    font-size: 16px;
    border: 1px solid #212121;
  }
  
  input:checked + &:before {
    content: '✔︎';
  }
`;

interface Props extends i18nProps, RouteComponentProps {

}

class BronzeAgreement extends React.Component<Props> {

  state = {
    isProgress: false
  };

  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props;

    return (
      <Section className={t('認定者のメリット').length > 12 ? 'long' : ''}>
        <Helmet>
          <title>{t('ブロンズ認定オンラインテスト')} {t('受験規約')} | Japanese Cuisine.com</title>
        </Helmet>
        <TopTitle>{t('受験規約')}</TopTitle>
        <BronzeAgreementText isFront={false}/>
        <ButtonBox>
          <input type='checkbox' id='agreement'
                 style={{ display: 'none' }}
                 disabled={this.state.isProgress}
                 checked={localStorage.getItem('bronze_agreement') != null}
                 onChange={this.changeAgreement}/>
          <AgreementButton htmlFor='agreement'>
            {nl2br(t('上記の規約に同意する'))}
          </AgreementButton>
        </ButtonBox>
      </Section>
    );
  }

  changeAgreement = (e) => {
    this.setState({ isProgress: true });

    const isChecked = e.target.checked;
    if (isChecked) {
      localStorage.setItem('bronze_agreement', '1');
    } else {
      localStorage.removeItem('bronze_agreement');
    }

    setTimeout(() => {
      this.props.history.push('/bronze');
    }, 1000);

    setTimeout(() => {
      window.scrollTo(0, 10000);
    }, 1100);
  };
}

export default withI18n()(BronzeAgreement);