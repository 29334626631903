import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import SignIn from './containers/SignIn';
import SignUp from './containers/SignUp';
import SuccessSignUp from './containers/SuccessSignUp';
import UserVerify from './containers/UserVerify';
import PaymentThankYou from './containers/PaymentThankYou';
import ManabiHome from './containers/ManabiHome';
import Instructor from './containers/Instructor';
import UserInformation from './containers/UserInformation';
import Contact from './containers/Contact';
import Lesson from './containers/Lesson';
import PaymentCancel from './containers/PaymentCancel';
import Question from './containers/Question';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import Law from './containers/Law';
import PrivacyPolicy from './containers/PrivacyPolicy';
import Terms from './containers/Terms';
import UpdateUser from './containers/UpdateUser';
import UpdatePassword from './containers/UpdatePassword';
import Order from './containers/Order';
import LocaleChanger from './containers/LocaleChanger';
import OrderBulk from './containers/OrderBulk';
import AddCart from './containers/AddCart';
import Cart from './containers/Cart';
import BronzeInformation from './containers/BronzeInformation';
import BronzeAgreement from './containers/BronzeAgreement';
import BronzePayment from './containers/BronzePayment';
import RecipeMovieTop from './containers/RecipeMovieTop';
import RecipeView from './containers/RecipeView';

const AppRouting = ({children: Children}) => {
  return withRouter(routerProps => {
    return (
      <React.Fragment>
        <Children {...routerProps} />
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/manabi' component={ManabiHome}/>
          <Route exact path='/manabi/instructor' component={Instructor}/>
          <Route exact path='/categories/:category_id' component={Lesson}/>
          <Route exact path='/categories/:category_id/:movie_id' component={Lesson}/>
          <Route exact path='/auth/login' component={SignIn}/>
          <Route exact path='/auth/register' component={SignUp}/>
          <Route exact path='/auth/register/success' component={SuccessSignUp}/>
          <Route exact path='/auth/password/forgot' component={ForgotPassword}/>
          <Route exact path='/auth/password/reset/:token' component={ResetPassword}/>
          <Route exact path='/user/verify/:user_id' component={UserVerify}/>
          <Route exact path='/user' component={UserInformation}/>
          <Route exact path='/user/update' component={UpdateUser}/>
          <Route exact path='/user/update_password' component={UpdatePassword}/>
          <Route exact path='/payment' component={Order}/>
          <Route exact path='/payment/all/:target' component={OrderBulk}/>
          <Route exact path='/payment/thank_you' component={PaymentThankYou}/>
          <Route exact path='/payment/cancel' component={PaymentCancel}/>
          <Route exact path='/contact' component={Contact}/>
          <Route exact path='/questions' component={Question}/>
          <Route exact path='/law' component={Law}/>
          <Route exact path='/privacy' component={PrivacyPolicy}/>
          <Route exact path='/terms' component={Terms}/>
          <Route exact path='/locale/:locale' component={LocaleChanger}/>
          <Route exact path='/cart' component={Cart}/>
          <Route exact path='/cart/:purchase_unit_id' component={AddCart}/>
          <Route exact path='/bronze' component={BronzeInformation}/>
          <Route exact path='/bronze/agreement' component={BronzeAgreement}/>
          <Route exact path='/bronze/payment/:entry_id' component={BronzePayment}/>
          <Route exact path='/recipe' component={RecipeMovieTop}/>
          <Route exact path='/recipe/categories/:category_id' component={RecipeView}/>
          <Route component={NotFound}/>
        </Switch>
      </React.Fragment>
    );
  })
};

export default AppRouting;
