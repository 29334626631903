import React from 'react';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import { HashLink } from '../../helpers/react-router-hash-link';
import i18nProps from '../../helpers/i18n';

const RegisterInformationSection = styled.section`
    margin: 0 auto 24px;
    text-align: center;
    padding: 8px;
`;

const RegisterNow = styled(HashLink)`
    color: #000000;
    font-weight: 700;
    font-size: 24px;
    display: inline-block;
    position: relative;
    margin-bottom: 24px;
    
    &:before, &:after {
      position: absolute;
      top: 1.5em;
      content: "";
      display: inline-block;
      width: 0;
      height: 2px; 
      background: #000000;
      transition: all 0.2s;
    }
    
    &:before {
      left: 50%;
    }
    
    &:after {
      right: 50%;
    }
    
    :hover, :active, :focus {
      text-decoration: none;
      color: #000000;
      
      &:before,&:after {
        width: 50%;
      }
    }
`;

const Information = styled.p`
    text-align: left;
    width: 720px;
    max-width: 100%;
    margin: 0 auto;
`;

const RegisterInformation: React.FC<i18nProps> = ({ t }) => {
  return (
    <RegisterInformationSection>
      <RegisterNow to='/auth/register'>{t('無料動画もご用意。いますぐご登録ください。')}</RegisterNow>
      <Information>
        {t('日本料理は、料理に対する考え方も調理技術も、他の地域に類を見ない独特の体系を持っています。')}<br/>
        {t('日本料理を学ぶために、最初に知っておきたい「大前提」をオープンコンテンツでお届けします。')}<br/>
        {t('いますぐご登録ください。')}
      </Information>
    </RegisterInformationSection>
  );
};

export default withI18n()(RegisterInformation);