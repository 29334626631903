import storage from 'redux-persist/es/storage';
import { createLearningStore } from 'learning-redux-store/dist';
import { api_endpoint } from './config/app';
import locale from './helpers/locale';
import asyncLocalStorage from './helpers/asyncLocalStorage';
import { createMigrate } from 'redux-persist';

const migrations = {
  0: (state) => {
    return state;
  },
  1: (state) => {
    // カテゴリをリセット
    return {
      ...state,
      category: undefined
    };
  }
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['user'],
  migrate: createMigrate(migrations, { debug: false }),
};

const store = createLearningStore(
  api_endpoint,
  locale,
  asyncLocalStorage,
  persistConfig
);

export default store;