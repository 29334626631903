import * as React from 'react';
import styled, { css } from '../../helpers/styled';
import nl2br from '../../helpers/nl2br';
import { combineTabletStyle } from '../../helpers/mediaQuery';

const BoxPc = styled.div<{ isEnable: boolean }>`
  width: 250px;
  height: 400px;
  display: flex;
  flex-direction: column;
  background: ${({ isEnable }) => isEnable ? 'linear-gradient(180deg, rgba(2,93,172,1) 0%, rgba(5,124,224,1) 20%, rgba(1,149,166,1) 69%, rgba(4,148,77,1) 100%)' : '#9FA0A0'};
`;

const BoxMobile = css<{ isEnable: boolean }>`
  width: 100%;
  height: auto;
  flex-direction: row;
  background: ${({ isEnable }) => isEnable ? 'linear-gradient(90deg, rgba(2,93,172,1) 0%, rgba(5,124,224,1) 20%, rgba(1,149,166,1) 69%, rgba(4,148,77,1) 100%)' : '#9FA0A0'};
`;

const Box = combineTabletStyle(BoxPc, BoxMobile);

const TitlePc = styled.p<{small: boolean}>`
  color: white;
  flex: 1;
  text-align: center;
  padding: 16px;
  font-size: ${({small}) => small ? "30px" : "40px"};
  overflow: hidden;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleMobile = css<{small: boolean}>`
  margin-top: 0;
  font-size: ${({small}) => small ? "18px" : "24px"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

const Title = combineTabletStyle(TitlePc, TitleMobile);

const ButtonBoxPc = styled.div<{ isEnable: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  margin: 16px;
  height: 150px;
  cursor: ${({ isEnable }) => isEnable ? 'pointer' : ''};
  transition: background-color 200ms ease-in;
  
  &:hover {
    background: ${({ isEnable }) => isEnable ? '#e1e1e1' : 'white'};
  }
`;

const ButtonBoxMobile = css`
  height: 100%;
  width: 40%;
`;

const ButtonBox = combineTabletStyle(ButtonBoxPc, ButtonBoxMobile);

const Arrow = styled.div`
  font-size: 24px;
  height: 50px;
`;

const ActionPc = styled.div<{small: boolean}>`
  flex: 1;
  font-size: ${({small}) => small ? "24px" : "32px"};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const ActionMobile = css<{small: boolean}>`
  font-size: ${({small}) => small ? "18px" : "24px"};
`;

const Action = combineTabletStyle(ActionPc, ActionMobile);

interface Props {
  title: string;
  action: string;
  isEnable: boolean;
  onClick: () => void;
}

const StepBox: React.FC<Props> = ({ title, action, isEnable, onClick }) => {
  return (
    <Box isEnable={isEnable}>
      <Title small={title.length > 20}>{nl2br(title)}</Title>
      <ButtonBox isEnable={isEnable} onClick={() => isEnable ? onClick() : undefined}>
        <Arrow>ˇ</Arrow>
        <Action small={action.length > 10}>{nl2br(action)}</Action>
      </ButtonBox>
    </Box>
  );
};

export default StepBox;