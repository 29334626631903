import * as React from 'react';
import styled, { css } from '../../helpers/styled';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';
import BronzeSectionHeader from './BronzeSectionHeader';
import secondImage from '../../images/bronze/second_image.png';
import thirdImage from '../../images/bronze/third_image.png';
import { combineTabletStyle } from '../../helpers/mediaQuery';

const Section = styled.section`
  padding: 16px;
  font-size: 16px;
  letter-spacing: 0.1em;
`;

const FirstBody = styled.p`
  
`;

const SecondBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const SecondImageBox = styled.div`
  width: 300px;
`;

const SecondImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const SecondTextPc = styled.p`
  flex: 1;
  min-width: 300px;
  margin-left: 16px;
`;

const SecondTextMobile = css`
  margin-left: 0;
`;

const ThirdBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const ThirdImageBox = styled.div`
  width: 300px;
`;

const ThirdImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ThirdImageDesc = styled.p`
  color: #616161;
  font-size: 12px;
`;

const ThirdTextPc = styled.p`
  flex: 1;
  min-width: 300px;
  margin-right: 16px;
`;

const ThirdTextMobile = css`
  margin-right: 0;
`;

const ThirdText = combineTabletStyle(ThirdTextPc, ThirdTextMobile);

const SecondText = combineTabletStyle(SecondTextPc, SecondTextMobile);

const AboutBronze: React.FC<i18nProps> = ({ t }) => {
  return (
    <Section>
      <BronzeSectionHeader>{t('「海外における日本料理の調理技能認定制度」ブロンズ認定制度について')}</BronzeSectionHeader>
      <FirstBody>
        {t('近年、世界中で日本食がブームとなり、日本国外の日本食レストランは約12万件にものぼっています。')}<br/>
        {t('しかし、それらの日本食レストランの中で、本格的に日本料理を学んだことがある料理人は、ごく僅かと言われています。')}<br/>
        {t('農林水産省では、日本料理の適切な知識・技術を有する海外の日本料理人を対象に「海外における日本料理の調理技能の認定に関するガイドライン」を定めました。')}<br/>
        {t('このガイドラインにおいて定められている認定には、ゴールド、シルバー、ブロンズの3つの階があり、海外の外国人日本食料理人が有している日本料理に関する知識や技能の習得度に準じて付与されます。')}
      </FirstBody>
      <SecondBody>
        <SecondImageBox>
          <SecondImage src={secondImage}/>
        </SecondImageBox>
        <SecondText>
          {t('ゴールド認定は2年程度、シルバー認定は1年程度、日本国内の日本食レストランでの実務経験が必要であることから、日本への留学を条件としていますが、ブロンズ認定は、日本へ留学することなく修得することができる唯一の、しかも、世界中の多くの日本料理で必要とされている日本料理の基礎的な知識と技能が身についていることが証明できる資格です。')}
        </SecondText>
      </SecondBody>
      <ThirdBody>
        <ThirdText>
          {t('当サイトにおけるブロンズ認定試験は、日本料理のトップシェフや日本料理の研究家・教育者が所属し、国内外で最も権威のある「日本料理コンペティション」等を主催しているNPO法人日本料理アカデミー（農林水産省のガイドラインに即した正規の認定団体）が実施します。')}<br/>
          {t('そして、このブロンズ認定試験は、世界中どこにいても受験することができる唯一のオンラインテストでもあります。')}<br/>
          {t('このブロンズ認定を受けていることは、他店との差別化や集客など、日本食レストランの経営や就職などに効果を発揮すると確信いたします。')}
          {t('是非、チャレンジしてください。')}
        </ThirdText>
        <ThirdImageBox>
          <ThirdImage src={thirdImage}/>
          <ThirdImageDesc>
            {t('認定試験に合格すると、認定登録証が発行され、ブロンズ認定バッジ（有料）を呈示することができます。')}
          </ThirdImageDesc>
        </ThirdImageBox>
      </ThirdBody>
    </Section>
  );
};

export default withI18n()(AboutBronze);