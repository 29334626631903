import React from 'react';
import { withI18n } from 'react-i18next';
import styled, { css } from '../../helpers/styled';
import { combileNoteStyle } from '../../helpers/mediaQuery';
import { asset_endpoint } from '../../config/app';
import VideoPlayer from '../VideoPlayer';
import i18nProps from '../../helpers/i18n';
import locale from '../../helpers/locale';

const FreeMoviesSection = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 880px;
    margin: 48px auto 32px;
`;

const FreeMovieSectionMobile = css`
    width: 100%;
`;

const FreeMovieSectionCombined = combileNoteStyle(FreeMoviesSection, FreeMovieSectionMobile);

const MovieItem = styled.div`
    width: 50%;
    padding: 16px;
`;

const MovieItemMobile = css`
    width: 100%;
`;

const MovieItemCombined = combileNoteStyle(MovieItem, MovieItemMobile);

const MovieTitle = styled.p`
    font-weight: 700;
    font-size: 16px;
    text-align: left;
`;

const MovieInfoFree = styled.p`
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    color: #BF360C;
    margin-bottom: 4px;
`;

const FreeMovies: React.FC<i18nProps> = ({ t }) => {
  return (
    <FreeMovieSectionCombined>
      <MovieItemCombined>
        <MovieInfoFree>【{t('無料動画')}】</MovieInfoFree>
        <MovieTitle>{t('日本料理の精神')}</MovieTitle>
        <VideoPlayer
          url={`${asset_endpoint}/movies/spirit_${locale()}/master.m3u8`}
          thumbUrl={require(`../../images/manabi/${t('free_movie_spirit')}`)}
          autoPlay={false}/>
      </MovieItemCombined>
      <MovieItemCombined>
        <MovieInfoFree>【{t('無料動画')}】</MovieInfoFree>
        <MovieTitle>{t('基本の調理道具')}</MovieTitle>
        <VideoPlayer
          url={`${asset_endpoint}/movies/tool_${locale()}/master.m3u8`}
          thumbUrl={require(`../../images/manabi/${t('free_movie_tool')}`)}
          autoPlay={false}/>
      </MovieItemCombined>
    </FreeMovieSectionCombined>
  );
};

export default withI18n()(FreeMovies);