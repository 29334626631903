import styled from '../../helpers/styled';
import ErrorInput from '../ErrorInput';

export const Container = styled.div`
    width: 768px;
    max-width: 100%;
    margin: 0 auto;
    padding: 16px;
    text-align: center;
`;

export const ErrorMessage = styled.p`
    font-size: 16px;
    font-weight: 700;
    color: crimson;
`;

export const Description = styled.p`
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px;
`;

export const InputField = styled(ErrorInput)`
    display: block;
    width: 100%;
    margin-bottom: 24px;
    font-size: 16px;
    padding: 4px;
`;

export const SubmitButton = styled.button`
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
    transition: all 0.2s;
    font-size: 20px;
    padding: 8px 32px;
    
    :hover {
      color: #FFFFFF;
      background-color: #000000;
    }
`;