import React from 'react';
import i18nProps from '../../helpers/i18n';
import { BulkPrice } from 'learning-redux-store/dist/data/bulk_price';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import SetPriceItem from './SetPriceItem';

const Section = styled.section`
  background-color: white;
  padding: 32px 16px;
`;

const Header = styled.h5`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 32px;
`;

interface Props extends i18nProps {
  bulk_price: BulkPrice;
}

const SetPriceBox: React.FC<Props> = ({ t, bulk_price }) => {
  return (
    <Section>
      <Header>{t('“日本料理“を極めるためのお得なセットをご利用下さい。')}</Header>
      <SetPriceItem price={bulk_price.home_data}
                    campaign={bulk_price.home_campaign}
                    title={t('「菊乃井 本店」プロデュースの全レシピ')}
                    description={t('各有料コンテンツはレシピ単位で購入します。')}
                    target={1}
                    index={'01'}/>
      <SetPriceItem price={bulk_price.all_data}
                    campaign={bulk_price.all_campaign}
                    title={t('プロンズ・カリキュラム＋レシピ動画')}
                    description={t('各有料コンテンツは、レッスン単位、レシピ単位で購入します。')}
                    target={-1}
                    index={'02'}/>
    </Section>
  );
};

export default withI18n()(SetPriceBox);
