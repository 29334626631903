import styled from "../helpers/styled";
import mediaQuery from "styled-media-query";

export const mediaNote = mediaQuery.lessThan("large");
export const mediaTablet = mediaQuery.lessThan("medium");
export const mediaMobile = mediaQuery.lessThan("small");

export const combileNoteStyle = (Component, extend) => {
    return styled(Component)`
    ${mediaNote`
      ${extend}
    `};
  `;
};

export const combineTabletStyle = (Component, extend) => {
    return styled(Component)`
    ${mediaTablet`
      ${extend}
    `};
  `;
};

export const combineMobileStyle = (Component, extend) => {
    return styled(Component)`
    ${mediaMobile`
      ${extend}
    `};
  `;
};

export const combineTabletMobileStyle = (Component, extendTablet, extendMobile) => {
    return styled(Component)`
    ${mediaTablet`
      ${extendTablet}
    `};
    ${mediaMobile`
      ${extendMobile}
    `};
  `;
};