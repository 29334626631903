import * as React  from 'react';
import styled from '../helpers/styled';

const ErrorText = styled.span`
    margin-bottom: 8px; 
    color: red;
    display: inline-block;
`;

interface Props extends React.TextareaHTMLAttributes<{}> {
  error: { errors: object | null } | null;
}

const ErrorTextArea: React.FC<Props> = (props) => {

  if (props.error) {
    const { errors } = props.error;

    if (errors && props.name && errors.hasOwnProperty(props.name) && errors[props.name]) {
      return (
        <div>
          <textarea {...props} style={{ ...props.style, marginBottom: 0 }}/>
          <ErrorText>{errors[props.name]}</ErrorText>
        </div>
      );
    }
  }
  return <textarea {...props}/>;
};

export default ErrorTextArea;