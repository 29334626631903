import * as React from 'react';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import { HashLink } from '../../helpers/react-router-hash-link';

const Section = styled.section`
  flex: 1;
  margin: 24px auto;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 16px;
`;

interface Props extends i18nProps {

}

const RegisterInformation: React.FC<Props> = ({ t }) => {
  return (
    <Section>
      <h3>
        {t('Japanese Cuisine 会員登録')}
      </h3>
      <p>
        {t('Japanese CuisineのログインIDを入力してください。')}<br/>
        {t('ログインまたは会員登録が完了すると、下記の機能を使用できます。')}
      </p>
      <ul>
        <li>{t('MANABI（E-ラーニング）カリキュラムの購入・閲覧')}</li>
        <li>{t('DOUGU ONLINE SHOPでのご購入')}</li>
      </ul>
      <p>
        {t('会員登録にあたっての当サイトのポリシーは下記をご確認ください。')}
      </p>
      <ul>
        <li>
          <HashLink to="/privacy">
            {t('プライバシーポリシー')}
          </HashLink>
        </li>
        <li>
          <HashLink to='/terms'>
            {t('Japanese Cuisine ご利用規約')}
          </HashLink>
        </li>
        <li>
          <HashLink to='/law'>
            {t('特定商取引法に基づく表記')}
          </HashLink>
        </li>
      </ul>
    </Section>
  );
};

export default withI18n()(RegisterInformation);