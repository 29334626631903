import React from 'react';
import { withI18n } from 'react-i18next';
import styled from '../../helpers/styled';
import FontAwesome from 'react-fontawesome';
import { HashLink } from '../../helpers/react-router-hash-link';
import i18nProps from '../../helpers/i18n';

const FooterSection = styled.footer`
    width: 832px;
    max-width: 100%;
    padding: 0 8px;
    margin: 0 auto;
`;

const FooterBackLink = styled(HashLink)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-bottom: 24px;
    text-align: center;
    background-color: #000000;
    border: 1px solid #000000;
    color: white;
    text-decoration: none;
    transition: all 0.2s;
    
    :hover, :active, :focus {
      text-decoration: none;
      background-color: #FFFFFF;
      color: #000000;
    }
`;

const BackLinkSubTitle = styled.span`
    font-size: 14px;
    letter-spacing: 0.1em;
`;

const BackLinkTitle = styled.span`
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-left: 16px;
    margin-right: 16px;
`;

const BackLinkMore = styled.span`
    font-size: 12px;
    letter-spacing: 0.2em;
`;

const BackLinkArrow = styled(FontAwesome)`
    font-size: 24px !important;
    margin-left: 16px;
`;

const FooterCopyRight = styled.div`
    font-weight: 200;
    font-size: 12px;
    text-align: center;
    padding: 8px 8px 32px;
`;

const Footer: React.FC<i18nProps> = ({t}) => {
  return (
    <FooterSection>
      <FooterBackLink to="/manabi#curriculum">
        <BackLinkSubTitle>{t('日本料理を学ぶ')}</BackLinkSubTitle>
        <BackLinkTitle>MANABI</BackLinkTitle>
        <BackLinkMore>move on</BackLinkMore>
        <BackLinkArrow name="angle-right"/>
      </FooterBackLink>
      <FooterCopyRight>
        Copyright 2019- Blue Magic Inc. All Right Reserved.
      </FooterCopyRight>
    </FooterSection>
  );
};

export default withI18n()(Footer);