import * as React from 'react';
import { News } from 'learning-redux-store';
import i18nProps from '../../helpers/i18n';
import { withI18n } from 'react-i18next';
import styled, { css } from '../../helpers/styled';
import locale from '../../helpers/locale';
import moment from 'moment';
import nl2br from '../../helpers/nl2br';
import { asset_endpoint } from '../../config/app';
import { combineMobileStyle } from '../../helpers/mediaQuery';

const ModalDrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  overflow: hidden;
  
  animation-name:fade-in;
  animation-duration:0.2s;
  animation-timing-function: ease-out;
  animation-delay:0s;
  animation-iteration-count:1;
  animation-direction:normal;
  animation-fill-mode: forwards;
  
  @keyframes fade-in {
    0% {opacity: 0}
    100% {opacity: 1}
  }
`;

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  margin: auto;
  width: 768px;
  max-width: 90%;
  max-height: 80%;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 16px;
  overflow-y: auto;
`;

const NewsImageBox = styled.div`
  width: 100%;
  text-align: center;
`;

const NewsImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const NewsPublished = styled.p`
  font-weight: 400;
`;

const NewsTitle = styled.h4`
  font-weight: 600;
`;

const NewsBody = styled.p`
  font-weight: 400;
`;

const CloseIconPc = styled.span`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.6;
  cursor: pointer;
  
  &:hover {
    opacity: 1;
  }
  &:before, &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #FFFFFF;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const CloseIconMobile = css`
  top: 8px;
  right: 8px;
`;

const CloseIcon = combineMobileStyle(CloseIconPc, CloseIconMobile);

interface Props extends i18nProps {
  news: News | null;
  onClose: () => void;
}

const NewsDetail: React.FC<Props> = ({ t, news, onClose }) => {
  if (news === null) {
    return <></>;
  }
  let published: string;
  if (locale() === 'en') {
    published = moment(news!.published_at).format('MMMM. DD, YYYY');
  } else {
    published = moment(news!.published_at).format('YYYY/MM/DD');
  }
  return (
    <ModalDrop onClick={onClose} id={'modal'}>
      <CloseIcon onClick={onClose}/>
      <Modal onClick={(e) => e.stopPropagation()}>
        {(() => {
          if (news!.image) {
            return (
              <NewsImageBox>
                <NewsImage src={`${asset_endpoint}/uploads/news_photos/${news!.image}`}/>
              </NewsImageBox>
            );
          } else {
            return <></>;
          }
        })()}
        <NewsPublished>{published}</NewsPublished>
        <NewsTitle>{news!.title}</NewsTitle>
        <NewsBody>{nl2br(news!.body)}</NewsBody>
      </Modal>
    </ModalDrop>
  );
};

export default withI18n()(NewsDetail);