import React from 'react';
import { withI18n } from 'react-i18next';
import styled, { css } from '../../helpers/styled';
import { combineTabletStyle } from '../../helpers/mediaQuery';
import i18nProps from '../../helpers/i18n';

const FooterSection = styled.footer`

`;

const FooterBox = styled.div`
    padding: 48px 8px 0;
`;

const FooterFlex = styled.div`
    display: flex;
    width: 848px;
    max-width: 100%;
    margin: 0 auto;
    padding: -16px 0;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const CooperationHeader = styled.h5`
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.2em;
    margin-top: 32px;
    margin-bottom: 24px;
    text-align: center;
`;

const CooperationLink = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    letter-spacing: 0.1em;
    width: 400px;
    max-width: 100%;
    flex: 1;
    height: 80px;
    color: #000000;
    text-decoration: none;
    border: 1px solid #000000;
    background-color: #FFFFFF;
    transition: all 0.2s;
    cursor: pointer;
    margin: 16px 12px;
    
    :hover {
      color: #FFFFFF;
      background-color: #000000;
      text-decoration: none;
    }
`;

const CooperationLinkMobile = css`
    margin: 8px !important;
`;

const CooperationLinkCombine = combineTabletStyle(CooperationLink, CooperationLinkMobile);

const FooterDescription = styled.p`
    width: 832px;
    max-width: 100%;
    margin: 16px auto;
`;

const FooterDescriptionMobile = css`
    width: 100%;
    padding: 0 16px;
`;

const FooterDescriptionCombined = combineTabletStyle(FooterDescription, FooterDescriptionMobile);

const FooterCopyRight = styled.div`
    font-weight: 200;
    font-size: 12px;
    text-align: center;
    padding: 8px 8px 16px;
`;

const Footer: React.FC<i18nProps> = ({t}) => {
  return (
    <FooterSection>
      <FooterBox>
        <CooperationHeader>{t('Production')}</CooperationHeader>
        <FooterFlex>
          <CooperationLinkCombine href='https://culinary-academy.jp/english' target='_blank' rel="noopener noreferrer">
            {t('日本料理アカデミー')}
          </CooperationLinkCombine>
        </FooterFlex>
        <FooterDescriptionCombined>
          {t('日本料理jpは、日本料理を中心に、世界のシェフとの交流活動や、地域に ねざした食育活動などを行うNPO法人「日本料理アカデミー」が協賛して運営しています。')}
          {t('単なる調理技能の習得にとどまらず、食を通じて世界の文化の発展に貢献できる情報・教育を目指しています。')}
        </FooterDescriptionCombined>
      </FooterBox>
      <FooterCopyRight>
        Copyright 2019- Blue Magic Inc. All Right Reserved.
      </FooterCopyRight>
    </FooterSection>
  );
};

export default withI18n()(Footer);