import * as React from 'react';
import {
  BackLink,
  BackLinkBox,
  Information, OrderBox,
  OrderButton,
  Section,
  SectionHeader,
  TotalPrice
} from '../components/Cart/Elements';
import CartProps from 'learning-redux-store/dist/props/cart';
import { AppState, CartState, requestClearCart, requestDeleteCart, requestGetCart } from 'learning-redux-store';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import i18nProps from '../helpers/i18n';
import CartItem from '../components/Cart/CartItem';
import Header from '../components/Header';
import { Helmet } from 'react-helmet-async';
import loginRequired from '../middleware/loginRequired';

interface Props extends CartProps, i18nProps {
  cart: CartState;
}

class Cart extends React.Component<Props> {

  componentDidMount(): void {
    this.props.requestGetCart();
  }

  render() {
    const { t, cart } = this.props;

    let items: JSX.Element[] = [];
    let totalPrice = 0;
    cart.items.forEach(item => {
      totalPrice += item.price;
      items.push(<CartItem item={item} key={item.id} onDelete={this.onDeleteItem}/>);
    });

    return (
      <div>
        <Helmet>
          <title>{t('ショッピングカート')} | Japanese Cuisine.com</title>
        </Helmet>
        <Header subTitle={t('カート')}/>
        <Section>
          <SectionHeader>{t('ショッピングカート')}</SectionHeader>
          {cart.items.length === 0 ? (
            <Information>
              {t('ショッピングカートの中に商品がありません。')}
            </Information>
          ) : (
            <Information>
              {t('ショッピングカートの中には以下の商品が入っています。')}<br/>
              {t('内容をご確認の上、ご注文手続きにお進みください。')}
            </Information>
          )}
          {items}
          {cart.items.length !== 0 && (
            <TotalPrice>
              {t('ご注文金額合計')} <span>{t('¥')}{totalPrice.toLocaleString()}</span> + {t('税')}
            </TotalPrice>
          )}
          <BackLinkBox>
            <BackLink to={'/manabi#curriculum'}>{t('カリキュラム一覧に戻る')}</BackLink>
          </BackLinkBox>
          {cart.items.length !== 0 && (
            <OrderBox>
              <OrderButton to={'/payment'}>{t('ご注文手続きへ進む')}</OrderButton>
            </OrderBox>
          )}
        </Section>
      </div>
    );
  }

  onDeleteItem = (id: number) => {
    this.props.requestDeleteCart(id);
  };
}

export default connect(
  (state: AppState) => ({
    cart: state.cart
  }),
  { requestGetCart, requestDeleteCart, requestClearCart }
)(withI18n()(loginRequired(Cart)));