import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

export default function loginRequired(WrappedComponent) {

  class loginRequiredComponent extends WrappedComponent {

    state = {
      isAuthenticated: false
    };

    static getDerivedStateFromProps(nextProps, prevProps) {
      if (!localStorage.getItem('user_session')) {
        return { isAuthenticated: false };
      } else {
        if (WrappedComponent.getDerivedStateFromProps !== undefined) {
          const state = WrappedComponent.getDerivedStateFromProps(nextProps, prevProps);
          return { ...state, isAuthenticated: true };
        } else {
          return { isAuthenticated: true };
        }
      }
    }

    render() {
      if (this.state.isAuthenticated) {
        return super.render();
      }
      if (this.props.location.search) {
        const search = encodeURIComponent(this.props.location.search);
        return <Redirect to={`/auth/login?prev=${this.props.location.pathname}&additional=${search}`}/>;
      } else {
        return <Redirect to={`/auth/login?prev=${this.props.location.pathname}`}/>;
      }
    }

  }

  return connect(
    state => ({
      user: state.user
    }),
    {}
  )(loginRequiredComponent);
}