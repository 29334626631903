const fallbackLocale = 'en';

const availableLocale = [
    'en',
    'zh',
    'ja'
];

const locale = () => {
    const locale = localStorage.getItem("set-locale");
    let setLocale;
    if (locale === undefined || locale === null || locale === '') {
        setLocale = require('browser-locale')().slice(0, 2);
    } else {
        setLocale = locale;
    }
    // 有効な言語でなければ英語とする
    if (availableLocale.includes(setLocale)) {
        return setLocale;
    } else {
        return fallbackLocale;
    }
};

export default locale;