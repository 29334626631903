import * as React from 'react';
import { Category } from 'learning-redux-store';
import styled from '../helpers/styled';
import { withI18n } from 'react-i18next';
import i18nProps from '../helpers/i18n';
import { HashLink } from '../helpers/react-router-hash-link';
import CampaignPriceLabel from './CampaignPriceLabel';

const Section = styled.div`
  width: 630px;
  max-width: 100%;
  position: relative;
  margin: 24px auto;
  background-color: #FFFFFF;
  padding: 16px 24px;
`;

const PaymentBox = styled(HashLink)`
  margin-top: 16px;
  border: 1px solid #000000;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  padding: 8px;
  
  &:hover, &:active, &:focus {
    color: #000000;
    background-color: #FFFFFF;
    text-decoration: none;
  }
`;

const Information = styled.p`
  text-align: center;
`;

const BannerText = styled.p`
  text-align: center;
  background-color: #000000;
  color: #FFFFFF;
`;

const PriceText = styled.p`
  text-align: center;
  margin-bottom: 0;
`;

const PriceBig = styled.span`
  font-size: 2em;
  
  &:last-of-type {
    margin: 0 8px 0 0;
  }
  
  &:first-of-type {
    margin: 0 8px;
  }
`;

interface Props extends i18nProps {
  category: Category;
  className?: string;
  isLogin: boolean;
}

const PaymentInfo: React.FC<Props> = ({ category, t, className, isLogin }) => {
  return (
    <Section className={className}>
      <Information>
        {t('コンテンツは90日間XXX円で同レッスン内の動画を見ることができます。', { price: category.price.toLocaleString() })}
      </Information>
      <BannerText>
        {t('ご利用料金')}
      </BannerText>
      <PriceText>
        {t('同一レッスン内動画')}<br/>
        <PriceBig>{t('90日間')}</PriceBig>
        <PriceBig>{t('￥')}<CampaignPriceLabel price={category.price}
                                              campaign_price={category.campaign_price}
                                              campaign_rate={category.campaign_rate}/></PriceBig>
        {t('見放題')}
      </PriceText>
      {isLogin ? (
        <PaymentBox to={`/cart/${category.purchase_unit_id}`}>
          {t('セット購入へ')}
        </PaymentBox>
      ) : (
        <PaymentBox to={`/auth/login?prev=/categories/${category.id}`}>
          {t('セット購入へ')}
        </PaymentBox>
      )}
    </Section>
  );
};

export default withI18n()(PaymentInfo);
