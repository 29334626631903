import React from 'react';
import styled from '../../helpers/styled';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';

const CurriculumHeader = styled.h3`
    font-family: 'Noto Serif JP', 'Noto Serif SC', serif;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0.2em;
    font-weight: 200;
    margin-top: 144px;
    margin-bottom: 120px;
`;

const StartAnchor = styled.div`
    width: 100%;
    height: 1px;
`;

const Start: React.FC<i18nProps> = ({ t }) => {
  return (
    <div>
      <StartAnchor id={'start'}/>
      <CurriculumHeader>{t('さぁ、カリキュラムをはじめましょう。')}</CurriculumHeader>
    </div>
  );
};

export default withI18n()(Start);