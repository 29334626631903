import * as React from 'react';
import UserProps from 'learning-redux-store/dist/props/user';
import i18nProps from '../helpers/i18n';
import Header from '../components/Header';
import { withI18n } from 'react-i18next';
import styled, { css } from '../helpers/styled';
import ErrorInput from '../components/ErrorInput';
import { AppState, requestUpdateUser, requestUser, sendNotification, UserState } from 'learning-redux-store';
import { connect } from 'react-redux';
import Footer from '../components/Instructor/Footer';
import NotificationProps from 'learning-redux-store/dist/props/notification';
import { RouteComponentProps } from 'react-router';
import loginRequired from '../middleware/loginRequired';
import { combineMobileStyle } from '../helpers/mediaQuery';

const UpdateFormPc = styled.div`
  width: 416px;
  max-width: 100%;
  margin: 32px auto;
  padding: 24px 24px 48px;
  background-color: #F5F5F5;
`;

const UpdateFormMobile = css`
  padding: 24px 8px 48px;
`;

const UpdateForm = combineMobileStyle(UpdateFormPc, UpdateFormMobile);

const Input = styled(ErrorInput)`
  display: block;
  margin-bottom: 24px;
  width: 100%;
  border: 1px solid #a1a1a1;
  background-color: transparent;
`;

const Submit = styled.button`
  margin-top: 24px;
  background-color: #000000;
  color: #FFFFFF;
  border: 1px solid #000000;
  transition: 0.2s all;
  display: block;
  padding: 4px 16px;
  width: 100%;
  
  &:hover, &:active, &:focus {
    color: #000000;
    background-color: #FFFFFF;
  }
  
  &:disabled {
    background-color: #616161;
    border-color: #616161;
  }
`;

interface Prop extends UserProps, i18nProps, NotificationProps, RouteComponentProps {
  user: UserState;
}

class UpdatePassword extends React.Component<Prop> {

  state = {
    password: '',
    password_confirmation: ''
  };

  componentDidMount(): void {
    this.props.requestUser();
  }

  onChangeField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };


  render() {
    const { t, user } = this.props;
    return (
      <section>
        <Header subTitle={t('パスワードの変更')}/>
        <UpdateForm>

          <label htmlFor={'password'}>{t('パスワード')}</label>
          <Input
            error={user.error}
            id={'password'}
            name='password'
            type={'password'}
            onChange={this.onChangeField}
            value={this.state.password}/>
          <label htmlFor={'password_confirmation'}>{t('パスワード（確認）')}</label>
          <Input
            error={user.error}
            id={'password_confirmation'}
            name='password_confirmation'
            type={'password'}
            onChange={this.onChangeField}
            value={this.state.password_confirmation}/>
          <Submit onClick={this.onUpdate} disabled={user.isProcessing}>{t('更新')}</Submit>
        </UpdateForm>
        <Footer/>
      </section>
    );
  }

  onUpdate = () => {
    const { t } = this.props;
    if (this.state.password !== this.state.password_confirmation) {
      this.props.sendNotification({
        message: t('パスワードの確認入力が異なります。'),
        level: 'error'
      });
      return;
    }
    this.props.requestUpdateUser({
      callback: this.onSuccessUpdate,
      updateData: {
        password: this.state.password
      }
    });
  };

  onSuccessUpdate = () => {
    const { t, history } = this.props;
    this.props.sendNotification({
      message: t('アカウント情報を変更しました。'),
      level: 'success'
    });
    history.push('/user');
  };
}

export default connect(
  (state: AppState) => ({
    user: state.user
  }),
  { requestUser, requestUpdateUser, sendNotification }
)(withI18n()(loginRequired(UpdatePassword)));