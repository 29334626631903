import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import {
  AppState,
  requestContact,
  resetContact,
  sendNotification,
  ContactProps,
  NotificationProps,
  ContactState
} from 'learning-redux-store';
import ContactForm from '../components/Contact/ContactForm';
import Header from '../components/Header';
import Footer from '../components/Instructor/Footer';
import i18nProps from '../helpers/i18n';
import { Helmet } from 'react-helmet-async';
import styled from '../helpers/styled';
import { HashLink } from '../helpers/react-router-hash-link';

const Information = styled.p`
  width: 728px;
  max-width: 100%;
  margin: 24px auto 0;
  padding: 8px;
`;

interface Props extends ContactProps, NotificationProps, RouteComponentProps, i18nProps {
  contact: ContactState;
}

class Contact extends React.Component<Props> {

  componentDidMount() {
    this.props.resetContact();
  }

  render() {
    const { contact, t } = this.props;

    return (
      <div>
        <Helmet title={`${t('お問い合わせ')} | Japanese Cuisine.com`}/>
        <Header subTitle={t('お問い合わせ')}/>
        <ContactForm onSubmit={this.onSubmit.bind(this)} error={contact.error}/>
        <Information>
          {t('ご入力いただいた内容は当社の「プライバシーポリシー」に従って適切に取り扱われます。')}<br/>
          {t('ご入力いただいた個人情報は、お問い合わせ内容への対応のために使用し、お問い合わせに関する回答以外の目的には利用致しません。')}<br/>
          <HashLink to={'/privacy'}>{t('プライバシーポリシー')}</HashLink>
        </Information>
        <Footer/>
      </div>
    );
  }

  onSubmit = (values) => {
    this.props.requestContact({ request: values, callback: this.onSuccessContact });
  };

  onSuccessContact = () => {
    const { t } = this.props;
    this.props.sendNotification({ message: t('お問い合わせを送信しました。') });
    this.props.history.goBack();
  };
}

export default connect(
  (state: AppState) => ({
    contact: state.contact
  }),
  { requestContact, sendNotification, resetContact }
)(withI18n()(Contact));