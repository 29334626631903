import * as React from 'react';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';
import styled from '../../helpers/styled';

const Section = styled.footer`
  margin: 16px;
  padding: 16px;
  border: 1px solid #212121;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 768px;
  max-width: 100%;
  margin-top: 8px;
  
  footer.long & {
    width: 950px;
  }
`;

const Title = styled.div`
  width: 11em;
  
  footer.long & {
    width: 17em;
  }
`;

const Body = styled.div`
  flex: 1;
  min-width: 200px;
`;

const Footer: React.FC<i18nProps> = ({ t }) => {
  const long = t('ガイドライン制定').length > 11;

  return (
    <Section className={long ? "long" : ""}>
      <Box>
        <Title>●{t('制度名称')}</Title>
        <Body>{t('「海外における日本料理の調理技能認定制度」に基づく「ブロンズ認定」')}</Body>
      </Box>
      <Box>
        <Title>●{t('ガイドライン制定')}</Title>
        <Body>{t('日本の農林水産省')}</Body>
      </Box>
      <Box>
        <Title>●{t('認定団体')}</Title>
        <Body>{t('農林水産省認定運用管理団体「特定非営利活動法人日本料理アカデミー」')}</Body>
      </Box>
      <Box>
        <Title>●{t('運営協力')}</Title>
        <Body>{t('株式会社ブルーマジック（Japanese Cuisine com）')}</Body>
      </Box>
    </Section>
  );
};

export default withI18n()(Footer);