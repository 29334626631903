import styled from '../../helpers/styled';

const SectionHeader = styled.h2`
    font-size: 30px;
    letter-spacing: 0.3em;
    font-weight: bold;
    text-align: center;
    
    &::after {
      content: '';
      display: block;
      width: 12em;
      max-width: 100%;
      border-bottom: 4px solid crimson;
      margin: 8px auto 0;
    }
`;

export default SectionHeader;