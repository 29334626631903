import React from 'react';
import styled from '../../helpers/styled';
import AspectImage from '../AspectImage';
import { asset_endpoint } from '../../config/app';
import nl2br from '../../helpers/nl2br';
import { Question } from 'learning-redux-store';

const ItemArticle = styled.article`
    margin: 16px 8px 24px;
`;

const ItemTitle = styled.h4`
    font-size: 20px;
    font-weight: 700;
    :before {
      content: "Q. ";
    }
`;

const ItemBody = styled.p`

`;

const ItemImageBox = styled.a`
    width: 200px;
    display: block;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    padding: 4px;
`;

interface Props {
  question: Question;
}

const QuestionItem: React.FC<Props> = ({ question }) => {

  const Image = (() => {
    if (question.image) {
      const imageUrl = `${asset_endpoint}/uploads/question_photos/${question.image}`;
      return (
        <ItemImageBox href={imageUrl} target='_blank' rel="noopener noreferrer">
          <AspectImage src={imageUrl}/>
        </ItemImageBox>
      );
    } else {
      return null;
    }
  })();

  return (
    <ItemArticle>
      <ItemTitle>{question.title}</ItemTitle>
      {Image}
      <ItemBody>{nl2br(question.body)}</ItemBody>
    </ItemArticle>
  );
};

export default QuestionItem;