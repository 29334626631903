import * as React from 'react';
import i18nProps from '../helpers/i18n';
import { withI18n } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import styled from '../helpers/styled';
import Header from '../components/Header';

const Body = styled.div`
  width: 1140px;
  max-width: 100%;
  padding: 16px;
  margin: 0 auto;
`;

interface Props extends i18nProps {

}

const Terms: React.FC<Props> = ({ t }) => {
  return (
    <section>
      <Helmet title={t('ご利用規約') + ' | Japanese Cuisine.com'}/>
      <Header title={t('ご利用規約')}/>
      <Body>
        <h1>Japanese Cuisine.com {t('ご利用規約')}</h1>
        <p>{t('この利用規約（以下，「本規約」といいます。）は，株式会社ブルーマジック（以下，「当社」といいます。）がこのウェブサイト上で提供するオンラインショップ（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。')}</p>

        <h2>{t('第1条（適用）')}</h2>
        <ol>
          <li>{t('本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。')}</li>
          <li>{t('当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下,「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。')}</li>
          <li>{t('本規約の定めが前項の個別規定の定めと矛盾する場合には，個別規定において特段の定めなき限り，個別規定の定めが優先されるものとします。')}</li>
        </ol>

        <h2>{t('第2条（利用登録）')}</h2>
        <ol>
          <li>{t('本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれに対する承認を登録希望者に通知することによって，利用登録が完了するものとします。')}</li>
          <li>
            {t('当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。')}
            <ol>
              <li>{t('利用登録の申請に際して虚偽の事項を届け出た場合')}</li>
              <li>{t('本規約に違反したことがある者からの申請である場合')}</li>
              <li>{t('その他，当社が利用登録を相当でないと判断した場合')}</li>
            </ol>
          </li>
        </ol>

        <h2>{t('第3条（ユーザーIDおよびパスワードの管理）')}</h2>
        <ol>
          <li>{t('ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを管理するものとします。')}</li>
          <li>{t('ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。')}</li>
          <li>{t('ユーザーID及びパスワードが第三者に使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。')}</li>
        </ol>

        <h2>{t('第4条（売買契約）')}</h2>
        <ol>
          <li>{t('本サービスにおいては，ユーザーが当社に対して購入の申し込みをし，これに対して当社が当該申し込みを承諾した旨の通知をすることによって売買契約が成立するものとします。なお，当該商品の所有権は，当社が商品を配送業者に引き渡したときに，ユーザーに移転するものとします。')}</li>
          <li>
            {t('当社は，ユーザーが以下のいずれかの事由に該当する場合には，当該ユーザーに事前に通知することなく，前項の売買契約を解除することができるものとします。')}
            <ol>
              <li>{t('ユーザーが本規約に違反した場合')}</li>
              <li>{t('届け先不明や長期の不在のため商品の配送が完了しない場合')}</li>
              <li>{t('その他当社とユーザーの信頼関係が損なわれたと認める場合')}</li>
            </ol>
          </li>
          <li>{t('本サービスに関する決済方法，配送方法，購入の申し込みのキャンセル方法，または返品方法等については，別途当社が定める方法によります。')}</li>
        </ol>

        <h2>{t('第5条（知的財産権）')}</h2>
        <p>{t('本サービスによって提供される商品写真その他のコンテンツ（以下「コンテンツ」といいます）の著作権又はその他の知的所有権は,当社及びコンテンツ提供者などの正当な権利者に帰属し,ユーザーは,これらを無断で複製,転載,改変,その他の二次利用をすることはできません。')}</p>

        <h2>{t('第6条（禁止事項）')}</h2>
        <p>{t('ユーザーは，本サービスの利用にあたり，以下の行為をしてはならないものとします。')}</p>
        <ol>
          <li>{t('法令または公序良俗に違反する行為')}</li>
          <li>{t('犯罪行為に関連する行為')}</li>
          <li>{t('本サービスに含まれる著作権，商標権その他の知的財産権を侵害する行為')}</li>
          <li>{t('当社のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為')}</li>
          <li>{t('本サービスによって得られた情報を商業的に利用する行為')}</li>
          <li>{t('当社のサービスの運営を妨害するおそれのある行為')}</li>
          <li>{t('不正アクセスをし，またはこれを試みる行為')}</li>
          <li>{t('他のユーザーに関する個人情報等を収集または蓄積する行為')}</li>
          <li>{t('他のユーザーに成りすます行為')}</li>
          <li>{t('当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為')}</li>
          <li>{t('その他，当社が不適切と判断する行為')}</li>
        </ol>

        <h2>{t('第7条（本サービスの提供の停止等）')}</h2>
        <ol>
          <li>
            {t('当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。')}
            <ol>
              <li>{t('本サービスにかかるコンピュータシステムの保守点検または更新を行う場合')}</li>
              <li>{t('地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合')}</li>
              <li>{t('コンピュータまたは通信回線等が事故により停止した場合')}</li>
              <li>{t('その他，当社が本サービスの提供が困難と判断した場合')}</li>
            </ol></li>
          <li>{t('当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。')}</li>
        </ol>

        <h2>{t('第8条（利用制限および登録抹消）')}</h2>
        <ol>
          <li>{t('当社は，以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。')}
            <ol>
              <li>{t('本規約のいずれかの条項に違反した場合')}</li>
              <li>{t('登録事項に虚偽の事実があることが判明した場合')}</li>
              <li>{t('決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合')}</li>
              <li>{t('料金等の支払債務の不履行があった場合')}</li>
              <li>{t('当社からの連絡に対し，一定期間返答がない場合')}</li>
              <li>{t('本サービスについて，最終の利用から一定期間利用がない場合')}</li>
              <li>{t('その他，当社が本サービスの利用を適当でないと判断した場合')}</li>
            </ol></li>
          <li>{t('当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。')}</li>
        </ol>

        <h2>{t('第9条（退会）')}</h2>
        <p>{t('ユーザーは，所定の退会手続により，本サービスから退会できるものとします。')}</p>

        <h2>{t('第10条（保証の否認および免責事項）')}</h2>
        <ol>
          <li>{t('当社は,本サービスに事実上または法律上の瑕疵（安全性,信頼性,正確性,完全性,有効性,特定の目的への適合性,セキュリティなどに関する欠陥,エラーやバグ,権利侵害などを含みます。）がないことを保証するものではありません。')}</li>
          <li>{t('当社は,本サービスによってユーザーに生じたあらゆる損害について,一切の責任を負いません。ただし,本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合,この免責規定は適用されませんが,この場合であっても,当社は,当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し,または予見し得た場合を含みます。）について一切の責任を負いません。')}</li>
          <li>{t('当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。')}</li>
        </ol>

        <h2>{t('第11条（サービス内容の変更等）')}</h2>
        <p>{t('当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。')}</p>

        <h2>{t('第12条（利用規約の変更）')}</h2>
        <p>{t('当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。')}</p>

        <h2>{t('第13条（個人情報の取扱い）')}</h2>
        <p>{t('当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。')}</p>

        <h2>{t('第14条（通知または連絡）')}</h2>
        <p>{t('ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。')}</p>

        <h2>{t('第15条（権利義務の譲渡の禁止）')}</h2>
        <p>{t('ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。')}</p>

        <h2>{t('第16条（準拠法・裁判管轄）')}</h2>
        <ol>
          <li>{t('本規約の解釈にあたっては，日本法を準拠法とします。なお，本サービスに関しては，国際物品売買契約に関する国際連合条約の適用を排除するものとします。')}</li>
          <li>{t('本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。')}</li>
        </ol>

        <p className="tR">{t('以上')}</p>
      </Body>
    </section>
  );
};

export default withI18n()(Terms);