import * as React from 'react';
import styled, { css } from '../../helpers/styled';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';
import { NavHashLink } from '../../helpers/react-router-hash-link';
import { dougu_url } from '../../config/app';
import locale from '../../helpers/locale';
import { combineMobileStyle } from '../../helpers/mediaQuery';

const SectionPc = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 10%;
`;

const SectionMobile = css`
  padding: 0 16px;
`;

const Section = combineMobileStyle(SectionPc, SectionMobile);

const ItemPc = styled.div`
  width: 50%;
  
  &:first-child {
    padding-right: 8px;
  }
  
  &:last-child {
    padding-left: 8px;
  }
`;

const ItemMobile = css`
  width: 100%;
  
  &:first-child {
    padding-right: 0;
  }
  
  &:last-child {
    margin-top: 8px;
    padding-left: 0;
  }
`;

const Item = combineMobileStyle(ItemPc, ItemMobile);

const ItemFull = styled.div`
  width: 100%;
  margin-top: 16px;
  
  &:first-of-type {
    margin-top: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  max-height: 100%;
`;

const CampaignBanner: React.FC<i18nProps> = ({ t }) => {

  const campaignBannerImage = require(`../../images/top/${t('campaign_banner')}`);
  const douguOpenBannerImage = require(`../../images/top/${t('dougu_open_banner')}`);
  const recipeBannerImage = require(`../../images/top/${t('recipe_top_banner_png')}`);

  if (locale() === 'ja') {
    return (
      <Section>
        <ItemFull>
          <NavHashLink to='/manabi#curriculum'>
            <Image src={campaignBannerImage}/>
          </NavHashLink>
        </ItemFull>
        <ItemFull>
          <NavHashLink to='/recipe#top'>
            <Image src={recipeBannerImage}/>
          </NavHashLink>
        </ItemFull>
      </Section>
    );
  } else {
    return (
      <Section>
        <Item>
          <NavHashLink to='/manabi#curriculum'>
            <Image src={campaignBannerImage}/>
          </NavHashLink>
        </Item>
        <Item>
          <a href={`${dougu_url}/locale/${locale()}?top=1`}>
            <Image src={douguOpenBannerImage}/>
          </a>
        </Item>
        <ItemFull>
          <NavHashLink to='/recipe#top'>
            <Image src={recipeBannerImage}/>
          </NavHashLink>
        </ItemFull>
      </Section>
    );
  }
};

export default withI18n()(CampaignBanner);
