import * as React from 'react';
import { withI18n } from 'react-i18next';
import i18nProps from '../../helpers/i18n';
import { Category, Movie } from 'learning-redux-store';
import styled from '../../helpers/styled';

const Section = styled.section`
  width: 768px;
  max-width: 100%;
  padding: 8px;
  margin: 0 auto;
`;

const Text = styled.p`
  margin-top: 2px;
`;

const Button = styled.button`
  background-color: #000000;
  border: 1px solid #000000;
  margin-top: 24px;
  outline: none;
  appearance: none;
  color: #FFFFFF;
  padding: 8px 24px;
  transition: 0.2s all;
  
  &:hover, &:active, &:focus {
    background-color: #FFFFFF;
    color: #000000;
  }
`;

interface Props extends i18nProps {
  price: number | null;
  itemPrice: number;
  isHidden: boolean;
  onSubmit: () => void;
}

const ConfirmForm: React.FC<Props> = ({ t, itemPrice, price, isHidden, onSubmit }) => {
  if (isHidden || price === null || price === undefined) {
    return null;
  }
  return (
    <Section>
      <Text><strong>{t('商品価格')}</strong>: {itemPrice}{t('円')}</Text>
      {price !== 0 && (
        <Text><strong>{t('割引額')}</strong>: {price}{t('円')}</Text>
      )}
      <Text><strong>{t('総計')}</strong>: {itemPrice - price}{t('円')}</Text>
      <Button onClick={onSubmit}>{t('ご注文確定')}</Button>
    </Section>
  );
};

export default withI18n()(ConfirmForm);